import {Grid} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import CustomSelect from '../../../components/common/customSelect/customSelect';
import CustomTab from '../../../components/common/customTab/customTab';
import CustomTable from '../../../components/common/customTable/customTable';
import DateFormatter from '../../../hooks/dateFormatter';
import {
  MyEarningsList,
  MyEarningsRetailerList,
} from '../../../hooks/useTableData';
import distributorService from '../../../services/distributor_service';
import {Constants} from '../../../stateManagement/constants';
import {Context} from '../../../stateManagement/context';
import MyEarnings from './myEarnings';
import MyEarningsRetailer from './myEarningsRetailer';
import './myearningsmodal.css';

const values = [
  {id: 1, text: 'My Earnings'},
  {id: 2, text: 'Retailer Earnings'},
];

const selectObj = [
  {value: 'dmt', label: 'DMT'},
  {value: 'recharge', label: 'Recharge'},
  {value: 'credit card', label: 'Credit Card'},
  {value: 'bbps', label: 'BBPS'},
  {value: 'matm', label: 'MATM'},
  {value: 'aeps', label: 'AEPS'},
  {value: 'kycdmt', label: 'DMT KYC'},
];

const tableheadMyEarningsRetailer = [
  {
    name: 'Transaction At',
    selector: row => row.createdAt,
    sortable: true,
    id: 'createdAt',
    format: row => DateFormatter(row.createdAt, 'table'),
  },
  {
    name: 'Amount',
    selector: row => row.amount,
    sortable: true,
    id: 'amount',
  },
  {
    name: 'Commission',
    selector: row => row.commission,
    sortable: true,
    id: 'commission',
  },

  {
    name: 'Transaction Ref',
    selector: row => row.transactionRef,
    sortable: true,
    id: 'transactionRef',
  },
];

const tableheadMyEarnings = [
  {
    name: 'Transaction At',
    selector: row => row.createdAt,
    sortable: true,
    id: 'createdAt',
    format: row => DateFormatter(row.createdAt, 'table'),
  },
  {
    name: 'Amount',
    selector: row => row.amount,
    sortable: true,
    id: 'amount',
  },
  {
    name: 'Commission',
    selector: row => row.commission,
    sortable: true,
    id: 'commission',
  },
];

const MyEarningsModal = () => {
  const [fetchedTableData, setFetchedTableData] = useState({
    recharge: [],
    ecaps_dmt: [],
    credit_card: [],
    bbps: [],
    aeps: [],
    matm: [],
    kycDmt: [],
  });
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState(null);
  const [fetchedData, setFetchedData] = useState('');
  const [tableType, setTableType] = useState({value: 'dmt', label: 'DMT'});

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  var endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
  );

  const [dateObj, setDateObj] = useState({
    startDate: startDate,
    endDate: today,
  });

  const handleTabSelected = val => {
    setFetchedTableData([]);
    contextDispatch({type: Constants.ACTIVE_TAB_ID, payload: val});
  };

  const [selectedData, setSelectedData] = useState({
    startDate: startDate,
    endDate: endDate,
    userId: '',
  });

  const setData = data => {
    setSelectedData(data);
  };

  useEffect(() => {
    setDateObj({startDate: startDate, endDate: today});
    setSelectedData({startDate: startDate, endDate: endDate, userId: ''});
    setPending(true);
    setFetchedTableData([]);
    switch (state[0]?.activeTabId) {
      case 1:
        setTableType({value: 'dmt', label: 'DMT'});
        distributorService
          .My_Earnings({
            startDate: DateFormatter(
              new Date(selectedData.startDate),
              'payload',
            ),
            endDate: DateFormatter(new Date(selectedData.endDate), 'payload'),
          })
          .then(res => {
            setPending(false);
            setFetchedTableData(res?.data?.data);
          })
          .catch(err => setPending(false));
        break;
      case 2:
        setTableType({value: 'dmt', label: 'DMT'});
        distributorService
          .My_Earnings({
            startDate: DateFormatter(
              new Date(selectedData.startDate),
              'payload',
            ),
            endDate: DateFormatter(new Date(selectedData.endDate), 'payload'),
            userId: selectedData?.userId,
          })
          .then(res => {
            setPending(false);
            setFetchedTableData(res?.data?.data);
          })
          .catch(err => setPending(false));
        break;
      default:
        break;
    }
  }, [state[0]?.callApi]);

  useEffect(() => {
    distributorService
      .Transfer_Value_View_Chain({userType: 'retailer'})
      .then(res => {
        setFetchedData(res.data.data);
        setSelectedData(prev => ({...prev, userId: res.data.data[0]?.id}));
      });
  }, []);

  const selectData = [];
  fetchedData &&
    fetchedData.map(e => {
      selectData.push({
        value: `${e.id}`,
        label: `${
          e.firstName?.charAt(0).toUpperCase() + e.firstName?.slice(1)
        } ${e.lastName?.charAt(0).toUpperCase() + e.lastName?.slice(1)} (${
          e.edgeId
        })`,
      });
    });

  function RenderTabs(tabId) {
    switch (tabId) {
      case 1:
        return <MyEarnings setData={setData} dateObj={dateObj} />;
      case 2:
        return <MyEarningsRetailer selectData={selectData} setData={setData} />;
      default:
        return <MyEarnings setData={setData} dateObj={dateObj} />;
    }
  }

  function RenderTables(id) {
    switch (id) {
      case 1:
        if (tableType.value === 'dmt') {
          const {myEarningsListhead, myEarningsListdata} = MyEarningsList(
            fetchedTableData['dmt'],
            tableheadMyEarnings,
          );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsListhead}
                pending={pending}
                tabledata={myEarningsListdata}
              />
            </>
          );
        }
        if (tableType.value === 'recharge') {
          const {myEarningsListhead, myEarningsListdata} = MyEarningsList(
            fetchedTableData['recharge'],
            tableheadMyEarnings,
          );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsListhead}
                pending={pending}
                tabledata={myEarningsListdata}
              />
            </>
          );
        }
        if (tableType.value === 'credit card') {
          const {myEarningsListhead, myEarningsListdata} = MyEarningsList(
            fetchedTableData['creditCard'],
            tableheadMyEarnings,
          );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsListhead}
                pending={pending}
                tabledata={myEarningsListdata}
              />
            </>
          );
        }
        if (tableType.value === 'bbps') {
          const {myEarningsListhead, myEarningsListdata} = MyEarningsList(
            fetchedTableData['bbps'],
            tableheadMyEarnings,
          );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsListhead}
                pending={pending}
                tabledata={myEarningsListdata}
              />
            </>
          );
        }
        if (tableType.value === 'matm') {
          const {myEarningsListhead, myEarningsListdata} = MyEarningsList(
            fetchedTableData['matm'],
            tableheadMyEarnings,
          );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsListhead}
                pending={pending}
                tabledata={myEarningsListdata}
              />
            </>
          );
        }
        if (tableType.value === 'aeps') {
          const {myEarningsListhead, myEarningsListdata} = MyEarningsList(
            fetchedTableData['aeps'],
            tableheadMyEarnings,
          );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsListhead}
                pending={pending}
                tabledata={myEarningsListdata}
              />
            </>
          );
        }
        if (tableType.value === 'kycdmt') {
          const {myEarningsListhead, myEarningsListdata} = MyEarningsList(
            fetchedTableData['kycDmt'],
            tableheadMyEarnings,
          );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsListhead}
                pending={pending}
                tabledata={myEarningsListdata}
              />
            </>
          );
        }
        break;
      case 2:
        if (tableType.value === 'dmt') {
          const {myEarningsRetailerListhead, myEarningsRetailerListdata} =
            MyEarningsRetailerList(
              fetchedTableData['dmt'],
              tableheadMyEarningsRetailer,
            );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsRetailerListhead}
                pending={pending}
                tabledata={myEarningsRetailerListdata}
              />
            </>
          );
        }
        if (tableType.value === 'recharge') {
          const {myEarningsRetailerListhead, myEarningsRetailerListdata} =
            MyEarningsRetailerList(
              fetchedTableData['recharge'],
              tableheadMyEarningsRetailer,
            );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsRetailerListhead}
                pending={pending}
                tabledata={myEarningsRetailerListdata}
              />
            </>
          );
        }
        if (tableType.value === 'credit card') {
          const {myEarningsRetailerListhead, myEarningsRetailerListdata} =
            MyEarningsRetailerList(
              fetchedTableData['creditCard'],
              tableheadMyEarningsRetailer,
            );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsRetailerListhead}
                pending={pending}
                tabledata={myEarningsRetailerListdata}
              />
            </>
          );
        }
        if (tableType.value === 'bbps') {
          const {myEarningsRetailerListhead, myEarningsRetailerListdata} =
            MyEarningsRetailerList(
              fetchedTableData['bbps'],
              tableheadMyEarningsRetailer,
            );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsRetailerListhead}
                pending={pending}
                tabledata={myEarningsRetailerListdata}
              />
            </>
          );
        }
        if (tableType.value === 'matm') {
          const {myEarningsRetailerListhead, myEarningsRetailerListdata} =
            MyEarningsRetailerList(
              fetchedTableData['matm'],
              tableheadMyEarningsRetailer,
            );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsRetailerListhead}
                pending={pending}
                tabledata={myEarningsRetailerListdata}
              />
            </>
          );
        }
        if (tableType.value === 'aeps') {
          const {myEarningsRetailerListhead, myEarningsRetailerListdata} =
            MyEarningsRetailerList(
              fetchedTableData['aeps'],
              tableheadMyEarningsRetailer,
            );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsRetailerListhead}
                pending={pending}
                tabledata={myEarningsRetailerListdata}
              />
            </>
          );
        }
        if (tableType.value === 'kycdmt') {
          const {myEarningsRetailerListhead, myEarningsRetailerListdata} =
            MyEarningsRetailerList(
              fetchedTableData['kycDmt'],
              tableheadMyEarningsRetailer,
            );
          return (
            <>
              <h1 className="text-[#112467] my-2.5 font-bold">{`${tableType.label} List`}</h1>
              <CustomTable
                tablehead={myEarningsRetailerListhead}
                pending={pending}
                tabledata={myEarningsRetailerListdata}
              />
            </>
          );
        }
        break;
      default:
        break;
    }
  }

  const handleChangeSelect = e => {
    setTableType(e.value);
  };

  function Earnings() {
    if (tableType.value == 'kycdmt') {
      return fetchedTableData.kycDmtEarnings ?? '0';
    }
    if (tableType.value == 'aeps') {
      return fetchedTableData.aepsEarnings ?? '0';
    }
    if (tableType.value == 'bbps') {
      return fetchedTableData.bbpsEarnings ?? '0';
    }
    if (tableType.value == 'matm') {
      return fetchedTableData.matmEarnings ?? '0';
    }
    if (tableType.value == 'credit card') {
      return fetchedTableData.cCEarnings ?? '0';
    }
    if (tableType.value == 'dmt') {
      return fetchedTableData.dmtEarnings ?? '0';
    }
    if (tableType.value == 'recharge') {
      return fetchedTableData.rechargeEarnings ?? '0';
    }

    return null;
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item sm={10}>
          <div className="myledger-modal-container modal-transparency">
            <CustomTab values={values} selected={handleTabSelected} />
            {RenderTabs(state[0]?.activeTabId)}
          </div>
        </Grid>
      </Grid>
      <div className="bg-white p-5 mt-10 table-container">
        <div className="w-full flex items-center justify-between">
          <span className="font-black mr-2">
            Total Earnings: ₹ {Earnings() ?? 'NA'}
          </span>
          <div className="flex items-center w-[320px] gap-5">
            <span>Select Table Type</span>
            <span className="grow">
              <CustomSelect
                data={selectObj}
                name="list"
                value={tableType}
                handleChange={handleChangeSelect}
                label={tableType?.label}
              />
            </span>
          </div>
        </div>
        {RenderTables(state[0]?.activeTabId)}
      </div>
    </>
  );
};

export default MyEarningsModal;
