import RightArrow from '../../../../assets/svg/down-arrow.svg';
import LogOut from '../../../../assets/svg/logout.svg';
import './usercard.css';

const UserCard = ({handleLogOut, data, setResetPasswordModal, setAnchorEl}) => {
  const handleOpen = () => {
    setAnchorEl(null);
    setResetPasswordModal({
      isOpen: true,
      flag: false,
    });
  };

  return (
    <div className="usercard-container">
      <div className="pb-2.5">
        <div className="flex gap-6">
          <div className="usercard-logo-container">
            <div>
              <img
                src={process.env.REACT_APP_LOGIN_IMAGE_1}
                alt="UserCardLogo"></img>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="usercard-user-type">
              {data?.userType=="super_distributor"
                ? `${
                  data?.userType?.replace('_', ' ').split(' ')[0].charAt(0).toUpperCase() +
                  data?.userType?.replace('_', ' ').split(' ')[0].slice(1)+` `+
                  data?.userType?.replace('_', ' ').split(' ')[1].charAt(0).toUpperCase() +
                  data?.userType?.replace('_', ' ').split(' ')[1].slice(1)
                  }`
                : `${
                  data?.userType?.charAt(0).toUpperCase() +
                  data?.userType?.slice(1)
                }`}
            </span>
            <span className="usercard-user-id">
              {data?.edgeId ? data?.edgeId : 'loading....'}
            </span>
            <span className="usercard-user-balance">
              Balance
              <span className="mx-2">₹</span>
              <span>{data?.tradeBalance || 'loading...'}</span>
            </span>
            {/* Kit Balance */}
            <span className="usercard-user-balancekit">{`Kit Balance ${
              data?.kitBalance ?? data?.kitBalance
            }`}</span>
            {/* Kit Balance */}
          </div>
        </div>
      </div>
      <div className="usercard-change-password">
        <span onClick={handleOpen}>Change Password</span>
        <img src={RightArrow} alt="RightArrow" style={{rotate: '270deg'}}></img>
      </div>
      <div className="usercard-logout" onClick={handleLogOut}>
        <span>Logout</span>
        <img src={LogOut} alt="LogOut"></img>
      </div>
    </div>
  );
};

export default UserCard;
