import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";

const LoaderStyling = {
  display: "flex",
  overflow: "hidden",
};

export default function CustomLoader() {
  // return (
  //   <div>
  //     {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
  //     <Backdrop
  //       sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //       open={navContext.showBackDrop}
  //     >
  //       <CircularProgress color="inherit" />
  //     </Backdrop>
  //   </div>
  // );
  return (
    <Box sx={LoaderStyling}>
      <CircularProgress sx={{color:"#112467"}}/>
    </Box>
  );
}
