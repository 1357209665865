import { useContext, useEffect, useState } from "react";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import "./cutomtab.css";

function CustomTab({ values, selected, style }) {

    const [state, contextDispatch] = useContext(Context);

    const handleClick = (val) => {
        selected(val.id)
    }

    return (
        <div className="flex justify-center gap-10 whitespace-nowrap">
            {values.map((val, i) => (
                <div key={i} onClick={() => handleClick(val)} style={style} className={`text-center tab-title ${state[0]?.activeTabId === val.id ? `tab-title-border tab-title-bold` : ``}`}>{val.text}</div>
            ))}
        </div>
    )
}

export default CustomTab;