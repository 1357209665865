import {Grid} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import CustomButton from '../../../components/common/customButton/customButton';
import CustomModal from '../../../components/common/customModal/customModal';
import FundLoadSelect from '../../../components/common/customSelect/fundLoadSelect';
import CustomTab from '../../../components/common/customTab/customTab';
import CustomTable from '../../../components/common/customTable/customTable';
import DateFormatter from '../../../hooks/dateFormatter';
import {
  FundLoadCompleted,
  FundLoadRequestList,
} from '../../../hooks/useTableData';
import distributorService from '../../../services/distributor_service';
import {Constants} from '../../../stateManagement/constants';
import {Context} from '../../../stateManagement/context';
import './fundloadmodal.css';

const values = [
  {id: 1, text: 'Fund Load Requests'},
  {id: 2, text: 'Completed Requests'},
];

const buttonStyle2 = {
  background: 'linear-gradient(135deg, #EC7D2D 0%, #EB2D2D 100%)',
  color: 'white',
  borderRadius: '8px',
  height: '40px',
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '2px',
  width: '100px',
  // height: "32px",
  padding: '0px 15px',
  whiteSpace: 'nowrap',
};

const buttonStyle1 = {
  background: 'linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)',
  color: 'white',
  marginRight: '5px',
  marginLeft: '5px',
  borderRadius: '8px',
  height: '40px',
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '2px',
  width: '100px',
  // height: "32px",
  padding: '0px 15px',
  whiteSpace: 'nowrap',
};

const buttonStyle3 = {
  background:
    'linear-gradient(90deg, hsla(27, 98%, 47%, 1) 50%, hsla(27, 70%, 55%, 1) 99%)',
  color: 'white',
  marginLeft: '5px',
  // marginRight: '5px',
  borderRadius: '8px',
  height: '40px',
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '2px',
  width: '100px',
  // height: "32px",
  padding: '0px 15px',
  whiteSpace: 'nowrap',
};

const tableheadCompleted = [
  {
    name: 'User ID',
    selector: row => row?.edgeId,
    sortable: true,
    id: 'edgeId',
  },
  {
    name: 'Name',
    selector: row => row?.name,
    sortable: true,
    id: 'name',
  },
  {
    name: 'Payment Mode',
    selector: row => row?.paymentMode,
    sortable: true,
    id: 'paymentMode',
    format: row => row?.paymentMode?.toUpperCase(),
    width: '150px !important',
  },
  {
    name: 'Referance No',
    selector: row => row?.ReferanceNo,
    sortable: true,
    id: 'ReferanceNo',
    width: '150px !important',
  },
  {
    name: 'Bank',
    selector: row => row?.bank,
    sortable: true,
    id: 'bank',
  },
  {
    name: 'Amount',
    selector: row => row?.amount,
    sortable: true,
    id: 'amount',
    format: row => row?.amount?.toLocaleString(),
  },
  {
    name: 'Date',
    selector: row => row?.paymentDate,
    sortable: true,
    format: row => DateFormatter(row?.paymentDate, 'table'),
    id: 'paymentDate',
  },
  {
    name: 'Status',
    selector: row => row?.status,
    sortable: true,
    id: 'status',
    format: row => row?.status?.toUpperCase(),
  },
  {
    name: "Remarks",
    selector: (row) => row?.remarks,
    sortable: true,
    format: (row) => row?.remarks,
    id: "remarks",
  },
];

function FundLoadModal() {
  const [fetchedTableData, setFetchedTableData] = useState([]);
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState(true);
  const [remarks, setRemarks] = useState({id: {value: ''}});
  const [modalData, setModalData] = useState({
    requestid: '',
    amount: '',
    description: '',
    modalType: '',
  });
  const [remarksError, setRemarksError] = useState({error: '', id: ''});

  const selectData = [
    {value: 'Fundload Not credited', label: 'Fundload Not credited'},
    {value: 'Wrong Payment Mode', label: 'Wrong Payment Mode'},
    {value: 'Wrong Payment Date', label: 'Wrong Payment Date'},
    {value: 'Wrong Ref Number', label: 'Wrong Ref Number'},
    {value: 'Wrong Amount', label: 'Wrong Amount'},
    {value: 'Duplicate order', label: 'Duplicate order'},
    // { value: "Others ( input option )", label: "Others ( input option )" },
  ];

  const tableheadRequest = [
    {
      name: 'User ID',
      selector: row => row?.edgeId,
      sortable: true,
      id: 'edgeId',
      width: '100px !important',
    },
    {
      name: 'Name',
      selector: row => row?.name,
      sortable: true,
      id: 'name',
      width: '150px !important',
    },
    {
      name: 'Payment Mode',
      selector: row => row?.paymentMode,
      sortable: true,
      id: 'paymentMode',
      format: row => row?.paymentMode.toUpperCase(),
      width: '150px !important',
    },
    {
      name: 'Referance No',
      selector: row => row?.ReferanceNo,
      sortable: true,
      id: 'ReferanceNo',
      width: '150px !important',
    },
    {
      name: 'Bank',
      selector: row => row?.bank,
      sortable: true,
      id: 'bank',
      width: '150px !important',
    },
    {
      name: 'Status',
      selector: row => row?.status,
      sortable: true,
      id: 'status',
      width: '150px !important',
      // format: (row) => row?.status?.toUpperCase()
    },
    {
      name: 'Amount',
      selector: row => row?.amount,
      sortable: true,
      id: 'amount',
      width: '150px !important',
    },
    {
      name: 'Date',
      selector: row => row.paymentDate,
      sortable: true,
      format: row => DateFormatter(row?.paymentDate, 'table'),
      id: 'paymentDate',
      width: '150px !important',
    },
    {
      name: 'Action',
      cell: row => (
        <div className="p-2.5 w-full">
          <div className="flex mb-5">
            <span className="w-[260px] relative">
              <FundLoadSelect
                value={remarks[row.id]?.value ?? ''}
                data={selectData}
                label="Remarks"
                handleChange={e => {
                  setRemarksError({error: '', id: row?.id});
                  setRemarks(prev => ({...prev, [row.id]: {value: e?.value}}));
                }}></FundLoadSelect>
              {remarksError.id === row?.id && (
                <span className="absolute left-0 text-[red] text-xs my-1">
                  {remarksError.error}
                </span>
              )}
            </span>
            <CustomButton
              loadingDescription="no description"
              handleClick={() => handleTransfer(row.id, row.amount)}
              label={'Transfer'}
              style={buttonStyle1}></CustomButton>
            <CustomButton
              loadingDescription="no description"
              handleClick={() => handleReject(row.id, row.amount)}
              label={'Reject'}
              style={buttonStyle2}></CustomButton>
            <CustomButton
              loadingDescription="no description"
              handleClick={() => handleWFP(row.id)}
              label={'WFP'}
              style={buttonStyle3}></CustomButton>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '585px !important',
    },
  ];

  const handleWFP = selectedId => {
    distributorService
      .Request_WFP({
        fundTransactionId: selectedId,
        remarks: remarks[selectedId]?.value?.value,
      })
      .then(res => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'success',
            message: res?.data?.message,
          },
        });
      })
      .catch(err => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'error',
            message: err?.response?.data?.message,
          },
        });
      });
    setRemarks(prev => ({...prev, [selectedId]: {value: ''}}));
    contextDispatch({
      type: Constants.CALL_API,
      payload: !state[0]?.callApi,
    });
  };

  const handleTransfer = (requestId, amt) => {
    setModalData({
      requestid: requestId,
      amount: amt,
      description: `A total of ${amt} rupees will be deducted from your account`,
      modalType: 'fundrequesttransfer',
      remarks: remarks[requestId]?.value?.value,
    });
    contextDispatch({
      type: Constants.CUSTOM_MODAL,
      payload: {isOpen: true, modalProps: {}},
    });
  };

  const handleReject = (requestId, amt) => {
    // setRemarksError({error: 'This field is required!', id: requestId});

    if (remarksError.id === requestId && remarks[requestId]?.value?.value) {
      setRemarksError({error: '', id: requestId});
      setModalData({
        requestid: requestId,
        amount: amt,
        description: `Are you sure to reject an amount of ${amt}`,
        modalType: 'fundrequestreject',
        remarks: remarks[requestId]?.value?.value,
      });
      contextDispatch({
        type: Constants.CUSTOM_MODAL,
        payload: {isOpen: true, modalProps: {}},
      });
    } else {
      setRemarksError({error: 'This field is required!', id: requestId});
    }
  };

  useEffect(() => {
    let mounted = true;

    switch (state[0]?.activeTabId) {
      case 1:
        contextDispatch({
          type: Constants.CALL_API,
          payload: true,
        });
        setFetchedTableData('');
        distributorService.FundLoad_Req({status: 'pending'}).then(({data}) => {
          if (mounted) {
            setPending(false);
            setFetchedTableData(data.data);
          }
        });

        break;
      case 2:
        setFetchedTableData('');
        distributorService.FundLoad_Req({status: "completed"}).then(({data}) => {
          if (mounted) {
            setPending(false);
            setFetchedTableData(data.data);
          }
        });

        break;
      default:
        break;
    }

    return () => {
      mounted = false;
      setPending(true);
    };
  }, [state[0]?.activeTabId, state[0]?.callApi]);

  const handleTabSelected = val =>
    contextDispatch({
      type: Constants.ACTIVE_TAB_ID,
      payload: val,
    });

  function RenderTab(value) {
    switch (value) {
      case 1:
        const {requestData, requestHead} = FundLoadRequestList(
          fetchedTableData,
          tableheadRequest,
        );
        return (
          <div className="bg-white p-4 table-container mt-10">
            <CustomTable
              tablehead={requestHead}
              pending={pending}
              tabledata={requestData}
            />
          </div>
        );
      case 2:
        const {completedData, completedHead} = FundLoadCompleted(
          fetchedTableData,
          tableheadCompleted,
        );
        return (
          <div className="bg-white p-4 table-container mt-10 rounded">
            <CustomTable
              tablehead={completedHead}
              pending={pending}
              tabledata={completedData}
            />
          </div>
        );
      default:
        break;
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <div className="modal-transparency" id="fundload-container">
            <CustomTab selected={handleTabSelected} values={values} />
          </div>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
      {RenderTab(state[0]?.activeTabId)}
      <CustomModal
        data={modalData}
        buttonLabel="Agree"
        name={modalData.modalType}
        header={'Are you sure?'}
        description={modalData.description}
        modalType={modalData.modalType}
      />
    </>
  );
}

export default FundLoadModal;
