import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useContext } from "react";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarStyling = styled(Alert)(({ theme }) => ({
  "& .MuiAlert-message": {
    overflow: "hidden",
    padding: 0,
  },
  "& .MuiAlert-icon": {
    padding: 0,
  },
  "& .MuiAlert-action": {
    padding: 0,
    height: "22px",
    width: "22px",
    display: "flex",
    alignItems: "center",
    marginLeft: "12px",
  },
  "& .css-1e0d89p-MuiButtonBase-root-MuiIconButton-root": {
    padding: 0,
  },
  width: "100%",
  height: "38px",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
}));

const CustomSnackbar = () => {
  const [state, contextDispatch] = useContext(Context);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    contextDispatch({
      type: Constants.SHOW_SNACKBAR,
      payload: { isOpen: false, severity: state[0]?.SnackBar?.severity, message: state[0]?.SnackBar?.message },
    });
  };

  // ["error", "warning","info","success"]

  return (
    <Snackbar sx={{ height: "30px" }} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={state[0]?.SnackBar?.isOpen} autoHideDuration={3000} onClose={handleClose}>
      <SnackbarStyling severity={state[0]?.SnackBar?.severity} onClose={handleClose}>
        {state[0]?.SnackBar?.message}
      </SnackbarStyling>
    </Snackbar>
  );
};

export default CustomSnackbar;
