const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function Loginvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];
    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }

    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function RetailerListvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
  }

  return errors;
}

export function ForgotPasswordFormValidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
  }

  return errors;
}

export function ChangePasswordNewPasswordvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }

    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
  }

  return errors;
}

export function Kitvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function VirtualAccountvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function TransferValuevalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function TransferByNumbervalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function Requestvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function ValueReceivedvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
  }

  return errors;
}

export function TransferRetailervalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
  }

  return errors;
}

export function Kitmgntvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function Tpinvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }

    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}
export function TpinOtpvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }

    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function TransactionsValidation(values, submit = false) {
  let errors = {};
  for (let value in values) {
    if (submit) {
      if (values["retailer"].value.value === "phonenumber") {
        values["mobile"].touched = true;
      }
      if (values["retailer"].value.value === "retailer" || values["retailer"].value.value == undefined) {
        values["retailerName"].touched = true;
      }
    }
    const currentField = values[value];

    if (values["retailer"].value.value === "retailer" || values["retailer"].value.value == undefined) {
      if (currentField.retailerName && (currentField.value === '' || !currentField.value) && currentField.touched) {
        errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'This field is required!';
      }
    }
    if (values["retailer"].value.value === "phonenumber") {
      if (!errors[value] && currentField.phone && (currentField.value === '' || !currentField.value) && currentField.touched) {
        errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'This field is required!';
      }
    }
  }
  return errors;
}

export function OTPModalValidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }
  // console.warn(errors);

  return errors;
}

export function PANAadharvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }
    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }
  // console.warn(errors);

  return errors;
}

export function Dsevalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
  }

  return errors;
}
export function RetailerDetailsvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }
    const currentField = values[value];
    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }
    if (!errors[value] && currentField.email && !validateEmail(currentField.value) && currentField.touched) {
      errors[value] = currentField.emailMessage ? currentField.emailMessage : 'Invalid email address!';
    }
    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  // console.warn(errors);

  return errors;
}

export function CustomModalvalidation(values, submit = true) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}
export function verifyOtpvalidation(values, submit = true) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}
