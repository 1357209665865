
import ValueTransferReportModal from './valueTransferReportModal/valueTransferReportModal';

const ValueTransferReport=()=>{

    return(
      <div className="m-5">
        <div className="relative bottom-20">
        <ValueTransferReportModal />
        </div> 
      </div> 
  );
    

}


export default ValueTransferReport;