import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomDatePicker from '../../../components/common/customDatePicker/customDatePicker';
import CustomSelect from "../../../components/common/customSelect/customSelect";
import DateFormatter from '../../../hooks/dateFormatter';
import UseForm from "../../../hooks/useForm";
import distributorService from "../../../services/distributor_service";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import { TransferRetailervalidation } from "../../../validation/validation";


const buttonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px"
}

const TransferRetailer = ({ dateObj, selectData, fetchDataFromApi }) => {
  const [state, contextDispatch] = useContext(Context);

  const initialState = {
    startDate: { value: dateObj["startDate"], required: true },
    endDate: { value: dateObj["endDate"], required: true },
    retailer: { value: selectData[0], required: true }
  }

  const { handleChange, selectChange, values, handleSubmit, errors, dateChange } = UseForm(submitForm, TransferRetailervalidation, initialState);

  function submitForm(value) {

    var tempDate = new Date(values.endDate.value);
    var endDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + 1);


    distributorService.Value_Received({
      startDate: DateFormatter(new Date(values.startDate.value), 'payload'),
      endDate: DateFormatter(new Date(endDate), 'payload'),
      retailerId: values.retailer.value.value
    }).then((res) => {
      fetchDataFromApi(res?.data?.data);
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "success",
          message: res?.data?.message,
        },
      });
    }).catch((err) => {
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "error",
          message: err?.response?.data?.message,
        },
      });
    })
  }


  return (
    <Grid sm={12} md={12}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="flex justify-center mt-5 flex-col">
          <label className="my-3 pt-2" >Start Date<span className="text-font-red text-sm font-bold">*</span></label>
          <div>
            <CustomDatePicker dateChange={dateChange} name="startDate" selected={values.startDate.value} placeholder={"Start Date"} />
            {errors.startDate && <p className="absolute errorText">{errors.startDate}</p>}
          </div>
          <label className="my-3 pt-2">End Date<span className="text-font-red text-sm font-bold">*</span></label>
          <div>
            <CustomDatePicker minDate={values.startDate.value} dateChange={dateChange} name="endDate" selected={values.endDate.value} placeholder={"End Date"} />
            {errors.endDate && <p className="absolute errorText">{errors.endDate}</p>}
          </div>
          <label className="my-3 pt-2">Retailer<span className="text-font-red text-sm font-bold">*</span></label>
          <div>
            {/* <CustomInput  name="retailer"  value={values.retailer.value} handleChange={handleChange} placeholder={"Retailer"} /> */}
            <CustomSelect
              data={selectData}
              name="retailer"
              value={values.retailer.value}
              handleChange={selectChange}
              label={selectData[0]?.label}
            />
            {errors.retailer && <p className="absolute errorText">{errors.retailer}</p>}
          </div>
          <div className="flex justify-end items-center mt-8">
            <CustomButton style={buttonStyle} label={"Submit"} />
          </div>
        </div>
      </form>
    </Grid>
  )

}

export default TransferRetailer;