
import './copyright.css'

export default function Copyright({ color }) {
  return (
    <div className="copyright">
      <div className={color ? `${color}` : 'text-white'}>{process.env.REACT_APP_COPYRIGHT_LINE_1}</div>
      {!color && <div className='text-white'>{process.env.REACT_APP_COPYRIGHT_LINE_2}</div>}
    </div>
  );
}
