import { Grid } from '@mui/material';
import { useContext, useState } from 'react';
import CustomButton from '../../components/common/customButton/customButton';
import CustomDatePicker from '../../components/common/customDatePicker/customDatePicker';
import CustomReportTable from '../../components/common/customTable/customReportTable/customReportTable';
import DateFormatter from '../../hooks/dateFormatter';
import UseForm from '../../hooks/useForm';
import distributorService from '../../services/distributor_service';
import { Constants } from '../../stateManagement/constants';
import { Context } from '../../stateManagement/context';
import { ValueReceivedvalidation } from '../../validation/validation';
import './index.css';

const buttonStyle = {
  width: '230px',
  lineHeight: '17px',
  letterSpacing: '4px',
};

const FundTransferReportsDT = () => {
  const [fetchedData, setFetchedData] = useState({ headers: [], data: [] });
  const [loading, setLoading] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [state, contextDispatch] = useContext(Context);

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const dateObj = { startDate: startDate, endDate: today };

  const initialState = {
    startDate: { value: dateObj['startDate'], required: true },
    endDate: { value: dateObj['endDate'], required: true },
  };

  const { values, handleSubmit, errors, dateChange } = UseForm(
    submitForm,
    ValueReceivedvalidation,
    initialState,
  );

  function handleResponseData(headers, data) {
    const tempArray = [];
    let dataLength = data.length;
    let headerLength = headers.length;

    let formatedData = [];
    for (let i = 0; i < dataLength; i++) {
      let tempObj = {};
      for (const property in data[i]) {
        if (['NaN', null, undefined, ''].includes(data[i][property])) {
          tempObj = {
            ...tempObj,
            [property]: 'NA',
          };
        } else if (data[i][property] == false) {
          tempObj = {
            ...tempObj,
            [property]: data[i][property]?.toLocaleString().toUpperCase(),
          };
        } else {
          tempObj = {
            ...tempObj,
            [property]: data[i][property].toLocaleString(),
          };
        }
      }
      formatedData.push(tempObj);
    }

    for (let i = 0; i < dataLength; i++) {
      let tempObj = {};
      for (const property in formatedData[i]) {
        for (let h = 0; h < headerLength; h++) {
          if (property === 'Transaction Timestamp') {
            tempObj = {
              ...formatedData[i],
              'Transaction Timestamp': DateFormatter(
                formatedData[i][property],
                'report-table',
              ),
            };
          }
        }
      }
      tempArray.push(tempObj);
    }
    setFetchedData({ headers: headers, data: tempArray });
  }

  function submitForm() {
    setLoading('loading');
    setFetchedData({ headers: [], data: [] });
    distributorService
      .Reports_Fund_Transfer_DT({
        responseType: 'json',
        fromDate: DateFormatter(new Date(values.startDate.value), 'report'),
        toDate: DateFormatter(new Date(values.endDate.value), 'report'),
      })
      .then(res => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'success',
            message: res.data.message,
          },
        });
        setLoading('');
        handleResponseData(res?.data?.headers, res?.data?.data);
      })
      .catch(err => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'error',
            message: err.response.data.message,
          },
        });
        setLoading('');
      });
  }

  const options = [
    {
      label: 'Transaction Type',
      options: [
        { label: 'Credit', value: 'Credit' },
        { label: 'Debit', value: 'Debit' },
      ],
    },
    {
      label: 'Other Options',
      options: [
        { label: 'Option 2', value: 'Option 2' },
        { label: 'Option 3', value: 'Option 3' },
      ],
    },
  ];

  return (
    <>
      <div className="m-5">
        <div className="relative bottom-28">
          <div className="myledger-modal-container modal-transparency mx-[10px]">
            <div className="tab-title tab-title-border tab-title-bold mb-[40px]">
              Fund Transfer Report
            </div>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid
                md={12}
                container
                item
                columnSpacing={3}
                justifyContent="center"
                alignItems="center">
                <Grid md={4} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      dateChange={dateChange}
                      name="startDate"
                      selected={values.startDate.value}
                      placeholder={'start Date'}
                    />
                    {errors.startDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.startDate}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid md={4} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      minDate={values.startDate.value}
                      dateChange={dateChange}
                      name="endDate"
                      selected={values.endDate.value}
                      placeholder={'end Date'}
                    />
                    {errors.endDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.endDate}
                      </p>
                    )}
                  </div>
                </Grid>
                {/* <Grid md={4} item className="my-5">
                  <Select options={options} isMulti />
                </Grid>
                <Grid md={12} item className="my-5">
                  <input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </Grid> */}
                <div className="ledger-btn">
                  <CustomButton
                    variant={loading}
                    style={buttonStyle}
                    label={'Generate Report'}
                  />
                </div>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <CustomReportTable
        title="Fund Transfer Report"
        fetchedData={fetchedData}
        fileName="FundTransferReport"
        searchTerm={searchTerm}
      />
    </>
  );
};

export default FundTransferReportsDT;
