import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {useContext, useState} from 'react';
import UseForm from '../../../../hooks/useForm';
import distributorService from '../../../../services/distributor_service';
import {Constants} from '../../../../stateManagement/constants';
import {Context} from '../../../../stateManagement/context';
import {Tpinvalidation} from '../../../../validation/validation';
import CustomButton from '../../../common/customButton/customButton';
import CustomInput from '../../../common/customInput/customInput';
import '../userCard/usercard.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '1rem',
  boxShadow: 24,
  padding: '30px 30px 40px 30px',
  outline: 'none',
};

const buttonStyle = {
  width: '240px',
  lineHeight: '17px',
  letterSpacing: '4px',
};

const initialState = {
  oldPassword: {
    value: '',
    required: true,
    minLength: 4,
    minLengthMessage: 'At least 4 characters long!',
    regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/,
  },
  newPassword: {
    value: '',
    required: true,
    minLength: 4,
    minLengthMessage: 'At least 4 characters long!',
    regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/,
  },
  confirmPassword: {
    value: '',
    matchWith: 'newPassword',
    matchWithMessage: 'Values must be equal!',
    required: true,
    regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/,
  },
};

const ResetPassword = ({resetPasswordModal, setResetPasswordModal}) => {
  const [state, contextDispatch] = useContext(Context);
  const [newPinType, setNewPinType] = useState('password');
  const [confirmPinType, setConfirmPinType] = useState('password');
  const [oldPinType, setOldPinType] = useState('password');
  const [pending, setPending] = useState('');

  const {handleChange, values, handleSubmit, errors, clearState} = UseForm(
    submitForm,
    Tpinvalidation,
    initialState,
  );

  const handleModalClose = () => {
    setResetPasswordModal({
      isOpen: false,
      flag: false,
    });
  };

  function submitForm(value) {
    setPending('loading');
    distributorService
      .Change_Password({
        oldpassword: values?.oldPassword?.value,
        password: values?.confirmPassword?.value,
      })
      .then(res => {
        setPending('');
        setResetPasswordModal({
          isOpen: false,
          flag: false,
        });
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'success',
            message: res?.data?.message,
          },
        });
        clearState(initialState);
      })
      .catch(err => {
        setPending('');
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'error',
            message: err?.response?.data?.message,
          },
        });
      });
  }

  const togglePassword = type => {
    if (type === 'oldPin') {
      if (oldPinType === 'password') setOldPinType('text');
      else setOldPinType('password');
    }
    if (type === 'newPin') {
      if (newPinType === 'password') setNewPinType('text');
      else setNewPinType('password');
    }
    if (type === 'confirmPin') {
      if (confirmPinType === 'password') setConfirmPinType('text');
      else setConfirmPinType('password');
    }
  };

  return (
    <Modal
      open={resetPasswordModal.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="text-center flex justify-center flex-col">
          <h1 className="modal-title">Change password</h1>
          <div className="modal-line"></div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="passwordfields"
          autoComplete="off">
          {!resetPasswordModal?.flag && (
            <CloseRoundedIcon
              onClick={handleModalClose}
              className="absolute top-2 right-2 cursor-pointer hover:scale-105 hover:bg-slate-300 hover:rounded-full"
            />
          )}
          <div className="my-5">
            <CustomInput
              type={oldPinType}
              name="oldPassword"
              value={values.oldPassword.value}
              handleChange={handleChange}
              placeholder={'Old Password'}
            />
            {errors.oldPassword && (
              <p className="absolute  errorText">{errors.oldPassword}</p>
            )}
            <span
              className="eye-password  text-gray-600"
              onClick={() => togglePassword('oldPin')}>
              {oldPinType === 'password' ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </span>
          </div>
          <div className="my-7">
            <CustomInput
              type={newPinType}
              name="newPassword"
              value={values.newPassword.value}
              handleChange={handleChange}
              placeholder={'New Password'}
            />
            {errors.newPassword && (
              <p className="absolute  errorText">{errors.newPassword}</p>
            )}
            <span
              className="eye-password  text-gray-600"
              onClick={() => togglePassword('newPin')}>
              {newPinType === 'password' ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </span>
          </div>
          <div className="my-7">
            <CustomInput
              type={confirmPinType}
              name="confirmPassword"
              value={values.confirmPassword.value}
              handleChange={handleChange}
              placeholder={'Confirm Password'}
            />
            {errors.confirmPassword && (
              <p className="absolute  errorText">{errors.confirmPassword}</p>
            )}
            <span
              className="eye-password  text-gray-600"
              onClick={() => togglePassword('confirmPin')}>
              {confirmPinType === 'password' ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </span>
          </div>
          <div className="flex justify-center w-full h-12 get-otp-btn-changepassword">
            <CustomButton
              variant={pending}
              style={buttonStyle}
              label="Submit"
            />
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ResetPassword;
