import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomInput from "../../../components/common/customInput/customInput";
import UseForm from "../../../hooks/useForm";
import distributorService from "../../../services/distributor_service";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import { TpinOtpvalidation, Tpinvalidation } from "../../../validation/validation";
import "./tpinmodal.css";

const otpInputStyle = {
    textAlign: "center",
};

const buttonStyle = {
    width: "25%",
    lineHeight: "17px",
    letterSpacing: "4px",
};



const ChangeTpin = ({ type, tpin }) => {
    const [newPinType, setNewPinType] = useState("password");
    const [confirmPinType, setConfirmPinType] = useState("password");
    const [state, contextDispatch] = useContext(Context);
    const initialState = {
        newPin: {
            value: '', required: true, minLength: 4, regexp: /^[0-9_ ]*$/,
            minLengthMessage: 'Tpin must be At least 4 characters long!',
            maxLength: 6,
            maxLengthMessage: 'Too many characters!'
        },
        confirmPin: { value: '', matchWith: "newPin", matchWithMessage: 'Values must be equal!', required: true, regexp: /^[0-9_ ]*$/ },
    }

    function submitForm(value) {
        type("otp")
        tpin(values.confirmPin.value)
        if (value) {
            distributorService.Tpin_Generate({
                "tpin": values.confirmPin.value
            }).then((res) => {
                contextDispatch({
                    type: Constants.SHOW_SNACKBAR,
                    payload: {
                        isOpen: true,
                        severity: "success",
                        message: res?.data?.message,
                    },
                });
            })
        }
    }



    const { handleChange, values, handleSubmit, errors } = UseForm(submitForm, Tpinvalidation, initialState);

    const togglePassword = (type) => {
        if (type === "newPin") {
            if (newPinType === "password")
                setNewPinType("text");
            else
                setNewPinType("password");
        }
        if (type === "confirmPin") {
            if (confirmPinType === "password")
                setConfirmPinType("text");
            else
                setConfirmPinType("password");
        }

    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item sm={6}>
                <div className="tpin-modal-container modal-transparency">
                    <h1 className="text-center  t-pin-title ">{"Change T-PIN"}</h1>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="my-5">
                            <CustomInput name="newPin" max={6} error={errors.newPin} value={values.newPin.value} handleChange={handleChange} placeholder={"New Pin"} type={newPinType} />
                            {errors.newPin && <p className="absolute  errorText">{errors.newPin}</p>}
                            <span className="eye-password  text-gray-600" onClick={() => togglePassword("newPin")}>
                                {newPinType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </span>
                        </div>
                        <div className="my-5">
                            <CustomInput name="confirmPin" max={6} error={errors.confirmPin} value={values.confirmPin.value} handleChange={handleChange} placeholder={"Confirm New PIN"} type={confirmPinType} />
                            {errors.confirmPin && <p className="absolute errorText">{errors.confirmPin}</p>}
                            <span className="eye-password  text-gray-600" onClick={() => togglePassword("confirmPin")}>
                                {confirmPinType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </span>
                        </div>
                        <div className="flex justify-center w-full h-12 get-otp-btn"><CustomButton style={buttonStyle} label={'Get OTP'} /></div>
                    </form>
                </div>
            </Grid>
        </Grid>
    )
}


const EnterOtp = ({ tpin, type }) => {
    const [runner, setRunner] = useState(true);
    const [state, contextDispatch] = useContext(Context);

    const [counter, setCounter] = useState(60 * 3);

    const seconds = String(counter % 60).padStart(2, 0);
    const minutes = String(Math.floor(counter / 60)).padStart(2, 0);

    useEffect(() => {
        if (counter > 0) {
            const timer = setInterval(
                () => setCounter((counter) => counter - 1),
                1000
            );
            return () => clearInterval(timer);
        }
    }, [counter]);

    const handleResendOtp = () => setCounter(60 * 3);

    const initialState = {
        tPinOtp: {
            value: '', required: true, minLength: 6, regexp: /^[0-9_ ]*$/,
            minLengthMessage: 'At least 4 characters long!',
            maxLength: 6,
            maxLengthMessage: 'Too many characters!'
        }
    }

    function submitForm(value) {
        if (value) {
            distributorService.TpinSet({
                "otp": values.tPinOtp.value,
                "tpin": tpin
            }).then((res) => {
                setRunner(false);
                type("tpin");
                contextDispatch({
                    type: Constants.SHOW_SNACKBAR,
                    payload: {
                        isOpen: true,
                        severity: "success",
                        message: res?.data?.message,
                    },
                });
            }).catch((err) => {
                contextDispatch({
                    type: Constants.SHOW_SNACKBAR,
                    payload: {
                        isOpen: true,
                        severity: "error",
                        message: err?.response?.data?.message,
                    },
                });
            })
        }
    }

    const { handleChange, values, handleSubmit, errors } = UseForm(submitForm, TpinOtpvalidation, initialState);
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item sm={6}>
                <div className="tpin-modal-container modal-transparency">
                    <h1 className="text-center text-base t-pin-title font-medium">{"Enter OTP"}</h1>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="my-5 w-full">
                            <CustomInput max={6} style={otpInputStyle} value={values.tPinOtp.value} handleChange={handleChange} name="tPinOtp" placeholder={"Enter OTP Here"} />
                            {errors.tPinOtp && <p className="absolute errorText">{errors.tPinOtp}</p>}
                        </div>
                        {runner && <div className="otp-min text-center">
                            {counter <= 0
                                ? "OTP has expired"
                                : `Request OTP in ${minutes}:${seconds} min`}
                        </div>}
                        {counter <= 0 && <p className="text-center resend-otp" onClick={handleResendOtp}>
                            Resend OTP
                        </p>}
                        <div className="flex justify-center w-full h-12 get-otp-btn">
                            {counter <= 0 ? "" : <CustomButton style={buttonStyle} label={"Verify"} />}
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    )
}

const TPinModal = () => {
    const [type, setType] = useState("tpin");
    const [tpin, setTpin] = useState("")


    return (
        type == "tpin" ? <ChangeTpin type={setType} tpin={setTpin} /> : <EnterOtp tpin={tpin} type={setType} />

    )
}

export default TPinModal;