import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from 'react';
import CustomButton from "../../../components/common/customButton/customButton";
import CustomDatePicker from '../../../components/common/customDatePicker/customDatePicker';
import DateFormatter from '../../../hooks/dateFormatter';
import UseForm from "../../../hooks/useForm";
import distributorService from "../../../services/distributor_service";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import { ValueReceivedvalidation } from "../../../validation/validation";


const buttonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px"
}

const ValueReceived = ({ fetchDataFromApi, dateObj }) => {

  const initialState = {
    startDate: { value: dateObj["startDate"], required: true },
    endDate: { value: dateObj["endDate"], required: true }
  }

  const [state, contextDispatch] = useContext(Context);
  const { handleChange, values, handleSubmit, errors, dateChange } = UseForm(submitForm, ValueReceivedvalidation, initialState);

  function submitForm(value) {

    var tempDate = new Date(values.endDate.value);
    var endDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + 1);

    distributorService.Value_Received({
      startDate: DateFormatter(new Date(values.startDate.value), 'payload'),
      endDate: DateFormatter(new Date(endDate), 'payload'),
    }).then(({ data }) => {
      fetchDataFromApi(data?.data);
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "success",
          message: data?.message,
        },
      });
    }).catch((err) => {
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "error",
          message: err?.response?.data?.message,
        },
      });
    })
  }

  return (
    <Grid sm={12} md={12}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="flex justify-center mt-5 flex-col">
          <label className="valueTransfer-label my-3 pt-2" >Start Date<span className="text-font-red text-sm font-bold">*</span></label>
          <div>
            <CustomDatePicker dateChange={dateChange} name="startDate" placeholder={"Start Date"} selected={values.startDate.value} />
            {errors.startDate && <p className="absolute errorText">{errors.startDate}</p>}
          </div>
          <label className="valueTransfer-label my-3 pt-2">End Date<span className="text-font-red text-sm font-bold">*</span></label>
          <div>
            <CustomDatePicker minDate={values.startDate.value} dateChange={dateChange} name="endDate" selected={values.endDate.value} placeholder={"End Date"} />
            {errors.endDate && <p className="absolute errorText">{errors.endDate}</p>}
          </div>
          <div className="flex justify-end mt-8">
            <CustomButton style={buttonStyle} label={"Submit"} />
          </div>
        </div>
      </form>
    </Grid>
  )

}


export default ValueReceived;