import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../components/common/customButton/customButton';
import CustomDatePicker from '../../components/common/customDatePicker/customDatePicker';
import CustomReportTable from '../../components/common/customTable/customReportTable/customReportTable';
import DateFormatter from '../../hooks/dateFormatter';
import UseForm from '../../hooks/useForm';
import distributorService from '../../services/distributor_service';
import { Constants } from '../../stateManagement/constants';
import { Context } from '../../stateManagement/context';
import { ValueReceivedvalidation } from '../../validation/validation';
import './index.css';
import CustomSelect from '../../components/common/customSelect/customSelect';
import CustomInput from '../../components/common/customInput/customInput';

const buttonStyle = {
  width: '230px',
  lineHeight: '17px',
  letterSpacing: '4px',
};

const filterType=[
  {label:"Connection Number",value:"mobile"},
  {label:"Category",value:"category"}
];


const BBPSReportsSD = () => {
  const [fetchedData, setFetchedData] = useState({ headers: [], data: [] });
  const [loading, setLoading] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [state, contextDispatch] = useContext(Context);
  const [category,setCategory]=useState([]);

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const dateObj = { startDate: startDate, endDate: today };

  const initialState = {
    startDate: { value: dateObj['startDate'], required: true },
    endDate: { value: dateObj['endDate'], required: true },
    mobile: { value: '', required: false },
    category: { value: '', required: false },
    filter:{value:filterType[0],require:false}
  };

  const { values, handleSubmit, errors, dateChange,selectChange,handleChange } = UseForm(
    submitForm,
    ValueReceivedvalidation,
    initialState,
  );

  function submitForm() {
    setLoading('loading');
    setFetchedData({ headers: [], data: [] });
    distributorService
      .Reports_BBPS_SD({
        fromDate: DateFormatter(new Date(values.startDate.value), 'report'),
        toDate: DateFormatter(new Date(values.endDate.value), 'report'),
        "filters": [
          {
              "field": "connectionNumber",
              "value": values.mobile.value,
              "operator": "iLike"
          },
          {
              "field": "category",
              "value": values.category.value.value,
              "operator": "like"
          }
      ]
      })
      .then(res => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'success',
            message: res.data.message,
          },
        });
        setLoading('');
        setFetchedData({ headers: res?.data?.header, data: res?.data?.data });
      })
      .catch(err => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'error',
            message: err?.response?.data?.message,
          },
        });
        setLoading('');
      });
  }

  useEffect(()=>{
    distributorService.bbps_list_category({"groupBy":false}).then((res)=>{
      const temp = res?.data?.data.map(item => ({
        value: item?.category,
        label: item?.category,
    }));
    setCategory(temp)
    }).catch((err)=>{
      console.log(err)
    })
  },[])

  useEffect(()=>{
    if(values.filter.value.value=='mobile'){
      values.category.value={ value: '', required: false}
    }else if(values.filter.value.value=='category'){
      values.mobile.value=''
    }
  },[values])
  return (
    <>
      <div className="m-5">
        <div className="relative bottom-28">
          <div className="myledger-modal-container modal-transparency mx-[10px]">
            <div className="tab-title tab-title-border tab-title-bold mb-[40px]">
              BBPS Report
            </div>
            <form className='flex justify-center' onSubmit={handleSubmit} autoComplete="off">
              <Grid
                md={10}
                container
                item
                columnSpacing={3}
                justifyContent="center"
                alignItems="center">
                <Grid md={6} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      dateChange={dateChange}
                      name="startDate"
                      selected={values.startDate.value}
                      placeholder={'start Date'}
                    />
                    {errors.startDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.startDate}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid md={6} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      minDate={values.startDate.value}
                      dateChange={dateChange}
                      name="endDate"
                      selected={values.endDate.value}
                      placeholder={'end Date'}
                    />
                    {errors.endDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.endDate}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid md={6} item className="my-5">
                <div className="flex justify-center flex-col mt-3">
                  <CustomSelect
                    name="filter"
                    handleChange={selectChange}
                    label={'filter'}
                    value={values.filter.value}
                    data={filterType}
                  />
                    {errors.filter && (
                      <p className="absolute errorText">{errors.filter}</p>
                    )}
                    </div>
                </Grid>
                <Grid md={6} item className="my-5">
                 {values.filter.value.value=='mobile'&&<div className="flex justify-center flex-col mt-3">
                    <CustomInput
                      name="mobile"
                      placeholder={'Search By Connection Number'}
                      handleChange={handleChange}
                      value={values.mobile.value}
                    />
                    {errors.mobile && (
                      <p className="absolute mt-16 errorText">
                        {errors.mobile}
                      </p>
                    )}
                  </div>}
                  {values.filter.value.value=='category'&&<div className="flex justify-center flex-col mt-3">
                  <CustomSelect
                    name="category"
                    handleChange={selectChange}
                    label={'Category'}
                    value={values.category.value}
                    data={category}
                  />
                    {errors.category && (
                      <p className="absolute mt-16 errorText">
                        {errors.category}
                      </p>
                    )}
                  </div>}
                </Grid>
                <div className="ledger-btn">
                  <CustomButton
                    variant={loading}
                    style={buttonStyle}
                    label={'Generate Report'}
                  />
                </div>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <CustomReportTable
        title="BBPS Report"
        fetchedData={fetchedData}
        fileName="BBPSReport"
        searchTerm={searchTerm}
      />
    </>
  );
};

export default BBPSReportsSD;
