import api from './api';

const token = sessionStorage.getItem('user');

console.log(JSON.parse(token)?.accessToken);

const Create_Distributor = data => api.post(`/distributor/create`, data);

const List_Kits = (data) => api.post(`/user/list/settings`, data);

const User_KYC = (data) => api.post(`/user/kyc`, data);

const Terms_Conditions = data => api.post(`/user/tnc/checked`, data);

const Aadhaar_OTP_Validate = (data) => api.post(`/user/kyc/aadhaar/validateotp`, data);

const Create_Retailer = data => api.post(`/retailer/create`, data);

const List_All_Type = data => api.post(`/user/list`, data);

const Buy_Kits = data => api.post(`/ktm/buy`, data);

const Buy_Kits_List = () => api.post(`/distributor/kit/purchase/history`);

const Transfer_Kits = data => api.post(`/ktm/transfer`, data);

const Distributor_View_Child = () => api.post(`distributor/child/list`);

const Pan_Validation = data => api.post(`/user/pan/check`, data);

const Transfer_Value_View_Chain = data => api.post(`/user/chain`, data);

const Value_Transfer_Report = data =>
  api.post(`/fund/value_transfer/history`, data);

const Transfer_Value_Forward = data => api.post(`/fund/transfer`, data);

const Transfer_Value_Backward = data =>
  api.post(`/fund/transfer/reverse`, data);

const Tpin_Generate = () => api.post(`/user/t-pin/sendOtp`);

const TpinSet = data => api.post(`/user/tpin/set`, data);

const Tpinverify = data => api.post(`/user/tpin/verify`, data);

const GetProfile = () => api.get(`/user/profile/get`);

const Transfer_Value = data => api.post(`/fund/transfer/request`, data);

const Payment_Gateway = data => api.post(`/pg/rz/link/create`, data);

const FundLoad_Req = data => api.post(`/fund/request/list`, data);

const Value_Transfer_List = data => api.post(`/fund/requester/list`, data);

const Value_Transfer_Accept = data => api.post(`/fund/request/accept`, data);

const Value_Transfer_Reject = data => api.post(`/fund/request/reject`, data);

const Transaction_List_Table = data =>
  api.post('distributor/list/transaction', data);
const Buy_Kits_Issued = () => api.post(`/distributor/list/kit/issued`);

const Kit_Balance = () => api.get(`distributor/kit/available`);

const My_Ledger = data => api.post(`distributor/list/myLedger`, data);

const My_Earnings = data => api.post(`/user/myearnings`, data);

const Get_Virtual_Account = data => api.get(`pg/rz/myaccount`, data);

const Value_Received = data => api.post(`fund/value_transfer/history`, data);

const Forget_Password_Request = data => api.post(`auth/otp/request`, data);

const Change_Password = data => api.post(`auth/resetpassword`, data);

const Login_Set_New_Password = data => api.post(`/auth/setnewpassword`, data);

const Forgot_Password_OTP_Verify = data => api.post(`/auth/verify`, data);

const Payment_Gateway_Razor_Pay = data => api.post(`/pg/rz/link/create`, data);

const Payment_Gateway_CashFree_Pay = data =>
  api.post(`/pg/cf/paylink/create`, data);

const Dashboad_Chainbalance = data => api.post(`user/list/chain/balance`);

const Payment_Mode = () => api.get(`/fund/list/payment/mode`);

const Payment_Banks = () => api.get(`/fund/list/payment/bank`);

const Request_WFP = data => api.post(`/fund/request/wfp`, data);

const Payment_Gateway_List = data => api.post(`/pg/list/fundloads`, data);

const Reports_Wallet_SD = data => api.post(`report/user/wallet`, data);

const Reports_Wallet_DT = data => api.post(`report/user/wallet`, data);

const Reports_DMT = data => api.post(`report/dmt`, data);

const Reports_Recharge_DT = data => api.post(`report/recharge`, data);

const Reports_Recharge_SD = data => api.post(`report/recharge`, data);

const Reports_Fund_Transfer_DT = data =>
  api.post(`reports/dt/fundtransfer`, data);

const Reports_Fund_Transfer_SD = data =>
  api.post(`reports/sd/fundtransfer`, data);

const Reports_Credit_Card_SD = data => api.post(`report/creditcard`, data);

const Reports_Credit_Card_DT = data => api.post(`report/creditcard`, data);

const Reports_AEPS_Wallet_DT = data => api.post(`report/aeps`, data);

const Reports_AEPS_Wallet_SD = data => api.post(`report/aeps`, data);

const Reports_BBPS_DT = data => api.post(`/report/bbps`, data);

const Reports_BBPS_SD = data => api.post(`/report/bbps`, data);

const Ledger_Report = (data) => api.post(`/report/user/wallet`, data);

const List_Settings_SA = (data) => api.post(`/superadmin/settings/list/`, data);

const List_Settings_AD = (data) => api.post(`/user/list/settings`, data);


const List_All_TypeSD = data => api.post(`/distributor/sd/list/retailers`, data);

const MATM_Report_DT = data => api.post(`/report/matm`, data);

const MATM_Report_SD = data => api.post(`/report/matm`, data);
const bbps_list_category=data=>api.get(`/bbps/list/operator/categorys`,data)
const KYCDMTreports=data=>api.post(`/report/kyc/dmt`,data)


const distributorService = {
  MATM_Report_DT,
  MATM_Report_SD,
  List_Kits,
  List_Settings_SA,
  List_Settings_AD,
  Create_Distributor,
  User_KYC,
  Aadhaar_OTP_Validate,
  Create_Retailer,
  List_All_Type,
  Buy_Kits,
  Buy_Kits_List,
  Transfer_Kits,
  Distributor_View_Child,
  Pan_Validation,
  Transfer_Value_View_Chain,
  Transfer_Value_Forward,
  Transfer_Value_Backward,
  Tpin_Generate,
  TpinSet,
  GetProfile,
  Transfer_Value,
  Tpinverify,
  Payment_Gateway,
  FundLoad_Req,
  Value_Transfer_Accept,
  Value_Transfer_Reject,
  Transaction_List_Table,
  Buy_Kits_Issued,
  Kit_Balance,
  My_Ledger,
  My_Earnings,
  Get_Virtual_Account,
  Value_Received,
  Value_Transfer_List,
  Forget_Password_Request,
  Change_Password,
  Login_Set_New_Password,
  Forgot_Password_OTP_Verify,
  Payment_Gateway_Razor_Pay,
  Payment_Gateway_CashFree_Pay,
  Value_Transfer_Report,
  Dashboad_Chainbalance,
  Payment_Mode,
  Payment_Banks,
  Request_WFP,
  Payment_Gateway_List,
  Reports_Wallet_DT,
  Reports_Wallet_SD,
  Reports_DMT,
  Reports_Recharge_DT,
  Reports_Recharge_SD,
  Reports_Fund_Transfer_DT,
  Reports_Fund_Transfer_SD,
  Reports_Credit_Card_SD,
  Reports_Credit_Card_DT,
  Reports_AEPS_Wallet_DT,
  Reports_AEPS_Wallet_SD,
  Reports_BBPS_DT,
  Terms_Conditions,
  Reports_BBPS_SD,
  Ledger_Report,
  List_All_TypeSD,
  bbps_list_category,
  KYCDMTreports
};

export default distributorService;
