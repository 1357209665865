import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../components/common/customButton/customButton';
import CustomDatePicker from '../../components/common/customDatePicker/customDatePicker';
import CustomReportTable from '../../components/common/customTable/customReportTable/customReportTable';
import DateFormatter from '../../hooks/dateFormatter';
import UseForm from '../../hooks/useForm';
import distributorService from '../../services/distributor_service';
import { Constants } from '../../stateManagement/constants';
import { Context } from '../../stateManagement/context';
import { ValueReceivedvalidation } from '../../validation/validation';
import './index.css';
import CustomSelect from '../../components/common/customSelect/customSelect';
import CustomInput from '../../components/common/customInput/customInput';

const buttonStyle = {
  width: '230px',
  lineHeight: '17px',
  letterSpacing: '4px',
};

const filterType=[
  {label:"Remitter Number",value:"remitterNo"},
  {label:"Beneficiary Account Number",value:"beneficiary"},
  {label:"Transaction Id",value:"transactionId"}
];

const DMTReportsDT = () => {
  const [fetchedData, setFetchedData] = useState({ headers: [], data: [] });
  const [loading, setLoading] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [state, contextDispatch] = useContext(Context);

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const dateObj = { startDate: startDate, endDate: today };

  const initialState = {
    startDate: { value: dateObj['startDate'], required: true },
    endDate: { value: dateObj['endDate'], required: true },
    remitterNo: { value: '', required: false },
    beneficiary: { value: '', required: false },
    ecapsTxnId: { value: '', required: false },
    filter:{value:filterType[0],require:false}
  };

  const { values, handleSubmit, errors, dateChange,selectChange,handleChange } = UseForm(
    submitForm,
    ValueReceivedvalidation,
    initialState,
  );

  function submitForm() {
    setLoading('loading');
    setFetchedData({ headers: [], data: [] });
    distributorService
      .Reports_DMT({
        fromDate: DateFormatter(new Date(values.startDate.value), 'report'),
        toDate: DateFormatter(new Date(values.endDate.value), 'report'),
        "filters": [
          {
              "field": "remitterNo",
              "value": values.remitterNo.value,
              "operator": "like"
          },
          {
              "field": "beneficiaryAccountNo",
              "value": values.beneficiary.value,
              "operator": "like"
          },
          {
            "field": "transactionId",
            "value": values.ecapsTxnId.value,
            "operator": "like"
        }
      ]
      })
      .then(res => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'success',
            message: res.data.message,
          },
        });
        setLoading('');
        setFetchedData({ headers: res?.data?.header, data: res?.data?.data });
      })
      .catch(err => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'error',
            message: err.response.data.message,
          },
        });
        setLoading('');
      });
  }

  
  useEffect(()=>{
    if(values.filter.value.value=='remitterNo'){
      values.beneficiary.value=''
      values.ecapsTxnId.value=''
    }else if(values.filter.value.value=='beneficiary'){
      values.remitterNo.value='';
      values.ecapsTxnId.value='';
    }else if(values.filter.value.value=='transactionId'){
      values.beneficiary.value='';
      values.remitterNo.value='';
    }

  },[values])

  return (
    <>
      <div className="m-5">
        <div className="relative bottom-28">
          <div className="myledger-modal-container modal-transparency mx-[10px]">
            <div className="tab-title tab-title-border tab-title-bold mb-[40px]">
              DMT Report
            </div>
            <form className='flex justify-center' onSubmit={handleSubmit} autoComplete="off">
              <Grid
                md={10}
                container
                item
                columnSpacing={3}
                justifyContent="center"
                alignItems="center">
                <Grid md={6} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      dateChange={dateChange}
                      name="startDate"
                      selected={values.startDate.value}
                      placeholder={'start Date'}
                    />
                    {errors.startDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.startDate}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid md={6} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      minDate={values.startDate.value}
                      dateChange={dateChange}
                      name="endDate"
                      selected={values.endDate.value}
                      placeholder={'end Date'}
                    />
                    {errors.endDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.endDate}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid md={6} item className="my-5">
                <div className="flex justify-center flex-col mt-3">
                  <CustomSelect
                    name="filter"
                    handleChange={selectChange}
                    label={'filter'}
                    value={values.filter.value}
                    data={filterType}
                  />
                    {errors.filter && (
                      <p className="absolute errorText">{errors.filter}</p>
                    )}
                    </div>
                </Grid>
                <Grid md={6} item className="my-5">
                 {values.filter.value.value=='remitterNo'&&<div className="flex justify-center flex-col mt-3">
                    <CustomInput
                      name="remitterNo"
                      placeholder={'Search By RemitterNo'}
                      handleChange={handleChange}
                      value={values.remitterNo.value}
                    />
                    {errors.remitterNo && (
                      <p className="absolute mt-16 errorText">
                        {errors.remitterNo}
                      </p>
                    )}
                  </div>}
                  {values.filter.value.value=='beneficiary'&&<div className="flex justify-center flex-col mt-3">
                    <CustomInput
                      name="beneficiary"
                      placeholder={'Search By Beneficiary Account Number'}
                      handleChange={handleChange}
                      value={values.beneficiary.value}
                    />
                    {errors.beneficiary && (
                      <p className="absolute mt-16 errorText">
                        {errors.beneficiary}
                      </p>
                    )}
                  </div>}
                  {values.filter.value.value=='transactionId'&&<div className="flex justify-center flex-col mt-3">
                    <CustomInput
                      name="ecapsTxnId"
                      placeholder={'Search By Transaction Id'}
                      handleChange={handleChange}
                      value={values.ecapsTxnId.value}
                    />
                    {errors.ecapsTxnId && (
                      <p className="absolute mt-16 errorText">
                        {errors.ecapsTxnId}
                      </p>
                    )}
                  </div>}
                </Grid>
                <div className="ledger-btn">
                  <CustomButton
                    variant={loading}
                    style={buttonStyle}
                    label={'Generate Report'}
                  />
                </div>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <CustomReportTable
        title="DMT Report"
        fetchedData={fetchedData}
        fileName="DMTReport"
        searchTerm={searchTerm}
      />
    </>
  );
};

export default DMTReportsDT;
