import { useState } from "react";
import CustomHorizontalStepper from "../../../components/common/customHorizontalStepper/CustomHorizontalStepper";
import CreateDistributor from "./createDistributor.jsx";
import CreateDistributorSummary from "./createDistributorSummary.jsx";
import "./createdistributor.css";
import DistributorForm from "./distributorForm.jsx";

function CreateRetailerModal() {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState(null);

  return (
    <>
      <div className="flex justify-center mb-10">
        <div className="modal-transparency w-96" id="createdt-yourchain-modal-container">
          <CustomHorizontalStepper activeStep={activeStep} steps={[1, 2, 3]} />
        </div>
      </div>
      {activeStep === 1 && <CreateDistributor setFormData={setFormData} setActiveStep={setActiveStep} />}
      {activeStep === 2 && <DistributorForm formData={formData} setFormData={setFormData} setActiveStep={setActiveStep} />}
      {activeStep === 3 && <CreateDistributorSummary formData={formData} setFormData={setFormData} setActiveStep={setActiveStep} />}
    </>
  );
}

export default CreateRetailerModal;

