import Grid from "@mui/material/Unstable_Grid2";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../components/common/customButton/customButton";
import CustomDatePicker from "../../../../components/common/customDatePicker/customDatePicker";
import CustomInput from "../../../../components/common/customInput/customInput";
import CustomSelect from "../../../../components/common/customSelect/customSelect";
import DateFormatter from "../../../../hooks/dateFormatter";
import UseForm from "../../../../hooks/useForm";
import distributorService from "../../../../services/distributor_service";
import { Constants } from "../../../../stateManagement/constants";
import { Context } from "../../../../stateManagement/context";
import { Requestvalidation } from "../../../../validation/validation";

const buttonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px",
  // margin: "24px 0px"
};

const initialState = {
  amount: {
    value: "",
    required: true,
    regexp: /^[1-9][0-9]*$/,
    minLength: 1,
    minLengthMessage: "At least 1 characters long!",
    maxLength: 7,
    maxLengthMessage: "Too many characters!",
  },
  payment: { value: "", required: true },
  refNo: {
    value: "",
    required: true,
    minLength: 3,
    minLengthMessage: "At least 3 characters long!",
    maxLength: 30,
    maxLengthMessage: "Too many characters!",
  },
  bank: { value: "", required: true },
  paymentDate: { value: "", required: true },
};

const selectBank = [{ value: "yes bank", label: "YES BANK" }];

// const selectPaymentMode = [{ value: "upi", label: "UPI" }];

const RequestValue = () => {
  const [sendData, setSendData] = useState(null);
  const [state, contextDispatch] = useContext(Context);
  const [fetchSelectData, setFetchSelectData] = useState({
    paymentMode: [],
    paymentBank: [],
  });

  const [paymentModeData, setPaymentModeData] = useState({
    data: [],
    isLoading: true,
  });
  const [paymentBankData, setPaymentBankData] = useState({
    data: [],
    isLoading: true,
  });

  useEffect(() => {
    distributorService.Payment_Mode().then((res) => {
      setPaymentModeData({ data: res?.data?.data, isLoading: false });
    });
    distributorService.Payment_Banks().then((res) => {
      setPaymentBankData({ data: res?.data?.data, isLoading: false });
    });
  }, []);

  const selectPaymentMode = paymentModeData?.data?.map((item) => ({
    value: item?.mode,
    label: item?.mode,
  }));

  const selectPaymentBanks = paymentBankData?.data?.map((item) => ({
    value: item?.bankName,
    label: item?.bankName,
  }));

  const {
    handleChange,
    selectChange,
    values,
    handleSubmit,
    errors,
    dateChange,
    clearState,
    resetKey,
  } = UseForm(submitForm, Requestvalidation, initialState);

  function submitForm(value) {
    distributorService
      .Transfer_Value({
        amount: Number(values.amount.value),
        bank: values.bank.value.value,
        paymentMode: values.payment.value.value,
        paymentDate: DateFormatter(
          new Date(values.paymentDate.value),
          "payload"
        ),
        ReferanceNo: values.refNo.value,
      })
      .then((res) => {
        contextDispatch({
          type: Constants.CUSTOM_MODAL,
          payload: { isOpen: false, modalProps: {} },
        });
        contextDispatch({
          type: Constants.SHOW_POPUP,
          payload: {
            showLotti: true,
            lottiSeverity: "success",
          },
        });
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: "success",
            message: res?.data?.message,
          },
        });
        contextDispatch({
          type: Constants.CALL_API,
          payload: !state[0]?.callApi,
        });
        clearState(initialState);
      })
      .catch((err) => {
        contextDispatch({
          type: Constants.CUSTOM_MODAL,
          payload: { isOpen: false, modalProps: {} },
        });
        contextDispatch({
          type: Constants.SHOW_POPUP,
          payload: {
            showLotti: true,
            lottiSeverity: 'fail',
            message: err?.response?.data?.message
          },
        });
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: "error",
            message: err?.response?.data?.message,
          },
        });
      });
  }

  const handleInputChange = (e) => {
    const pattern = /^[1-9][0-9]*$/;
    if (pattern.test(e.target.value)) {
      handleChange(e);
    } else if (e.target.value === "") {
      resetKey(initialState.amount, "amount");
    }
  };

  return (
    <Grid sm={12} md={12}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="flex justify-center mt-5 flex-col">
          <label className="valueTransfer-label my-3 pt-2">
            Amount<span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomInput
              max={7}
              placeholder={"Enter an Amount"}
              name="amount"
              value={values.amount.value}
              handleChange={handleInputChange}
            />
            {errors.amount && (
              <p className="absolute errorText">{errors.amount}</p>
            )}
          </div>
          <label className="valueTransfer-label my-3 pt-2">
            Payment Mode
            <span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomSelect
              data={selectPaymentMode}
              name="payment"
              value={values.payment.value}
              handleChange={selectChange}
              label="Payment Mode"
              isLoading={paymentModeData?.isLoading}
            />
            {errors.payment && (
              <p className="absolute errorText">{errors.payment}</p>
            )}
          </div>
          <label className="valueTransfer-label my-3 pt-2">
            Ref No<span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomInput
              max={30}
              name="refNo"
              value={values.refNo.value}
              handleChange={handleChange}
              placeholder={"Ref No"}
            />
            {errors.refNo && (
              <p className="absolute errorText">{errors.refNo}</p>
            )}
          </div>
          <label className="valueTransfer-label my-3 pt-2">
            Bank<span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomSelect
              data={selectPaymentBanks}
              name="bank"
              value={values.bank.value}
              handleChange={selectChange}
              label="Bank"
              isLoading={paymentBankData?.isLoading}
            />
            {errors.bank && <p className="absolute errorText">{errors.bank}</p>}
          </div>
          <label className="valueTransfer-label my-3 pt-2">
            Payment Date
            <span className="text-font-red text-sm font-bold">*</span>
          </label>
          <div>
            <CustomDatePicker
              dateChange={dateChange}
              name="paymentDate"
              placeholder={"Payment Date"}
              selected={values.paymentDate.value}
            />
            {errors.paymentDate && (
              <p className="absolute errorText">{errors.paymentDate}</p>
            )}
          </div>
          <div className="flex justify-between items-center mt-8">
            <label className="valueTransfer-label">
              {/* Your Balance Rs:3485.50 */}
            </label>
            <CustomButton style={buttonStyle} label={"Submit"} />
          </div>
        </div>
      </form>
      {/* <CustomModal buttonLabel={"Submit"} name={"requestValue"} header={"Enter T-PIN"} data={sendData} modalType="requestValue" /> */}
    </Grid>
  );
};

export default RequestValue;
