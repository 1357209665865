import { createContext, useReducer } from 'react';
import TokenService from '../services/token_service';
import { Constants } from './constants';

const Context = createContext();

const initState = [
  {
    SnackBar: { isOpen: false, severity: '', message: '' },
    login: {
      showForgotPasswordForm: false,
      showOTPForm: false,
      showLoginForm: true,
      forgotOtpVerifyForm: false,
      setPasswordForm: false,
    },
    activeTabId: 1,
    Popup: { showLotti: false, lottiSeverity: '', message: '' },
    OTPModal: { isOpen: false },
    cutomModal: {
      isOpen: false,
      modalProps: {},
    },
    callApi: false,
    callBalanceApi: false,
    userType: TokenService.getUserType(),
    userId: '',
    // MyLedger: {
    //   date: {
    //     startDate: "",
    //     endDate: "",
    //   },
    // },
  },
];

function ContextProvider({ children }) {
  const Reducer = (state, action) => {
    console.log('state', state);
    switch (action.type) {
      case Constants.SHOW_SNACKBAR:
        return [{ ...state[0], ['SnackBar']: action.payload }];
      case Constants.SHOW_POPUP:
        return [{ ...state[0], ['Popup']: action.payload }];
      case Constants.SHOW_OTP_MODAL:
        return [{ ...state[0], ["OTPModal"]: action.payload }];
      case Constants.HANDLE_LOGIN:
        return [{ ...state[0], ['login']: action.payload }];
      case Constants.UPDATE_USERID:
        return [{ ...state[0], ['userId']: action.payload }];
      case Constants.ACTIVE_TAB_ID:
        return [{ ...state[0], ['activeTabId']: action.payload }];
      case Constants.USER_TYPE:
        return [{ ...state[0], ['userType']: action.payload }];
      case Constants.CUSTOM_MODAL:
        return [{ ...state[0], ['cutomModal']: action.payload }];
      case Constants.CALL_API:
        return [{ ...state[0], ['callApi']: action.payload }];
      case Constants.CALL_BALANCE_API:
        return [{ ...state[0], ['callBalanceApi']: action.payload }];
      default:
        return [{ ...state[0] }];
    }
  };

  const [state, contextDispatch] = useReducer(Reducer, initState);

  return (
    <Context.Provider value={[state, contextDispatch]}>
      {children}
    </Context.Provider>
  );
}

export { Context, ContextProvider };

