import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomTab from "../../../components/common/customTab/customTab";
import CustomTable from "../../../components/common/customTable/customTable";
import DateFormatter from "../../../hooks/dateFormatter";
import { ValueTransferList } from "../../../hooks/useTableData";
import distributorService from "../../../services/distributor_service";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import TransferByNumber from "../TransferModal/transferByNumber/transferByNumber";
import TransferValue from "./transferValue/transferValue";
import "./transfermodal.css";

const tablehead = [
  {
    name: "User ID",
    selector: (row) => row?.edgeId,
    sortable: true,
    id: "edgeId",
    width: "100px !important",
  },
  {
    name: "Name",
    selector: (row) => row?.name,
    sortable: true,
    id: "name",
    width: "200px !important",
  },
  {
    name: "Payment Mode",
    selector: (row) => row?.paymentMode,
    sortable: true,
    id: "paymentMode",
    format: (row) => row?.paymentMode?.toUpperCase(),
    width: "200px !important",
  },
  {
    name: "Referance No",
    selector: (row) => row?.ReferanceNo,
    sortable: true,
    id: "ReferanceNo",
    width: "150px !important",
  },
  {
    name: "Bank",
    selector: (row) => row?.bank,
    sortable: true,
    id: "bank",
    width: "150px !important",
  },
  {
    name: "Status",
    selector: (row) => row?.status,
    sortable: true,
    id: "status",
    format: (row) => row?.status?.toUpperCase(),
    width: "150px !important",
  },
  {
    name: "Amount",
    selector: (row) => row?.amount,
    sortable: true,
    id: "amount",
    width: "150px !important",
  },
  {
    name: "PaymentDate",
    selector: (row) => row.paymentDate,
    sortable: true,
    id: "paymentDate",
    format: (row) => DateFormatter(row?.paymentDate, "table"),
    width: "150px !important",
  },
  {
    name: "Request Date",
    selector: (row) => row?.createdAt,
    sortable: true,
    id: "createdAt",
    format: (row) => DateFormatter(row?.createdAt, "table"),
    width: "150px !important",
  },
];

const values = [
  { id: 1, text: "Transfer Value" },
  { id: 2, text: "Transfer By Number" },
];

const TransferModal = () => {
  const [pending, setPending] = useState(true);
  const [state, contextDispatch] = useContext(Context);

  const handleTabSelected = (val) =>
    contextDispatch({
      type: Constants.ACTIVE_TAB_ID,
      payload: val,
    });

  const [fetchedTableData, setFetchedTableData] = useState("");

  function RenderTab(value) {
    switch (value) {
      case 1:
        return <TransferValue />;
      case 2:
        return <TransferByNumber />;
      default:
        return <TransferValue />;
    }
  }

  useEffect(() => {
    let mounted = true;
    const getFundLoadReq = async () => {
      const res = await distributorService.Value_Transfer_List();
      if (mounted) {
        setPending(false);
        setFetchedTableData(res?.data?.data);
      }
    };
    getFundLoadReq();
    return () => {
      mounted = false;
      setPending(true);
    };
  }, [state[0]?.callApi]);

  const { tabledata, table_Head } = ValueTransferList(fetchedTableData, tablehead);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <div className='value-transfer-container modal-transparency'>
            <CustomTab values={values} selected={handleTabSelected} />
            {RenderTab(state[0]?.activeTabId)}
          </div>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
      <div className='mt-10 bg-white p-5 rounded-xl table-container'>
        <CustomTable tablehead={table_Head} pending={pending} tabledata={tabledata} />
      </div>
    </>
  );
};

export default TransferModal;
