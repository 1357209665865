import TransactionsModal from "./transactionsModal/transactionsModal";

function Transactions() {
    return(
        <div className="m-5">
        <div className="relative bottom-20">
        <TransactionsModal />
        </div>
        </div>
    )
}

export default Transactions;