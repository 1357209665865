import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { TextField } from "@mui/material";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomDatePicker from "../../../components/common/customDatePicker/customDatePicker";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomSelect from '../../../components/common/customSelect/customSelect';
import UseForm from "../../../hooks/useForm";
import { RetailerDetailsvalidation } from "../../../validation/validation";
import "./distributorform.css";

const selectData = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

const buttonStyle = {
  width: "240px",
  paddingLeft: "32px",
  paddingRight: "32px",
  lineHeight: "17px",
  letterSpacing: "4px",
};

const selectStyle = {
  borderRadius: "12px",
  backgroundColor: "white",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  height: "42px",
  width: "100%",
};

function DistributorForm({ setActiveStep, setFormData, formData }) {

  const initialState = {
    // kits: { value: "", required: true },
    firstName: { value: formData?.firstName?.value ?? formData?.firstName, required: true },
    lastName: { value: formData?.lastName?.value ?? formData?.lastName, required: true },
    mobile: {
      value: formData?.mobile?.value ? formData?.mobile?.value : "",
      required: true,
      regexp: /^[0-9]*$/,
      minLength: 10,
      minLengthMessage: "Mobile must be atleast 10 characters long!",
      maxLength: 10,
      maxLengthMessage: "Too many characters!",
    },
    pan: { value: formData?.pan?.value ? formData?.pan?.value : formData?.pan, required: true },
    aadhaar: { value: formData?.aadhaar?.value ? formData?.aadhaar?.value : formData?.aadhaar, required: true },
    dob: { value: "", required: true },
    email: { value: formData?.email?.value ? formData?.email?.value : "", required: true, email: true, emailMessage: 'Email address is not valid!' },
    address: { value: formData?.address?.value ?? formData?.address, required: true },
    shopName: { value: formData?.shopName?.value ? formData?.shopName?.value : "", required: true },
    gender: { value: formData?.gender?.value, required: true },
  };

  // const [checked, setChecked] = useState(false);

  const { handleChange, values, handleSubmit, errors, dateChange, selectChange } = UseForm(submitForm, RetailerDetailsvalidation, initialState);

  function submitForm() {
    setActiveStep(3);
    setFormData(values);
  }

  // const handleChangeCheckBox = (e) => {
  //   setChecked((prev) => !prev);
  // }

  function handleBackClick() {
    setActiveStep(1);
  }

  return (
    <div id="createdt-form-container" className="bg-[#ffffff80] relative">
      <ArrowBackRoundedIcon onClick={handleBackClick} className='cursor-pointer hover:rounded-full hover:bg-slate-100' />
      <form onSubmit={handleSubmit} autoComplete="off">
        <p id="createdt-form-title" className='mt-5'>Distributor Details</p>
        <div className="flex justify-between gap-x-20">
          <div className="createdt-form">
            <div>
              <label className="w-3/12">First Name</label>
              <div className="w-2/3">
                <CustomInput name="firstName" value={values.firstName.value} handleChange={handleChange} placeholder={"Enter First Name"} />
                {errors.firstName && <p className="absolute errorText">{errors.firstName}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Last Name</label>
              <div className="w-2/3">
                <CustomInput name="lastName" value={values.lastName.value} handleChange={handleChange} placeholder={"Enter Last Name"} />
                {errors.lastName && <p className="absolute errorText">{errors.lastName}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Mobile</label>
              <div className="w-2/3">
                <CustomInput max={10} name="mobile" value={values.mobile.value} handleChange={handleChange} variant={"mobile"} placeholder={"Enter Mobile Number"} />
                {errors.mobile && <p className="absolute errorText">{errors.mobile}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Aadhaar</label>
              <div className="w-2/3">
                <CustomInput disabled={true} min={12} max={12} name="aadhaar" value={values.aadhaar.value} handleChange={handleChange} placeholder={"Enter Aadhaar Number"} />
                {errors.aadhaar && <p className="absolute errorText">{errors.aadhaar}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Gender</label>
              <div className="w-2/3">
                <CustomSelect
                  style={selectStyle}
                  data={selectData}
                  name="gender"
                  value={values.gender.value}
                  handleChange={selectChange}
                  label="Select Gender"
                />
                {errors.gender && <p className="absolute errorText">{errors.gender}</p>}
              </div>
            </div>
          </div>
          <div className="createdt-form">
            <div>
              <label className="w-3/12">Shop Name</label>
              <div className="w-2/3">
                <CustomInput name="shopName" value={values?.shopName?.value} handleChange={handleChange} placeholder={"Enter Shop Name"} />
                {errors.shopName && <p className="absolute errorText">{errors.shopName}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">PAN</label>
              <div className="w-2/3">
                <CustomInput disabled={true} name="pan" value={values.pan.value} handleChange={handleChange} placeholder={"Enter pan Number"} />
                {errors.pan && <p className="absolute errorText">{errors.pan}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Date of Birth</label>
              <div className="w-2/3">
                <CustomDatePicker name="dob" dateChange={dateChange} placeholder={"Select a date"} selected={values.dob.value} />
                {errors.dob && <p className="absolute errorText">{errors.dob}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Email ID</label>
              <div className="w-2/3">
                <CustomInput name="email" value={values.email.value} handleChange={handleChange} placeholder={"Enter E-mail ID"} />
                {errors.email && <p className="absolute errorText">{errors.email}</p>}
              </div>
            </div>
            <div>
              <label className="w-3/12">Address</label>
              <div className="w-2/3">
                <TextField
                  placeholder='Enter Address'
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}
                  multiline={true}
                  minRows={4}
                  name="address"
                  onChange={handleChange}
                  value={values.address.value}
                  sx={{
                    borderRadius: "8px",
                    minHeight: "100px",
                    width: "100%",
                    height: "100%",
                    padding: "8px 24px",
                    background:
                      "linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%)",
                    boxShadow: " 0px 1px 2px rgb(0 0 0 / 25%)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                      "&.MuiInputBase-root": {
                        padding: 0,
                        color: "#112467",
                      }
                    },
                  }}
                />
                {errors.address && <p className="absolute errorText">{errors.address}</p>}
              </div>
            </div>
            {/* <div>
              <div className='w-1/2'>
                <label className="w-3/12">Create Virtual Account</label>
                <CustomCheckBox checked={checked} handleChange={handleChangeCheckBox} />
              </div>
            </div> */}
          </div>
        </div>
        <div className="createdt-form-submitbtn">
          <CustomButton style={buttonStyle} label="Next" />
        </div>
      </form>
    </div>
  );
}

export default DistributorForm;
