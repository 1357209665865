import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../../components/common/customButton/customButton";
import CustomDatePicker from "../../components/common/customDatePicker/customDatePicker";
import CustomSelect from "../../components/common/customSelect/customSelect";
import CustomReportTable from "../../components/common/customTable/customReportTable/customReportTable";
import DateFormatter from '../../hooks/dateFormatter';
import UseForm from "../../hooks/useForm";
import { Constants } from "../../stateManagement/constants";
import { Context } from "../../stateManagement/context";
import { ValueReceivedvalidation } from "../../validation/validation";
import distributorService from "../../services/distributor_service";
import "./index.css";

const buttonStyle = {
  width: "230px",
  lineHeight: "17px",
  letterSpacing: "4px",
};

const LedgerReport = () => {
  const [superDistributor, setSuperDistributor] = useState("");
  const [fetchedData, setFetchedData] = useState({ headers: [], data: [] });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState("");
  const [state, contextDispatch] = useContext(Context);


  const listUserType = [
    { label: "Retailer", value: "retailer" },
  ];

  const initialState = {
    startDate: { value: "", required: true },
    endDate: { value: "", required: true },
    selectedUser: { value: "", required: true },
    userType: { value: listUserType[0], required: true },
  };

  const {
    handleChange,
    selectChange,
    values,
    handleSubmit,
    errors,
    dateChange,
    resetKey,
    clearState
  } = UseForm(submitForm, ValueReceivedvalidation, initialState);

  function submitForm() {
    setLoading("loading");
    setFetchedData({ headers: [], data: [] });

    distributorService.Ledger_Report({
      fromDate: DateFormatter(new Date(values.startDate.value), 'payload'),
      toDate: DateFormatter(new Date(values.endDate.value), 'payload'),
      userType: values.userType?.value?.value,
      userId: values.selectedUser?.value?.value
    }).then((res) => {
      setLoading("");
      setFetchedData({ headers: res?.data?.header, data: res?.data?.data });
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "success",
          message: res.data.message,
        },
      });
    }).catch((err) => {
      setLoading("");
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "error",
          message: err?.response?.data?.message,
        },
      });
    })
  };

  useEffect(() => {
    distributorService
      .List_All_Type({ userType: "retailer", listAll: false })
      .then((data) => {
        setSuperDistributor(data?.data);
      })
  }, [])

  const listSuperDistributor = [];

  const handleUserType = (e) => {
    selectChange(e);
    // resetKey({ label: "", value: "", required: true}, "selectedUser");
    clearState({
      startDate: { value: values.startDate.value, required: true },
      endDate: { value: values.endDate.value, required: true },
      selectedUser: { value: "", required: true },
      userType: { value: e.value, required: true },
    });
    distributorService
      .List_All_Type({ userType: e?.value?.value, listAll: false })
      .then((data) => {
        setSuperDistributor(data?.data);
      })
  }

  superDistributor.data?.map((item) => {
    listSuperDistributor.push({
      value: `${item.id}`,
      label: `${item.edgeId} - ${item.firstName} ${item.lastName}`,
    });
  });

  return (
    <>
      <div className='m-5'>
        <div className='relative bottom-28'>
          <div className='myledger-modal-container modal-transparency mx-[10px]'>
            <div className='tab-title tab-title-border tab-title-bold mb-[40px]'>Ledger By User</div>
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Grid md={12} container item columnGap={1} spacing={2} justifyContent='center' alignItems='center'>
                <Grid md={4} item className='my-5'>
                  <div className='flex justify-center flex-col'>
                    <CustomDatePicker dateChange={dateChange} name='startDate' selected={values.startDate.value} placeholder={"Start Date"} />
                    {errors.startDate && <p className='absolute mt-16 errorText'>{errors.startDate}</p>}
                  </div>
                </Grid>
                <Grid md={4} item className='my-5'>
                  <div className='flex justify-center flex-col'>
                    <CustomDatePicker minDate={values.startDate.value} dateChange={dateChange} name='endDate' selected={values.endDate.value} placeholder={"End Date"} />
                    {errors.endDate && <p className='absolute mt-16 errorText'>{errors.endDate}</p>}
                  </div>
                </Grid>
                <Grid md={4} item className='my-5'>
                  <div className=''>
                    <CustomSelect
                      handleChange={handleUserType}
                      name="userType"
                      data={listUserType}
                      label={listUserType[0]?.label}
                      value={values?.userType?.value}
                    />
                    {errors.userType && (
                      <p className="absolute errorText">{errors.userType}</p>
                    )}
                  </div>
                </Grid>
                <Grid md={4} item className='my-5'>
                  <div className=''>
                    <CustomSelect
                      handleChange={selectChange}
                      name="selectedUser"
                      data={listSuperDistributor}
                      label={`Select ${values?.userType?.value?.label}`}
                      value={values?.selectedUser?.value}
                    />
                    {errors.selectedUser && (
                      <p className="absolute errorText">{errors.selectedUser}</p>
                    )}
                  </div>
                </Grid>
                <div className='ledger-btn'>
                  <CustomButton variant={loading} style={buttonStyle} label={"Generate Report"} />
                </div>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <CustomReportTable title='Ledger Report' fetchedData={fetchedData} fileName='LedgerReport' searchTerm={searchTerm} />
    </>
  );
};

export default LedgerReport;
