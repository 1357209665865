import { useState } from 'react';
import Copyright from '../components/common/copyright/copyright';
import Header from "../components/common/header/header";
import SideBar from "../components/sidebar";
import { AllRoutesData } from "../routes/routes";
import "./layout.css";
import TokenService from '../services/token_service';

const Layout = ({ children }) => {

  let Sidebar_Data = [];
  const user = JSON.parse(sessionStorage.getItem("user"));

  if (TokenService.getUser()) {

    Sidebar_Data = AllRoutesData().filter((item) => {
      if (user?.userType === "distributor" && item?.chainType.includes(user?.chainType) && item?.roles.includes(user?.userType)) {
        return item;
      }
      else if (user?.userType === "super_distributor" && item?.roles.includes(user?.userType)) {
        return item;
      }
    });
  }

  const [sidebarType, setSidebarType] = useState(true);

  const handleSidebarType = () => {
    setSidebarType(!sidebarType);
  }

  return (
    <div className="layout-container">
      <SideBar Sidebar_Data={Sidebar_Data} sidebarType={sidebarType} handleSidebarType={handleSidebarType} />
      <main
        style={{ width: sidebarType ? "calc(100% - 328px)" : "calc(100% - 80px)", right: sidebarType ? "-328px" : "-80px" }}
        className="relative main-container" >
        <Header />
        {children}
        <Copyright color={"text-bluebutton"} />
      </main>
    </div>
  )
}

export default Layout;