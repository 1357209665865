const getLocalRefreshToken = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user?.accessToken;
};

const getUserType = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user?.userType;
};

const updateNewAccessToken = token => {
  let user = JSON.parse(sessionStorage.getItem('user'));
  user.accessToken = token;
  sessionStorage.setItem('user', JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(sessionStorage.getItem('user'));
};

const setUser = user => {
  sessionStorage.setItem('user', JSON.stringify(user));
};

const removeUser = () => {
  sessionStorage.removeItem('user');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateNewAccessToken,
  getUser,
  getUserType,
  setUser,
  removeUser,
};

export default TokenService;
