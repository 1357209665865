import AssessmentIcon from "@mui/icons-material/Assessment";
import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import SidebarControl from "../../../assets/svg/nav/sidebarControl.svg";
import { Context } from '../../../stateManagement/context';
import "./sidebarclosed.css";
import TokenService from "../../../services/token_service";


const SidebarClosed = ({ handleSidebarType, Sidebar_Data }) => {
  const [state, contextDispatch] = useContext(Context);

  const [openChild, setOpenChild] = useState("");

  const navigate = useNavigate();

  const showSubnav = (id) =>
    id
      ? openChild === id
        ? setOpenChild("")
        : setOpenChild(id)
      : setOpenChild("");

  return (
    <div className="flex">
      <div style={{ width: "80px" }} className="sidebar">
        <div className="w-full flex items-center justify-center">
          <img className='h-[40px] w-[40px] mt-5 cursor-pointer' alt="ecaps logo" src={process.env.REACT_APP_LOGIN_IMAGE_1} onClick={() => navigate("/dashboard")}></img>
        </div>
        <div className="sidebarclosed-openicon">
          <img
            className='cursor-pointer'
            onClick={handleSidebarType}
            alt="SidebarControl"
            src={SidebarControl}
          />
        </div>
        <div className="sidebarclosed-container">
          {Sidebar_Data?.map((item, i) => (
            <div id="sidebarclosed-navlink-container" key={i}>
              <div>
                <NavLink
                  className={`${openChild === item?.navId ? "navlink active" : "navlink"
                    }`} // Parent link
                  activeclassname="active"
                  to={item.path}
                  onClick={() => {
                    item.subNav ? showSubnav(item.navId) : showSubnav();
                  }}
                >
                  <div className={"flex items-center justify-center w-full"}>
                    <div className="flex items-center mx-auto">
                      <span className="icon">{item.icon}</span>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div>
                {openChild === item?.navId &&
                  item?.subNav?.filter((sub) => sub?.roles?.includes(state[0]?.userId) && sub?.chainType?.includes(TokenService.getUser()?.chainType)).map((item, i) => {
                    return (
                      <NavLink //Child link
                        className={`${openChild === item?.navId
                          ? "navlink active"
                          : "navlink"
                          } mt-4`} // need action
                        activeclassname="active"
                        to={item.path}
                        key={i}
                      >
                        <div className={"flex items-center m-auto"}>
                          <span>
                            <AssessmentIcon />
                          </span>
                        </div>
                      </NavLink>
                    );
                  })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SidebarClosed;
