import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useContext, useEffect } from "react";
import fail from "../../../assets/gif/fail.gif";
import success from "../../../assets/gif/success.gif";
import error_modal from "../../../assets/svg/error_modal.svg";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import CustomButton from "../customButton/customButton";
import "./popUp.css";

const Popup = () => {
  const [state, contextDispatch] = useContext(Context);

  useEffect(() => {
    if (state[0]?.Popup?.lottiSeverity === "success") {
      let timer = setTimeout(() => {
        contextDispatch({
          type: Constants.SHOW_POPUP,
          payload: {
            showLotti: false,
            lottiSeverity: '',
            message: ""
          },
        });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [state[0]?.Popup?.showLotti]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // height:350,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '48px',
    boxShadow: 24,
    background:
      state[0]?.Popup?.lottiSeverity === 'success'
        ? '#17AD37'
        : 'linear-gradient(135deg, #EC7D2D 0%, #EB2D2D 100%)',
    p: 4,
    outline: 'none',
  };

  const errorModalStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    overflow: "hidden",
    // height:350,
    // bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '4px',
    // boxShadow: 24,
    background: '#d96b5a',
    outline: 'none',
  }

  const modalCloseStyle = {
    width: "100px",
    marginBottom: "20px",
    height: "34px",
    backgroundColor: "#d96b5a",
    borderRadius: "2px",
    color: "white",
    fontWeight: 500,
  }

  function handleModalClose() {
    contextDispatch({
      type: Constants.SHOW_POPUP,
      payload: {
        showLotti: false,
        lottiSeverity: '',
        message: ""
      },
    });
  }

  return (
    state[0]?.Popup?.lottiSeverity === 'success' ?
      <Modal
        disableScrollLock
        sx={{ outline: 'none', zIndex: 99999 }}
        open={state[0]?.Popup?.showLotti ?? false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h1 className="mb-5 text-center response-modal-heading">
            {state[0]?.Popup?.lottiSeverity === 'success'
              ? 'Transaction Success'
              : 'Transaction Failed'}
          </h1>
          <img
            className="w-65 h-56 flex m-auto"
            alt="gif"
            src={
              state[0]?.Popup?.lottiSeverity === 'success' ? success : fail
            }></img>
          <p className="mt-5 text-center response-modal-paragraph">
            {process.env.REACT_APP_LOTTIE}
          </p>
        </Box>
      </Modal> :
      <Modal
        disableScrollLock
        sx={{ outline: 'none', zIndex: 99999 }}
        open={state[0]?.Popup?.showLotti ?? false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={errorModalStyle}>
          <div className='h-full rounded-sm p-3'>
            <div className='bg-[#fefefe] rounded-sm flex items-center flex-col'>
              <img className='w-3/4 my-10' src={error_modal} />
              <p className='text-[#d96b5a] text-2xl error-modal-text relative'>Ups, sorry!</p>
              <p className='text-[#7f8694] px-5 relative mt-10 mb-5'>{state[0]?.Popup?.message ?? ""}</p>
              <CustomButton variant="error_modal" handleClick={handleModalClose} style={modalCloseStyle} label="Close" />
            </div>
          </div>
        </Box>
      </Modal>
  );
};

export default Popup;
