import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import {Pagination} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {CSVLink} from 'react-csv';
import {v4 as uuidv4} from 'uuid';
import DownloadIcon from '../../../../assets/svg/download.svg';
// import PrintIcon from '../../../../assets/svg/print.svg';
import noDataImg from '../../../../assets/nodatatable.svg';
import CustomButton from '../../customButton/customButton';
import './customReportTable.css';

const IconButtonStyling = {
  '&.MuiIconButton-root': {
    padding: 0,
    color: 'white',
    opacity: 0.1,
    '&:hover': {
      opacity: 1,
    },
  },
};

const PaginationStyles = {
  '& .MuiPaginationItem-rounded': {
    // backgroundColor: "#ffffff",
    color: '#112467',
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: 'Libre Franklin',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px',
    border: 'none',
    fontWeight: 400,
    '&:hover': {
      // backgroundColor: "#D4B051",
    },
  },
  '& .MuiPaginationItem-ellipsis': {
    color: '#112467',
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: 'Libre Franklin',
    boxShadow: 'none',
    border: 'none',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  '& 	.Mui-selected': {
    backgroundColor: '#112467 !important',
    fontSize: '14px',
    fontFamily: 'Libre Franklin',
    color: 'white',
    fontWeight: 400,
  },
};

function CustomReportTable({fetchedData, title, fileName, searchTerm}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState('20');
  const [displayData, setDisplayData] = useState([]);
  const [sortData, setSortData] = useState({type: '', key: ''});

  useEffect(() => {
    const data = fetchedData?.data?.slice(
      currentPage * pageLimit - pageLimit,
      pageLimit * currentPage,
    );
    // if (fetchedData.data.length === 0 && pageLimit !== 1)
    if (fetchedData.data.length === 0) {
      setCurrentPage(1);
      setPageLimit('20');
    }
    if (data.length === 0) {
      setCurrentPage(1);
    }
    setDisplayData(data);
  }, [currentPage, pageLimit, fetchedData.data, sortData.key, sortData.type]);

  function handlePageChange(e, val) {
    setCurrentPage(val);
  }

  function handlePageLimit(e) {
    setPageLimit(e.target.value);
  }

  function rowIterator(item) {
    const temp = [];

    const content = ['Remarks', 'Bank Details'];

    for (const property in item) {
      if (fetchedData.headers.includes(property)) {
        temp.push(
          <td
            style={{
              textOverflow: content.includes(property) && 'unset',
              maxWidth: content.includes(property) && 'none',
            }}
            key={uuidv4()}>
            {item[property]}
          </td>,
        );
      }
    }
    return <tr key={uuidv4()}>{temp}</tr>;
  }

  function handleSorting(item, sortType) {
    if (sortType === 'asc') {
      setSortData({key: item, type: 'dec'});
      return fetchedData.data.sort(function (a, b) {
        return a[item].toString().localeCompare(b[item].toString(), 'en', {
          numeric: true,
        });
      });
    }
    if (sortType === 'dec') {
      setSortData({key: item, type: 'asc'});
      return fetchedData.data.sort(function (a, b) {
        return b[item].toString().localeCompare(a[item].toString(), 'en', {
          numeric: true,
        });
      });
    }
  }

  // const filteredRows=useMemo(()=>{
  //   if (!searchTerm) return fetchedData.data;
  //   if (fetchedData.data.length > 0) {
  //     const attributes = Object.keys(fetchedData.data[0]);
  //     const list = [];
  //     for (const current of fetchedData.data) {
  //       for (const attribute of attributes) {
  //         // if (attribute === "key") {
  //         //   continue;
  //         // }
  //         const value = current[attribute];
  //         if (value && value === searchTerm) {
  //           const found = fetchedData.data.find((row) => row.key === current.key);
  //           if (found) {
  //             list.push(found);
  //           }
  //         }
  //       }
  //     }
  //     return list;
  //   }

  //   return [];

  // },[searchTerm])

  const filterdRows = useMemo(() => {
    const keys = [
      'User Type',
      'User ID',
      'Balance After',
      'Balance Before',
      'User Name',
    ];
    if (!searchTerm) return fetchedData.data;
    if (fetchedData.data.length > 0) {
      return fetchedData.data.filter(item => {
        return keys.some(key => {
          return item[key]
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase());
        });
      });
    }
  }, [searchTerm]);

  return fetchedData.data.length > 0 ? (
    <div className="bg-white p-5 my-5 mx-[30px] table-container relative top-[-90px] mb-10">
      <div className="report-title-container">
        <span className="report-title">{title}</span>
        <span className="report-button-conatiner">
          {/* <CustomButton svgIcon={PrintIcon} variant="report" label={'Print '} /> */}
          <CSVLink
            filename={`${fileName}.csv`}
            data={fetchedData.data}
            headers={fetchedData.headers}>
            <CustomButton
              svgIcon={DownloadIcon}
              variant="report"
              label={'Download '}
            />
          </CSVLink>
        </span>
      </div>
      <div className="custom-report-table-container">
        <table>
          <thead>
            <tr>
              {fetchedData.headers.map(item => (
                <th
                  className="cursor-pointer report-table-header-icon-container"
                  key={uuidv4()}
                  onClick={() =>
                    handleSorting(item, sortData.type ? sortData.type : 'asc')
                  }>
                  <div className="report-table-header-icon-wrapper">
                    {item}
                    <span
                      className="min-w-[24px] report-table-header-icon"
                      style={
                        sortData.key === item ? {opacity: 1} : {opacity: 0}
                      }>
                      <ArrowDownwardRoundedIcon
                        sx={
                          sortData.type === 'dec'
                            ? {transform: 'rotate(180deg)'}
                            : {transform: 'rotate(0deg)'}
                        }
                        fontSize="small"
                      />
                    </span>
                    {/* <IconButton  size='small' sx={IconButtonStyling}>
                      {sortData.type === "dec" && sortData.key === item ? <ArrowDownwardRoundedIcon fontSize='small' sx={{ transform: "rotate(180deg)" }} /> : <ArrowDownwardRoundedIcon fontSize='small' />}
                    </IconButton> */}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{displayData?.map(item => rowIterator(item))}</tbody>
        </table>
      </div>
      <div className="report-pagination-container">
        <div>
          <span className="pagination-label">Show</span>
          <select
            onChange={handlePageLimit}
            // defaultValue={20}
            value={pageLimit}
            className="pagination-limit-selector">
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
          <span className="pagination-label">entries</span>
        </div>
        <div className="report-paginator-container">
          <span>
            {currentPage * pageLimit - pageLimit + 1}-
            {pageLimit * currentPage > fetchedData.data.length
              ? fetchedData.data.length
              : pageLimit * currentPage}
            of {fetchedData.data.length}
          </span>
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            sx={PaginationStyles}
            variant="outlined"
            shape="rounded"
            showLastButton
            showFirstButton
            count={Math.ceil(fetchedData.data.length / pageLimit)}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="p-5 my-5 mx-[30px] table-container report-nodata-container"
      style={{marginBottom: '100px'}}>
      <img className="report-nodata-image" src={noDataImg} />
      <p className="report-nodata-title">No Data</p>
    </div>
  );
}

export default CustomReportTable;
