import { lazy, Suspense } from 'react';
import Loader from '../components/common/loader/loader';
import ErrorBoundary from '../components/errorBoundary/errorBoundary';
import Layout from '../Layout/Layout';
import AepsWalletReportDT from '../pages/aepsWalletReportDt';
import AepsWalletReportSD from '../pages/aepsWalletReportSd';
import BBPSReportsDT from '../pages/bbpsReportsDT';
import BBPSReportsSD from '../pages/bbpsReportsSD';
import BuyKits from '../pages/buyKits';
import CreditCardReportsDT from '../pages/creditCardReportsDT';
import CreditCardReportsSD from '../pages/creditCardReportsSD';
import Dashboard from '../pages/dashboard';
import CreateDistributor from '../pages/distributor-management/create-distributor';
import DistributorList from '../pages/distributor-management/list-distributor';
import DMTReportsDT from '../pages/dmtReportsDT';
import DMTReportsSD from '../pages/dmtReportsSD';
import FundLoad from '../pages/fundLoad';
import FundTransferReportsDT from '../pages/fundTransferReportsDT';
import FundTransferReportsSD from '../pages/fundTransferReportsSD';
import LedgerReportDt from '../pages/ledgerReportDt';
import LedgerReportSd from '../pages/ledgerReportSd';
import MATMReportDT from "../pages/matmReportDt";
import MATMReportSD from "../pages/matmReportSd";
import PageNotFound from '../pages/pageNotFound/pageNotFound';
import Payments from '../pages/payments';
import PaymentsVirtualAccount from '../pages/paymentsVirtualAccount';
import RechargeReportsDT from '../pages/rechargeReportsDT';
import RechargeReportsSD from '../pages/rechargeReportsSD';
import MyEarnings from '../pages/report/my-earnings';
import MyLedger from '../pages/report/my-ledger';
import CreateRetailer from '../pages/retailer-management/create-retailer';
import RetailerList from '../pages/retailer-management/retailer-list';
import Tpin from '../pages/tPin';
import Transactions from '../pages/transactions';
import TransferKits from '../pages/transferKits';
import RequestValue from '../pages/valueTransfer/RequestModal';
import TransferModal from '../pages/valueTransfer/TransferModal';
import ValueTransferReport from '../pages/valueTransferReport';
import WalletReportsDT from '../pages/walletReportsDT';
import WalletReportsSD from '../pages/walletReportsSD';
import ProtectedRoute from './protected-routes';
import { appRoutes } from './routes';
import KYCDMTReportDT from '../pages/kycDmtReportDt';
import KYCDMTReportSD from '../pages/kycDmtReportSD';


const Login = lazy(() => import('../pages/login/login'));

const routeConfig = [
  {
    path: appRoutes.UNKNOWN,
    element: <PageNotFound />,
  },
  {
    path: appRoutes.LOGIN,
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: appRoutes.DASHBOARD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Dashboard />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.PAYMENTS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Payments />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.VIRTUAL_ACCOUNT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <PaymentsVirtualAccount />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.TRANSFER_VALUE,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <TransferModal />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.REQUEST_VALUE,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <RequestValue />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.VALUE_TRANSFER_REPORT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <ValueTransferReport />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.FUND_LOAD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <FundLoad />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.CREATE_RETAILER,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <CreateRetailer />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.RETAILER_LIST,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <RetailerList />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.CREATE_DISTRIBUTOR,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <CreateDistributor />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.DISTRIBUTOR_LIST,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <DistributorList />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.TRANSACTION,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Transactions />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.TPIN,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <Tpin />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  // {
  //   path: appRoutes.MY_LEDGER,
  //   element: (
  //     <ProtectedRoute>
  //       <Layout>
  //         <ErrorBoundary>
  //           <MyLedger />
  //         </ErrorBoundary>
  //       </Layout>
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: appRoutes.MY_EARNINGS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <MyEarnings />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.BUY_KITS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <BuyKits />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.TRANSFER_KITS,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <TransferKits />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.WALLET_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <WalletReportsSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.WALLET_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <WalletReportsDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.DMT_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <DMTReportsDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.DMT_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <DMTReportsSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.RECHARGE_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <RechargeReportsDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.RECHARGE_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <RechargeReportsSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.FUND_TRANSFER_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <FundTransferReportsDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.FUND_TRANSFER_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <FundTransferReportsSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.CREDIT_CARD_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <CreditCardReportsDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.CREDIT_CARD_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <CreditCardReportsSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.AEPS_WALLET_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <AepsWalletReportDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.AEPS_WALLET_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <AepsWalletReportSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },

  {
    path: appRoutes.MATM_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <MATMReportDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.MATM_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <MATMReportSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },


  {
    path: appRoutes.BBPS_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <BBPSReportsDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.BBPS_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <BBPSReportsSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.LEDGER_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <LedgerReportDt />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.LEDGER_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <LedgerReportSd />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.KYCDMT_REPORT_DT,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <KYCDMTReportDT />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.KYCDMT_REPORT_SD,
    element: (
      <ProtectedRoute>
        <Layout>
          <ErrorBoundary>
            <KYCDMTReportSD />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },

];

export default routeConfig;
