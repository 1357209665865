import TPinModal from "./tPinModal/tPinModal";


const Tpin = () => {

  return (
    <div className="m-5">
      <div className="relative bottom-20 flex justify-center">
        <TPinModal />
      </div>
    </div>
  );
};

export default Tpin;
