import {Grid} from '@mui/material';
import './paymentModal.css';
// import CustomTable from "../../../components/common/customTable/customTable";
import React, {useContext, useEffect, useState} from 'react';
import PGMoney from '../../../../src/assets/money.svg';
import CustomButton from '../../../components/common/customButton/customButton';
import CustomInput from '../../../components/common/customInput/customInput';
import CustomSelect from '../../../components/common/customSelect/customSelect';
import CustomTable from '../../../components/common/customTable/customTable';
import DateFormatter from '../../../hooks/dateFormatter';
import UseForm from '../../../hooks/useForm';
import {PaymentGatewayList} from '../../../hooks/useTableData';
import distributorService from '../../../services/distributor_service';
import {Constants} from '../../../stateManagement/constants';
import {Context} from '../../../stateManagement/context';
import {VirtualAccountvalidation} from '../../../validation/validation';

const initialState = {
  amount: {
    value: '',
    required: true,
    regexp: /^[1-9][0-9]*$/,
    minLength: 3,
    minLengthMessage: 'At least 3 characters long!',
    maxLength: 7,
    maxLengthMessage: 'Too many characters!',
  },
  pg: {value: '', required: true},
};

const buttonStyle = {
  width: '100%',
  marginTop: '38px',
  lineHeight: '20px',
  letterSpacing: '4px',
};

const selectStyle = {
  borderRadius: '12px',
  backgroundColor: 'white',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
  height: '42px',
  width: '100%',
};

const selectData = [
  //{ value: "razor pay", label: "Razor Pay" },
  {value: 'cash free', label: 'Cash Free Pay'},
];

function PaymentModal() {
  const tablehead = [
    {
      name: 'User Name',
      selector: row => row?.name,
      sortable: true,
      id: 'name',
      // width: "200px !important"
    },
    {
      name: 'Payment Provider',
      selector: row => row?.provider,
      sortable: true,
      id: 'provider',
      // width: "200px !important"
    },
    {
      name: 'Payment Status',
      selector: row => row?.status,
      sortable: true,
      id: 'status',
      // width: "200px !important",
      format: row => row?.status?.toUpperCase(),
    },
    {
      name: 'Amount',
      selector: row => row?.amount,
      sortable: true,
      id: 'amount',
      // width: "200px !important"
    },
    {
      name: 'Payment Date',
      selector: row => row?.createdAt,
      sortable: true,
      id: 'createdAt',
      // width: "200px !important",
      format: row => DateFormatter(row?.createdAt, 'table'),
    },
  ];

  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    distributorService.Payment_Gateway_List().then(res => {
      setFetchedData(res?.data?.data);
    });
  }, []);

  const rowSpanData = `Minimum Amount Rs. ${100}/-`;
  const [loading, setLoading] = useState('');
  const [state, contextDispatch] = useContext(Context);

  const {handleChange, selectChange, values, handleSubmit, errors, resetKey} =
    UseForm(submitForm, VirtualAccountvalidation, initialState);

  const {tabledata, table_Head} = PaymentGatewayList(fetchedData, tablehead);

  function submitForm(value) {
    setLoading('loading');
    if (values?.pg?.value?.value === 'razor pay') {
      distributorService
        .Payment_Gateway_Razor_Pay({amount: Number(values.amount.value)})
        .then(res => {
          window.open(res?.data?.url);
          setLoading('');
        })
        .catch(err => {
          setLoading('');
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: 'error',
              message: err?.response?.data?.message,
            },
          });
        });
    } else {
      distributorService
        .Payment_Gateway_CashFree_Pay({amount: Number(values.amount.value)})
        .then(res => {
          window.open(res?.data?.url);
          setLoading('');
        })
        .catch(err => {
          setLoading('');
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: 'error',
              message: err?.response?.data?.message,
            },
          });
        });
    }
  }

  const handleInputChange = e => {
    const pattern = /^[1-9][0-9]*$/;
    if (pattern.test(e.target.value)) {
      handleChange(e);
    } else if (e.target.value === '') {
      resetKey(initialState.amount, 'amount');
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <div id="payment-modal" className="modal-transparency">
            <p className="text-center" id="payment-modal-title">
              Initiate Payment Gateway
            </p>

            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="mt-4">
                <CustomInput
                  max={7}
                  variant={'symbol'}
                  name="amount"
                  value={values.amount.value}
                  handleChange={handleInputChange}
                  placeholder={'Enter Amount'}
                />
                {errors.amount && (
                  <p className="absolute errorText">{errors.amount}</p>
                )}
              </div>
              <div className="mt-9">
                <CustomSelect
                  icon={PGMoney}
                  style={selectStyle}
                  data={selectData}
                  name="pg"
                  value={values.pg.value}
                  handleChange={selectChange}
                  label="Select Payment Gateway"
                />
                {errors.pg && <p className="absolute errorText">{errors.pg}</p>}
              </div>
              <CustomButton
                variant={loading}
                style={buttonStyle}
                label={'Initiate Payment Gateway'}
              />
            </form>
          </div>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
      <div className="mt-5 bg-white p-5 rounded-xl table-container">
        <CustomTable
          tablehead={table_Head}
          tabledata={tabledata}
          rowSpanData={rowSpanData}
        />
      </div>
    </>
  );
}

export default PaymentModal;
