import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import CustomButton from "../../../components/common/customButton/customButton";
import CustomInput from "../../../components/common/customInput/customInput";
import CustomSelect from "../../../components/common/customSelect/customSelect";
import CustomTable from "../../../components/common/customTable/customTable";
import DateFormatter from '../../../hooks/dateFormatter';
import UseForm from "../../../hooks/useForm";
import { TransactionListDMT, TransactionListRecharge } from '../../../hooks/useTableData';
import serviceConstants from "../../../services/constants";
import distributorService from "../../../services/distributor_service";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import { TransactionsValidation } from "../../../validation/validation";
import "./transactionsmodal.css";

const buttonStyle = {
  width: "20%",
  lineHeight: "20px",
  letterSpacing: "4px"
}

const exportButtonStyle = {
  width: "30%",
  lineHeight: "17px",
  letterSpacing: "4px",
  fontSize: "14px",
  fontWeight: 800,
  color: "#112467",
  marginTop: "20px",
};

const TransactionLisRechargehead = [
  {
    name: "Sl NO",
    selector: (row) => row.id,
    sortable: true,
    id: "id",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    id: "amount",
  },
  {
    name: "Created At",
    selector: (row) => row.createdAt,
    sortable: true,
    id: "createdAt",
    format: (row) => DateFormatter(row.createdAt, "table"),
  },
  {
    name: "Provider",
    selector: (row) => row.provider,
    sortable: true,
    id: "provider",
    format: (row) => row?.provider?.toUpperCase()
  },
  {
    name: "Recharge Number",
    selector: (row) => row.rechargeNumber,
    sortable: true,
    id: "rechargeNumber",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    id: "status",
    format: (row) => row?.status?.toUpperCase()
  },
  // {
  //   name: "OP. ID",
  //   selector: (row) => row.OperatorTransactionId,
  //   sortable: true,
  //   id: "OperatorTransactionId",
  // },
];

const TransactionListDMThead = [
  {
    name: "Sl NO",
    selector: (row) => row.id,
    sortable: true,
    id: "id",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    id: "amount",
  },
  {
    name: "Created At",
    selector: (row) => row.createdAt,
    sortable: true,
    id: "createdAt",
    format: (row) => DateFormatter(row.createdAt, "table"),
  },
  {
    name: "Remitter No",
    selector: (row) => row.remitterNo,
    sortable: true,
    id: "remitterNo",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    id: "status",
    format: (row) => row?.status?.toUpperCase()
  },
];


function TransactionsModal() {
  const [pending, setPending] = useState(false);
  const [state, contextDispatch] = useContext(Context);
  const [selectedChain, setSelectedChain] = useState([]);
  const [fetchTableData, setFetchTableData] = useState([]);

  const initialState = {
    retailer: { value: { label: "Retailer", value: "retailer" }, },
    retailerName: { value: "", retailerName: true },
    mobile: { value: "", phone: true, regexp: /^[0-9]*$/ },
  };

  const { handleChange, selectChange, values, handleSubmit, errors, dateChange, clearState } = UseForm(submitForm, TransactionsValidation, initialState);

  const searchSelector = [{ label: "Retailer", value: "retailer" }, { label: "Phone Number", value: "phonenumber" }];


  function submitForm() {
    setPending(true);
    setFetchTableData([]);
    distributorService.Transaction_List_Table({
      ...(values.retailer.value.value === "retailer" ? { userId: values.retailerName.value.value } : { mobileNumber: values.mobile.value }),
      transactionType: "recharge"
    })
      .then((data) => {
        setPending(false);
        setFetchTableData(data?.data?.data);
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: "success",
            message: data?.data?.message,
          },
        });
      }).catch((err) => {
        setPending(false);
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: "error",
            message: err?.response?.data?.message,
          },
        });
      })
    clearState(initialState);
  }


  const { tabledataRecharge, tableheadRecharge } = TransactionListRecharge(fetchTableData["recharges"], TransactionLisRechargehead);

  const { tabledataDMT, tableheadDMT } = TransactionListDMT(fetchTableData["dmtTransactions"], TransactionListDMThead,);

  const TheadRechargeCSV = tableheadRecharge.map((head) => {
    return ({ label: head.name, key: head.name })
  })

  const TbodyRechargeCSV = tabledataRecharge.map((body) => {
    return ({ "Sl NO": body.id, "Amount": body.amount, "Created At": DateFormatter(body.createdAt, "table"), "Provider": body.provider, "Recharge Number": body.rechargeNumber, "Status": body.status })
  })

  const TheadTransactionCSV = tableheadDMT.map((head) => {
    return ({ label: head.name, key: head.name })
  })

  const TbodyTransactioneCSV = tabledataDMT.map((body) => {
    return ({ "Sl NO": body.id, "Amount": body.amount, "Created At": DateFormatter(body.createdAt, "table"), "Remitter No": body.remitterNo, "Status": body.status })
  })

  useEffect(() => {
    let mounted = true;
    distributorService.Transfer_Value_View_Chain({ userType: serviceConstants.RETAILER })
      .then((data) => {
        setSelectedChain(data.data)
      })

    return () => (mounted = false);

  }, [])

  const obj = []

  selectedChain.data?.map((item) => {
    obj.push({ value: `${item.id}`, label: `${item.edgeId} - ${item.firstName} ${item.lastName}` })
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={1}></Grid>
        <Grid item md={10} className="">
          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-between relative modal-transparency" id="transactionmodal-container">
              <p id="transaction-searchtitle" className="whitespace-nowrap">Search By</p>
              <span className="relative w-full">
                <CustomSelect value={values.retailer.value == "retailer" ? searchSelector[0] : values.retailer.value} handleChange={selectChange} data={searchSelector} name="retailer" label={"Retailer"} />
                {errors.retailer && <p className="absolute left-0 errorText w-full text-left">{errors.retailer}</p>}
              </span>
              {(values.retailer.value === "retailer" || values.retailer.value.value === "retailer") && (<>
                <span className="relative w-full">
                  <CustomSelect value={values.retailerName.value} handleChange={selectChange} data={obj} name={"retailerName"} label={"Select Retailer"} />
                  {errors.retailerName && <p className="absolute left-0 errorText w-full text-left">{errors.retailerName}</p>}
                </span>
              </>)}
              {values.retailer.value.value === "phonenumber" && (<span className="relative w-full"><CustomInput handleChange={handleChange} name={"mobile"} max={10} value={values.mobile.value} variant={"mobile"} placeholder={"Enter Mobile Number"} />
                {errors.mobile && <p className="absolute left-0 errorText w-full text-left">{errors.mobile}</p>}</span>)}
              <span className="flex justify-center" id="transaction-report-btn"><CustomButton style={buttonStyle} label={"Search"} /></span>
            </div>
          </form>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
      <div className="bg-white p-5 mt-10 table-container">
        <h1 className='text-[#112467] my-2.5 font-bold'>Recharge Transactions List</h1>
        <CustomTable tablehead={tableheadRecharge} pending={pending} tabledata={tabledataRecharge} />
        <CSVLink filename={"TransactionRechargeReport.csv"} data={TbodyRechargeCSV} headers={TheadRechargeCSV}>
          <CustomButton
            style={exportButtonStyle}
            variant="export"
            label="Download to excel"
          />
        </CSVLink>
      </div>
      <div className="bg-white p-5 mt-10 table-container">
        <h1 className='text-[#112467] my-2.5 font-bold'>Ecaps DMT Transactions List</h1>
        <CustomTable tablehead={tableheadDMT} pending={pending} tabledata={tabledataDMT} />
        <CSVLink filename={"TransactionEcapsDMTReport.csv"} data={TbodyTransactioneCSV} headers={TheadTransactionCSV}>
          <CustomButton
            style={exportButtonStyle}
            variant="export"
            label="Download to excel"
          />
        </CSVLink>
      </div>
    </>
  )
}

export default TransactionsModal; 