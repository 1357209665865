import Grid from '@mui/material/Unstable_Grid2';
import {useContext} from 'react';
import CustomButton from '../../../components/common/customButton/customButton';
import CustomDatePicker from '../../../components/common/customDatePicker/customDatePicker';
import UseForm from '../../../hooks/useForm';
import {Constants} from '../../../stateManagement/constants';
import {Context} from '../../../stateManagement/context';
import {TransferRetailervalidation} from '../../../validation/validation';
import './myearnings.css';

const buttonStyle = {
  width: '30%',
  lineHeight: '17px',
  letterSpacing: '4px',
};

const MyEarnings = ({setData, dateObj}) => {
  const [state, contextDispatch] = useContext(Context);

  const initialState = {
    startDate: {value: dateObj['startDate'], required: true},
    endDate: {value: dateObj['endDate'], required: true},
  };

  function submitForm() {
    var tempDate = new Date(values.endDate.value);
    var endDate = new Date(
      tempDate.getFullYear(),
      tempDate.getMonth(),
      tempDate.getDate() + 1,
    );

    setData({startDate: values.startDate.value, endDate: endDate, userId: ''});
    contextDispatch({type: Constants.CALL_API, payload: !state[0]?.callApi});
  }

  const {values, handleSubmit, errors, dateChange, selectChange, clearState} =
    UseForm(submitForm, TransferRetailervalidation, initialState);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid
        md={12}
        container
        columnSpacing={3}
        justifyContent="center"
        alignItems="center">
        <Grid md={4}>
          <div className="flex justify-center flex-col my-5">
            <CustomDatePicker
              dateChange={dateChange}
              name="startDate"
              selected={values.startDate.value}
              placeholder={'start Date'}
            />
            {errors.startDate && (
              <p className="absolute mt-16 errorText">{errors.startDate}</p>
            )}
          </div>
        </Grid>
        <Grid md={4}>
          <div className="flex justify-center flex-col my-5">
            <CustomDatePicker
              minDate={values.startDate.value}
              dateChange={dateChange}
              name="endDate"
              selected={values.endDate.value}
              placeholder={'end Date'}
            />
            {errors.endDate && (
              <p className="absolute mt-16 errorText">{errors.endDate}</p>
            )}
          </div>
        </Grid>
        <div className="earnings-btn">
          <CustomButton style={buttonStyle} label="Generate Report" />
        </div>
      </Grid>
    </form>
  );
};

export default MyEarnings;
