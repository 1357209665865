import NotificationBanner from "./bannerComponent/notificationBanner";
import DashboardCard from "./dashboardCard/dashboardCard";
import "./index.css";

const Dashboard = () => {
  return (
    <div className="bg-body-dashboard">
      <NotificationBanner />
      <DashboardCard />
    </div>
  );
};

export default Dashboard;
