import { useContext, useEffect, useState } from "react";
import AnnouncementRing from "../../../assets/images/announcement_ring.png";
import Announcement from "../../../assets/svg/announcement.svg";
import distributorService from "../../../services/distributor_service";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";


function NotificationBanner() {
    const [listDataAD, setListDataAD] = useState(null);
    const [listDataSA, setListDataSA] = useState(null);
    const [state, contextDispatch] = useContext(Context);

    useEffect(() => {
        distributorService.List_Settings_AD({
            "key": "message"
        }).then((res) => {
            const concatenatedStrings = res?.data?.data?.map((item) => {
                const firstString = "&nbsp;&nbsp;&nbsp;";
                const secondString = item.value;
                return `<span style="display: flex;align-items:center;justify-content: center; margin-left: 50px;"><img style="height:10px;width:10px" src=${AnnouncementRing} />${firstString}</span>${secondString}`;
            });
            const newContent = concatenatedStrings ? concatenatedStrings.join('') : '';
            setListDataAD(newContent);
        }).catch((err) => {
            setListDataAD("");
            contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                    isOpen: true,
                    severity: "error",
                    message: err?.response?.data?.message,
                },
            });
        })
        distributorService.List_Settings_SA({
            "key": "message"
        }).then((res) => {
            const concatenatedStrings = res?.data?.data?.map((item) => {
                const firstString = "&nbsp;&nbsp;&nbsp;";
                const secondString = item.value;
                return `<span style="display: flex;align-items:center;justify-content: center; margin-left: 50px;"><img style="height:10px;width:10px" src=${AnnouncementRing} />${firstString}</span>${secondString}`;
            });
            const newContent = concatenatedStrings ? concatenatedStrings.join('') : '';
            setListDataSA(newContent);
        }).catch((err) => {
            setListDataAD("");
            contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                    isOpen: true,
                    severity: "error",
                    message: err?.response?.data?.message,
                },
            });
        })
    }, []);

    return (
        listDataAD && listDataSA && <div className="flex mx-[25px] h-14 mt-10 bg-white rounded-lg scrolling-text-container items-center relative">
            <span className="bg-[#112467] flex justify-center items-center w-14 z-10 h-full rounded-lg absolute">
                <img className="h-5 w-5" src={Announcement} />
            </span>
            <div className="scrolling-text text-[#212121] font-bold text-sm" dangerouslySetInnerHTML={{ __html: listDataAD + listDataSA }}></div>
        </div>
    )
}

export default NotificationBanner;