import { Grid } from '@mui/material';
import { useContext, useState } from 'react';
import CustomButton from '../../../components/common/customButton/customButton';
import CustomInput from '../../../components/common/customInput/customInput';
import CustomTab from '../../../components/common/customTab/customTab';
import UseForm from '../../../hooks/useForm';
import distributorService from '../../../services/distributor_service';
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import { PANAadharvalidation } from '../../../validation/validation';

const tabValues = [{id: 1, text: 'Create Retailer'}];

const buttonStyle = {
  width: '200px',
};

const initialState = {
  pan: {
    value: '',
    required: true,
    minLength: 10,
    minLengthMessage: 'Invalid PAN Number',
    maxLength: 10,
    maxLengthMessage: 'Too many characters!',
  },
  aadhaar: {
    value: '',
    required: true,
    minLength: 12,
    minLengthMessage: 'Invalid Aadhaar Number',
    maxLength: 12,
    maxLengthMessage: 'Too many characters!',
  },
};

function CreateRetailer({setFormData, setActiveStep}) {
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState('');
  const {handleChange, values, handleSubmit, errors, resetKey, setNewError} =
    UseForm(submitForm, PANAadharvalidation, initialState);

  function submitForm() {
    const pattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (pattern.test(values?.pan?.value)) {
      setPending('loading');
      distributorService
        .User_KYC({
          pan: values?.pan?.value,
          aadhaar: values?.aadhaar?.value,
        })
        .then(res => {
          setPending('');
          contextDispatch({
            type: Constants.SHOW_OTP_MODAL,
            payload: {
              isOpen: true,
              modalProps: {},
              modalContent:
                'An OTP has been send to your registered Aadhar mobile number',
              triggerFunction: (otpValue, setLoading) => {
                setLoading('loading');
                distributorService
                  .Aadhaar_OTP_Validate({
                    initiation_transaction_id: res?.data?.data?.decentroTxnId,
                    aadhaar_number: values.aadhaar.value,
                    otp: otpValue,
                  })
                  .then(res => {
                    setLoading('');
                    contextDispatch({
                      type: Constants.SHOW_OTP_MODAL,
                      payload: {isOpen: false, modalProps: {}},
                    });
                    const careOf = res?.data?.data?.address?.careOf
                      ? `${res?.data?.data?.address?.careOf}\n`
                      : '';
                    const house = res?.data?.data?.address?.house
                      ? `${res?.data?.data?.address?.house}\n`
                      : '';
                    const locality = res?.data?.data?.address?.locality
                      ? `${res?.data?.data?.address?.locality}\n`
                      : '';
                    const district = res?.data?.data?.address?.district
                      ? `${res?.data?.data?.address?.district} `
                      : '';
                    const country = res?.data?.data?.address?.country
                      ? `${res?.data?.data?.address?.country}\n`
                      : '';
                    const pincode = res?.data?.data?.address?.pincode
                      ? `${res?.data?.data?.address?.pincode}`
                      : '';
                    const address =
                      careOf + house + locality + district + country + pincode;
                    const appendData = {
                      pan: values.pan.value,
                      aadhaar: values.aadhaar.value,
                      firstName: res?.data?.data?.firstname,
                      lastName: res?.data?.data?.Lastname,
                      gender:
                        res?.data?.data?.gender === 'M'
                          ? {value: {value: 'M', label: 'Male'}}
                          : {value: 'F', label: 'Female'},
                      address: address,
                    };
                    setActiveStep(2);
                    setFormData(appendData);
                    contextDispatch({
                      type: Constants.SHOW_SNACKBAR,
                      payload: {
                        isOpen: true,
                        severity: 'success',
                        message: res?.data?.message,
                      },
                    });
                  })
                  .catch(err => {
                    setLoading('');
                    contextDispatch({
                      type: Constants.SHOW_OTP_MODAL,
                      payload: {isOpen: false, modalProps: {}},
                    });
                    contextDispatch({
                      type: Constants.SHOW_SNACKBAR,
                      payload: {
                        isOpen: true,
                        severity: 'error',
                        message: err?.response?.data?.message,
                      },
                    });
                  });
              },
              otpresendfuntion: () => {
                distributorService
                  .User_KYC({
                    pan: values?.pan?.value,
                    aadhaar: values?.aadhaar?.value,
                  })
                  .then(res => {
                    contextDispatch({
                      type: Constants.SHOW_SNACKBAR,
                      payload: {
                        isOpen: true,
                        severity: 'success',
                        message: res?.data?.message,
                      },
                    });
                  })
                  .catch(err => {
                    contextDispatch({
                      type: Constants.SHOW_POPUP,
                      payload: {
                        showLotti: true,
                        lottiSeverity: 'fail',
                        message: err?.response?.data?.message,
                      },
                    });
                  });
              },
            },
          });
        })
        .catch(err => {
          setPending('');
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: 'error',
              message: err?.response?.data?.message,
            },
          });
        });
    } else {
      setNewError('pan', 'Enter a valid pan Number');
    }
  }

  const handleInputChange = e => {
    let pattern;
    pattern = e.target.name === 'pan' ? /^[a-zA-Z0-9]+$/ : /^[1-9][0-9]*$/;
    if (pattern.test(e.target.value)) {
      handleChange(e);
    } else if (e.target.value === '') {
      resetKey(initialState[e.target.name], e.target.name);
    }
  };

  const handleTabSelected = val => {
    contextDispatch({
      type: Constants.ACTIVE_TAB_ID,
      payload: val,
    });
  };

  function handleUppercase(event) {
    event.target.value = event.target.value.toUpperCase();
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item sm={9}>
        <div
          className="modal-transparency relative"
          id="createRetailer-yourchain-modal-container">
          <CustomTab values={tabValues} selected={handleTabSelected} />
          <div className="mt-10 pb-10 createRetailer-yourchain-modal-customtable-container">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="relative">
                <label className="my-3 pt-2">PAN Number</label>
                <CustomInput
                  onInput={handleUppercase}
                  max={10}
                  value={values?.pan?.value}
                  handleChange={handleInputChange}
                  name="pan"
                  placeholder="Enter the PAN Number"
                />
                {errors.pan && (
                  <p className="absolute errorText">{errors.pan}</p>
                )}
              </div>
              <div className="relative mt-10">
                <label className="my-3 pt-2">Aadhaar Number</label>
                <CustomInput
                  max={12}
                  value={values?.aadhaar?.value}
                  handleChange={handleInputChange}
                  name="aadhaar"
                  placeholder="Enter the Aadhaar Number"
                />
                {errors.aadhaar && (
                  <p className="absolute errorText">{errors.aadhaar}</p>
                )}
              </div>
              <div className="absolute bottom-[-24px] left-0 flex justify-center w-full">
                <CustomButton
                  variant={pending}
                  style={buttonStyle}
                  label="Next"
                />
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default CreateRetailer;
