import {Grid} from '@mui/material';
import {useContext, useState} from 'react';
import CustomButton from '../../components/common/customButton/customButton';
import CustomDatePicker from '../../components/common/customDatePicker/customDatePicker';
import CustomReportTable from '../../components/common/customTable/customReportTable/customReportTable';
import DateFormatter from '../../hooks/dateFormatter';
import UseForm from '../../hooks/useForm';
import distributorService from '../../services/distributor_service';
import {Constants} from '../../stateManagement/constants';
import {Context} from '../../stateManagement/context';
import {ValueReceivedvalidation} from '../../validation/validation';
import './index.css';

const buttonStyle = {
  width: '230px',
  lineHeight: '17px',
  letterSpacing: '4px',
};

const MATMReportSD = () => {
  const [fetchedData, setFetchedData] = useState({headers: [], data: []});
  const [loading, setLoading] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [state, contextDispatch] = useContext(Context);

  var today = new Date();
  var startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const dateObj = {startDate: startDate, endDate: today};

  const initialState = {
    startDate: {value: dateObj['startDate'], required: true},
    endDate: {value: dateObj['endDate'], required: true},
  };

  const {values, handleSubmit, errors, dateChange} = UseForm(
    submitForm,
    ValueReceivedvalidation,
    initialState,
  );

  function submitForm() {
    setLoading('loading');
    setFetchedData({headers: [], data: []});
    distributorService
      .MATM_Report_SD({
        fromDate: DateFormatter(new Date(values.startDate.value), 'report'),
        toDate: DateFormatter(new Date(values.endDate.value), 'report'),
        filters:[]
      })
      .then(res => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'success',
            message: res.data.message,
          },
        });
        setLoading('');
        setFetchedData({headers: res?.data?.header, data: res?.data?.data});
      })
      .catch(err => {
        contextDispatch({
          type: Constants.SHOW_SNACKBAR,
          payload: {
            isOpen: true,
            severity: 'error',
            message: err.response.data.message,
          },
        });
        setLoading('');
      });
  }

  return (
    <>
      <div className="m-5">
        <div className="relative bottom-28">
          <div className="myledger-modal-container modal-transparency mx-[10px]">
            <div className="tab-title tab-title-border tab-title-bold mb-[40px]">
              MATM Report
            </div>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid
                md={12}
                container
                item
                columnSpacing={3}
                justifyContent="center"
                alignItems="center">
                <Grid md={4} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      dateChange={dateChange}
                      name="startDate"
                      selected={values.startDate.value}
                      placeholder={'start Date'}
                    />
                    {errors.startDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.startDate}
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid md={4} item className="my-5">
                  <div className="flex justify-center flex-col">
                    <CustomDatePicker
                      minDate={values.startDate.value}
                      dateChange={dateChange}
                      name="endDate"
                      selected={values.endDate.value}
                      placeholder={'end Date'}
                    />
                    {errors.endDate && (
                      <p className="absolute mt-16 errorText">
                        {errors.endDate}
                      </p>
                    )}
                  </div>
                </Grid>
                <div className="ledger-btn">
                  <CustomButton
                    variant={loading}
                    style={buttonStyle}
                    label={'Generate Report'}
                  />
                </div>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <CustomReportTable
        title="MATM Report"
        fetchedData={fetchedData}
        fileName="MATMReport"
        searchTerm={searchTerm}
      />
    </>
  );
};

export default MATMReportSD;
