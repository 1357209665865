// import './stepsDesign.css';

const CustomHorizontalStepper = ({ activeStep, steps }) => {

    return (<div className="flex justify-center select-none">
        {steps.map((step, i) => <div key={step} className="w-32 flex justify-center items-center relative">
            <div className={`h-10 w-10 flex justify-center items-center rounded-full ${i + 1 === activeStep && `bg-[#1124671A]`}`}>
                <span className={`h-8 w-8 flex justify-center items-center rounded-full ${i + 1 === activeStep ? `bg-[#112467] text-white border-2` : `bg-white text-[#80808F]`}`}>{step}</span>
            </div>
            {i > 0 && <span className="absolute top-5 w-1/2 -left-1/4 h-[1px] bg-slate-500"></span>}
        </div>
        )}
    </div>)
}


export default CustomHorizontalStepper;