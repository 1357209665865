import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import {Modal} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import distributorService from '../../services/distributor_service';
import CustomButton from '../common/customButton/customButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '8px',
  boxShadow: 24,
  background: 'white',
  p: 4,
  outline: 'none',
};

const buttonStyle = {
  width: '100%',
};

function TermsModal({termsModal, showTermsModal, isLogin}) {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [pending, setPending] = useState('');

  function handleButtonSubmit() {
    setPending('loading');
    distributorService
      .Terms_Conditions()
      .then(res => {
        setPending('');
        showTermsModal(false);
      })
      .catch(err => {
        setPending('');
      });
  }

  function handleModalClose() {
    showTermsModal(false);
  }

  function handleCheckboxChange(e) {
    if (e.target.checked) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }

  const scrollToBottom = () => {
    const container = document.getElementById('terms-container');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  return (
    <Modal
      sx={{outline: 'none', zIndex: 99999}}
      open={termsModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        {isLogin && (
          <span
            onClick={() => handleModalClose()}
            className="absolute top-3 right-3 cursor-pointer hover:scale-105 hover:bg-slate-300 hover:rounded-full">
            <CloseRoundedIcon />
          </span>
        )}
        <p className="text-[#112467] text-lg text-center mb-2 font-semibold">
          Terms & Conditions
        </p>
        <div
          id="terms-container"
          className={`overflow-y-scroll ${
            isLogin ? 'h-[calc(100%-32px)]' : 'h-[calc(100%-137px)]'
          } custom-scrollbar border rounded-md p-5 border-[#E2E8F0]`}>
          {/* {process.env.REACT_APP_TERMS_AND_CONDITIONS} */}
          <ol className="custom-list">
            <li>
              1. Scope and Application
              <ol className="my-2">
                <li>
                  1.1 {process.env.REACT_APP_COMPANY_NAME} is a private limited
                  company incorporated in India under the provisions of the
                  Companies Act, 2013 having its registered offices at{' '}
                  {process.env.REACT_APP_REGISTERED_OFFICE} (“Company”). The
                  words/phrases/references to ‘we’, ‘us’ and ’our’ used herein
                  this Privacy Policy shall refer to the Company.
                </li>
                <li>
                  1.2 This Privacy Policy covers the practices for handling and
                  securing your Information by the Company.
                </li>
                <li>
                  1.3 This Privacy Policy is applicable to all persons who
                  access, use, purchase any product or services from the Company
                  (collectively, the “Services”) through our websites, mobile
                  app and through other interactions and communications with the
                  Company or its agents or affiliates (collectively, the
                  “Platform”). This Privacy Policy is applicable to all users of
                  the Services viz. the Platform (“Users”). The words /phrases,
                  references to ‘you’ and ‘your’ herein this Privacy Policy
                  shall refer to the Users.
                </li>
                <li>
                  1.4 By accessing or/ using the Services offered by the Company
                  through the Platform, you expressly consent and confirm to the
                  Company collecting, maintaining, using, processing and
                  disclosing your Information in accordance with this Privacy
                  Policy. If you do not agree with this Privacy Policy or the
                  Terms of Use or any other policies of the Company published on
                  the Platform at any time, kindly refrain from accessing or/and
                  using such Services.
                </li>
              </ol>
            </li>
            <li className="mt-10">
              2. Commitment
              <ol className="my-2">
                <li>
                  2.1 The Company intends to protect the privacy of its Users
                  accessing or using its Services on the Platform as the privacy
                  of the Information provided by them to the Company from time
                  to time.
                </li>
                <li>
                  2.2 The privacy of our Users, whether they are our former or
                  existing Users or merely a visitor of our Platforms, is
                  important to us and we are strongly committed to your right to
                  privacy and to keeping your personal and other information
                  secure. We encourage the Users and visitors of our Platform to
                  read this Privacy Policy in detail to understand the types of
                  information we collect and how we use such information.
                </li>
              </ol>
            </li>
            <li className="mt-10">
              3. Collection of Information
              <ol className="my-2">
                <li>
                  3.1 For the purposes of rendering the Services, the Company
                  may collect your Information from various channels including
                  but not limited to voluntary submission of Information from
                  your end in furtherance of accessing or using the Services
                  through requests and communication initiated by you with the
                  Company and third parties on the Platform.
                </li>
                <li>
                  3.2 We are committed to protecting and respecting your
                  privacy. We understand and agree that our Services may be
                  availed by Users below the age of 18 years and we expressly
                  agree to protect the privacy of such Users as well. However,
                  all such users below the age of 18 years shall need to obtain
                  our consent to use and access the Services. The Company
                  disclaims all liabilities and responsibilities towards Users
                  below the age of 18 years who have not obtained our consent to
                  use and access the Services.
                </li>
              </ol>
            </li>
            <li className="mt-10">
              4. Types of Information collected by the Company (“Information”)
              <ol className="my-2">
                <li>
                  4.1 Personally Identifiable Information (PII)
                  <ol>
                    <li>
                      (a) PII, as used in information security and privacy laws,
                      is information that can be used on its own or with other
                      information to identify, contact, or locate a single
                      person or to identify an individual in context. PII
                      includes but is not limited to name, e-mail address,
                      gender, date of birth, postal address, phone number,
                      profile picture and other details shared via the Platform,
                      by emails or any other medium.
                    </li>
                    <li>
                      (b) Any information exchanged by you with the Company in
                      the form of written communication, responses to emails,
                      feedback provided, participation in discussions, etc. is
                      handled with confidentiality and will be available for the
                      exclusive use of the Company.
                    </li>
                    <li>
                      (c) The Company may also collect specific Information from
                      you when you access our Services through our Platform. For
                      example, you may be asked to enter information about your
                      address, delivery requirements and similar personal
                      details. We may use this Information to customize our
                      Services based on such Information as provided by you.
                    </li>
                  </ol>
                </li>
                <li>
                  4.2 Non-Personal Information (NPI)
                  <ol>
                    <li>
                      (a) NPI includes the IP address of the device used to
                      connect to the Platform along with such other information
                      such as browser details, operating system used, the name
                      of the website that redirected the visitor to the
                      Platform, etc. The Company requests you to note that when
                      you access the Platform or receive emails or any
                      communication from the Company, the Company and its
                      permitted agents, use cookies and/or pixel tags to collect
                      information and store your online preferences.
                    </li>
                  </ol>
                </li>
                <li>
                  4.3 Other Information
                  <ol>
                    <li>
                      (a) The Company may from time to time add or enhance or
                      modify the Services available on the Platform. To the
                      extent these Services are provided to and used by you, the
                      Company will use the information provided by you in this
                      regard to facilitate the access or use of the Services.
                    </li>
                    <li>
                      (b) The Company may also collect other non-specific
                      information to provide you better access to the Services
                      each time such as preferences and other non-personal
                      details names shared via the Platform by an email or any
                      other medium. For instance, if you email us with a
                      question or provide feedback, we will use your email
                      address, name, nature of the question, feedback etc. to
                      respond to your question or feedback. We may also store
                      and publish such information to assist us in making the
                      Platform better and easier to use.
                    </li>
                  </ol>
                </li>
                <li>
                  4.4 Indicative modes of collection of Information
                  <ol>
                    <li>
                      (a) When you visit our Platform: We collect industry
                      standard data from everyone who visits our Platform, even
                      if they do not have an account with the Platform. This
                      includes log data that automatically records information
                      about your visit, such as your browser type, operating
                      system, the URL of the page that referred you, the
                      different actions you performed, and the IP address you
                      used to access pages on the sites. We use this type of
                      information to provide you with an experience that is
                      relevant to your location based on the IP address, to
                      enhance the sites, to prevent site misuse, and to ensure
                      the site is working properly. We also collect data from
                      cookies.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className="mt-10">
              5. Usage and sharing of Information
              <ol className="my-2">
                <li>
                  (a) The primary goal of the Company in collecting the
                  Information is for the Company to render the Services to you
                  and to ensure quality and efficiency in the Services offered
                  and provided to you. Additionally, we will also be using the
                  Information for our internal operational purposes, such as
                  providing, maintaining, evaluating, and improving the
                  Platforms and the Services, and also for providing customer
                  support. We would also be sharing the information with others
                  in the manner provided in this Privacy Policy.
                </li>
                <li>
                  (b) Please note that the Company does not trade or sell your
                  PII in any manner, except as specified herein or unless
                  express consent is sought from you.
                </li>
                <li>
                  (c) The Company, however, may share the collected Information
                  with its subsidiaries, divisions, and affiliates, or with a
                  third party as part of any sale, merger or acquisition. The
                  Company will also share the Information with its service
                  providers as may be required to provide you with the Services.
                  In the interest of providing quality and efficiency in the
                  Services, you agree to inform the Company of any changes to
                  your PII and also undertake to protect the security of your
                  username, password and PII in relation to the Platform and the
                  Services.
                </li>
                <li>
                  (d) The Company may also share any of your Information to
                  authorized third parties/service partners to support your
                  interaction with us, to offer you Services in the best
                  possible manner and to contact you about other services that
                  we may offer. By submitting your Information to the Company,
                  you have expressly acknowledged and consented to the Company
                  using such Information in a manner deemed fit by the Company,
                  subject to this Privacy Policy. The Company shall also be
                  entitled to use the Information for conducting data analysis,
                  research based on reviews about deals, transfer to third party
                  experts for the purpose of outsourcing any Services offered to
                  you by the Company, etc.
                </li>
                <li>
                  (e) In addition to the disclosures reasonably necessary for
                  the purposes identified above, the Company may disclose your
                  PII to the extent that it is required to do so: (i) by law,
                  <p>
                    (ii) in connection with any legal proceedings or prospective
                    legal proceedings,
                  </p>
                  <p>
                    (iii) in order to establish, exercise or defend its legal
                    rights,
                  </p>
                  <p>(iv) on account of a governmental or judicial request,</p>
                  <p>(v) to enforce or apply our terms of use with you, or</p>
                  <p>
                    (vi) to protect the rights or safety of the Company or its
                    Users.
                  </p>
                </li>
                <li>
                  (f) Any NPI and data and analyses arising therefrom may be
                  shared by the Company to its existing and potential partners,
                  advertisers, investors, and others.
                </li>
                <li>
                  (g) The Company may have presence on social networking
                  websites including but not limited to LinkedIn, Facebook,
                  Twitter, YouTube and blogs which are promotional and business
                  initiatives to attract, engage and connect to a larger group
                  of people. The domain links contained therein may either
                  direct you to our Platform or request your participation by
                  way of feedback, suggestions, etc. The Company, in this
                  regard, fully disclaims any liability(ies) or claim(s), which
                  may arise by use/misuse of your feedback, suggestions, views,
                  etc. on any of the aforementioned networking websites or
                  blogs, by any third party whether or not known to the Company.
                </li>
                <li>
                  (h) If you wish to opt-out of sharing or storage of your
                  Information by the Company, please expressly intimate your
                  decision to do so in writing{' '}
                  <a href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}`}>
                    {process.env.REACT_APP_EMAIL_ADDRESS}
                  </a>
                  .
                </li>
              </ol>
            </li>
            <li className="mt-10">
              6. Storage of Information
              <ol className="my-2">
                <li>
                  (a) All Information collected in connection with the Services
                  may be stored with the Company within India. Information is
                  also stored on your device(s) and is subject to the security
                  and privacy policies of your device and storage providers. Any
                  breach of such security and privacy is beyond the control of
                  the Company and you acknowledge that the Company cannot and
                  will not be held responsible for such breaches of security
                  or/and privacy.
                </li>
                <li>
                  (b) If the Company uses a vendor for storage of Information,
                  all data storage by the vendor will be subject to the vendor’s
                  security systems and any breach of such privacy or /and
                  security policies of the vendor will be beyond the reasonable
                  control of the Company and the Company will not be held
                  responsible for such breaches.
                </li>
                <li>
                  (c) You understand and agree that the Company may continue to
                  store your Information after you cease use of the Services or
                  disable your use of, access to, or otherwise of the Services
                  or the Platform. Please note that the Company shall not use,
                  share or/ and disclose your PII with its affiliates, vendors,
                  third parties etc., after you cease use of the Services or
                  disable your use of, access to, or otherwise of the Services
                  or the Platform unless required by law to do so. The Company
                  may however continue to use, share and/ or disclose your NPI
                  in furtherance of its Policies.
                </li>
                <li>
                  (d) Cookies are small data files stored on your hard drive or
                  in device memory. The Company uses cookies to improve and
                  customize its Services and enhance your experience on the
                  Platform and in relation to the Services. Please note that you
                  can instruct your browser or device by changing its options to
                  stop accepting cookies or to prompt you before accepting a
                  cookie from the Company’s Platforms. However, the Company
                  finds it pertinent to mention that non-acceptance of cookies
                  may restrict your access to benefit from all aspects of the
                  Platform.
                </li>
              </ol>
            </li>
            <li className="mt-10">
              7. Commitment to Security
              <ol className="my-2">
                <li>
                  (a) The Company is committed in protecting your privacy and
                  has taken all necessary and reasonable measures to protect
                  your Information and handle the same in a safe and responsible
                  manner in accordance with the terms and conditions of this
                  Privacy Policy.The Company ensures to safeguard the security
                  of your PII by implementing standard electronic and managerial
                  processes to protect against unauthorised access to and
                  unlawful interception of PII.
                </li>
                <li>
                  (b) The Company will ensure its best efforts to protect your
                  Information available with the Company in line with
                  commercially reasonable efforts and general industry
                  standards; however, the Company does not represent, warrant,
                  or guarantee that your Information will be protected against
                  unauthorized access, loss, misuse, or alterations beyond the
                  reasonable control of the Company and does not accept any
                  liability for the security of the Information submitted to the
                  Company or for your or any third parties’ misuse of your
                  Information.
                </li>
                <li>
                  (c) The Company may provide links to any other websites or
                  locations for your convenience but the provision of such links
                  does not signify our endorsement of such other websites or
                  locations or content therein. The Company has no control over,
                  does not review and cannot be responsible for these outside
                  websites or the content therein. Please be aware that the
                  terms of this Privacy Policy do not apply to these outside
                  websites or locations or content therein.
                </li>
                <li>
                  (d) Except as provided elsewhere in this Privacy Policy, the
                  Company provides limited access to PII to those persons
                  (including employees and contractors) who have a business need
                  for such access.
                </li>
              </ol>
            </li>
            <li className="mt-10">
              8. Payment Related Details
              <ol className="my-2">
                <li>
                  (a) The Company uses third-party payment gateways for
                  processing online payments. We do not store your credit card
                  details or debit card details or your internet banking details
                  on our servers. The payment data is encrypted through the
                  Payment Card Industry Data Security Standard (PCI-DSS) when
                  processing your payments. Your purchase transaction data is
                  only used as long as is necessary to complete your purchase
                  transaction and is not saved thereafter.
                </li>
                <li>
                  (b) The payment gateway adopted by the Company adheres to the
                  standards set by PCI-DSS as managed by the PCI Security
                  Standards Council, which is a joint effort of brands like
                  Visa, MasterCard, American Express and Discover.
                </li>
                <li>
                  (c) PCI-DSS requirements will help ensure the secure handling
                  of payment data information by the Platform.
                </li>
                <li>
                  (d) Payment gateways and other payment transaction processors
                  will have their own privacy policies in respect to the
                  information you are required to provide to them and we are
                  required to provide to them for your payment-related
                  transactions, and as such, those details will be governed by
                  their privacy policies.
                </li>
                <li>
                  (e) You expressly agree and accept to not hold the Company
                  liable and responsible in the event of any fraud or breach of
                  the privacy policy of and by such third-party payment
                  gateways.
                </li>
              </ol>
            </li>
            <li className="mt-10">
              9. Changes to the Privacy Policy
              <p className="my-2">
                We reserve the right to modify, amend, suspend, terminate or
                replace this Privacy Policy at any time within our sole
                discretion, so please review it frequently. Changes and
                clarifications will take effect immediately upon their posting
                on the Platform. If we make material changes to this Privacy
                Policy, we will notify you that it has been updated to enable
                you to review the materially-changed Privacy Policy.
              </p>
            </li>

            <li className="mt-10">
              10. Grievances
              <ol className="my-2">
                <li>
                  (a) In accordance with the relevant provisions of the
                  Information Technology Act, 2000, and the rules made there
                  under, the name and contact details of the Grievance Officer
                  who can be contacted with respect to any complaints or
                  concerns including those pertaining to breach of the Terms of
                  Use, Privacy Policy and other polices of the Company are
                  published as under:
                  <br />
                  Grievance Officer Name:{' '}
                  <strong>
                    [●] {process.env.REACT_APP_GRIEVANCE_OFFICER_NAME}
                  </strong>
                  <br />
                  Email address:{' '}
                  <strong>[●] {process.env.REACT_APP_EMAIL_ADDRESS}</strong>
                  <br />
                  Phone number:{' '}
                  <strong>[●] {process.env.REACT_APP_PHONE_NUMBER}</strong>
                </li>
                <li>
                  (b) The Grievance Officer can be contacted between 10am to 5pm
                  from Monday to Friday except on public holidays.
                </li>
              </ol>
            </li>
            <li className="mt-10">
              11. Governing Law
              <p className="my-2">
                The Company is incorporated in, and based out of India, and is
                duty bound to abide by Indian laws. The Company may not have
                complied with some privacy laws of other countries and further
                represents to be unaware of such other legal requirements.
              </p>
            </li>
            <li className="mt-10">
              12. Questions and Contact Information
              <p className="my-2">
                If you would like to access, correct, amend, or delete any of
                your Information which is with the Company, please register a
                complaint, or if you want more Information about this Privacy
                Policy, please contact us <strong>[●]</strong>. The Company will
                respond to and address all reasonable concerns or inquiries in a
                reasonable amount of time.
              </p>
            </li>
          </ol>
          <p className="mt-16 mb-10 text-center font-bold">TERMS OF USE</p>
          <ol className="my-2">
            <li>
              1. General Overview
              <ol className="my-2">
                <li>
                  1.1 This Platform for usage on your electronic device has been
                  provided to you by Dodlapay, a private limited company,
                  registered under the provisions of the Companies Act, 2013 and
                  having its registered offices at{' '}
                  {process.env.REACT_APP_REGISTERED_OFFICE_2} (“Company”).
                  Throughout the Website, the terms/phrases/words ‘we’, ‘us’ and
                  ‘our’ refer to the Company. This Terms of Use is applicable to
                  all persons who access, use, purchase any product or services
                  from the Company (collectively, the “Services”) through the
                  mobile apps and other online channels and through other
                  interactions and communications with the Company or its agents
                  or affiliates (collectively, the “Platform”). This Terms of
                  Use is applicable to all users of the Services from the
                  Platform.
                </li>
                <li>
                  1.2 The Terms of Use, Privacy Policy and any other applicable
                  polices (the “Policies”), as updated from time to time, govern
                  your access and use of our Platform and Services.
                </li>
                <li>
                  1.3 This Terms of Use is a legally binding contract between
                  you and the Company regarding the terms of access and use of
                  our Platform and Services.
                </li>
                <li>
                  1.4 Please read the Terms of Use carefully. By downloading,
                  installing, or otherwise accessing or using our Platform or
                  Services (whether in whole or in part), you agree that you
                  have read, understood and agree to be bound by the Terms of
                  Use. By agreeing to these Terms of Use, you represent that you
                  are at least the age of majority in your state of residence,
                  or that you are the age of majority in your state of residence
                  and you have given us your consent to allow any of your minor
                  dependents to use our Services. If you do not agree with the
                  Terms of Use or any other Policies, please do not access or
                  use any of the Platforms or Services.
                </li>
                <li>
                  1.5 Upon your consenting to the Terms of Use and the Policies
                  of the Company (which are incorporated by reference into this
                  Terms of Use), the Company hereby grants you a limited,
                  personal, worldwide, revocable, non-assignable and
                  non-exclusive license to use our Services. This license is for
                  the sole purpose of enabling you to use the Services in the
                  manner expressly permitted by these Terms of Use and the
                  Policies.
                </li>
                <li>
                  1.6 The headings used in this Terms of Use are included for
                  convenience only and will not limit or otherwise affect these
                  Terms.
                </li>
              </ol>
            </li>
            <li>
              2. Access to the Services
              <ol className="my-2">
                <li>
                  2.1 As part of the registration process, you will create a
                  username and password for your account to access the Platform.
                  You must choose a reasonably descriptive username that clearly
                  identifies you or your business. In addition, your password
                  should not contain any details about you that is easily
                  available or identifiable.You are responsible for maintaining
                  the confidentiality of the password and the account. For
                  security purposes, we recommended that you memorize your
                  password and do not write it down. You agree not to disclose
                  these credentials to any third party. Any person to whom you
                  give your password will have full access to your payment
                  information, and you assume all risk of loss/or damages
                  resulting from any such access. All information and
                  instructions received from your account will be deemed to have
                  been authorized by you and the recipients of this information
                  shall rely on its authenticity based on the use of your
                  password. You will be responsible for all actions taken by
                  anyone accessing the Services using your username and
                  password.
                </li>
                <li>
                  2.2 In the event of any dispute between two or more parties as
                  to ownership of a particular account, you agree that the
                  Company will be the sole arbiter of such dispute. The
                  Company’s decision (which may include termination or
                  suspension of any account subject to dispute) will be final
                  and binding on all parties.
                </li>
                <li>
                  2.3 It is your responsibility to ensure your equipment
                  (computer, laptop, netbook, tablet or other mobile device)
                  meets all the necessary technical specifications and is
                  compatible with our Services to enable your access and use of
                  the Services.
                </li>
                <li>
                  2.4 We reserve the right to block, disable or delete any login
                  identification, at any time, if in our opinion, you have
                  failed to comply with any of the provisions of the Terms of
                  Use or any other Policies, or if any details you provide for
                  the purposes of registering as a user of the Services prove to
                  be false or a misrepresentation.
                </li>
                <li>
                  2.5 You consent to any collection, use or disclosure of
                  personal information or personally identifiable data required
                  to provide you with access to the Services or to deliver the
                  Services to you, as per the Privacy Policy of the Company.
                </li>
                <li>
                  2.6 You agree that we may collect and use technical data and
                  related information, including but not limited to technical
                  information about your device, system and application
                  software, and peripherals, that is gathered periodically, to
                  facilitate the provision of software updates, product support,
                  and other services to you (if any). We may use this
                  information as per the Privacy Policy of the Company,
                  including to improve our products or Services or technologies.
                </li>
                <li>
                  2.7 The operation of our Services includes periodic delivery
                  to you of offers, promotions, coupons or other information
                  that may be of interest to you based on your stated
                  preferences or your location. To the extent possible, you may
                  discontinue receiving such information at any time by updating
                  your preferences on the Platform.
                </li>
                <li>
                  2.8 Any new features, tools, products or services which are
                  added to the Services shall also be subject to the Terms of
                  Use. You can review the most current version of the Terms of
                  Use at any time on this page. We reserve the right to update,
                  change or replace any part of these Terms of Use by posting
                  updates and/or changes to the Services. It is your
                  responsibility to check the Services periodically for changes.
                  Your continued use of or access to the Services following the
                  posting of any changes constitutes your acceptance of those
                  changes.
                </li>
              </ol>
            </li>
            <li>
              3. Usage of Services
              <ol className="my-2">
                <li>
                  3.1 Any and all content displayed on, or transmitted via, the
                  Services provided to you is on a ‘as is’ basis and your access
                  to and use of the Services and any content therein is at your
                  own risk. You may access the content solely for your
                  information and personal use. You shall not copy, reproduce,
                  distribute, transmit, broadcast, display, sell, license or
                  otherwise exploit any content on any Platform for any other
                  purposes.
                </li>
                <li>
                  3.2 You shall not reverse-engineer, disassemble, attempt to
                  derive the source code of, modify, or create derivative works
                  of the Services, any updates, or any part thereof.
                </li>
                <li>
                  3.3 You understand that when using the Services, you will be
                  exposed to content from a variety of sources, and that the
                  Company is not responsible for the accuracy, usefulness,
                  safety or intellectual property rights of, or relating to,
                  such content on the Platform. The Company may, but is not
                  obligated to, monitor or control the content posted on the
                  Platform and/ or via the Services. Any use or reliance on any
                  content posted on the Platform and/ or via the Services or
                  obtained by you through in any other manner is at your own
                  risk. You further understand and acknowledge that you may be
                  exposed to content which you may deem inaccurate, offensive,
                  or objectionable; and you agree to waive and hereby do waive,
                  any legal or equitable rights or remedies you have or may have
                  against the Company with respect thereto. To the extent
                  permitted by applicable law, you agree to indemnify and hold
                  harmless the Company, its owners, officers, directors and
                  employees to the fullest extent regarding all matters relating
                  to your use of the Services and any content posted by you by
                  access to or use of the Platform and/ or the Services.
                </li>
              </ol>
            </li>
            <li>
              4. Your Obligations Towards Financial Institutions, Payment System
              Providers and Card Associations
              <ol className="my-2">
                <li>
                  4.1 As you will be using the services of various financial
                  institutions, payment system providers and card associations
                  to process your payment instructions, you consent and agree to
                  comply with the rules, guidelines, directions, instructions,
                  requests, etc. (“Guidelines”) made such parties from time to
                  time. You expressly acknowledge and agree that you are solely
                  assuming the risk of compliance with all applicable
                  Guidelines. You further acknowledge that such parties and your
                  issuing bank may also put limitations and restrictions on you,
                  at its sole discretion. You are responsible for keeping
                  yourself up -to- date and compliant with all such Guidelines.
                  In addition, such parties have the right to reject payments
                  made by you for any reason whatsoever. If you fail to comply
                  with your obligations towards such parties, we may suspend or
                  terminate your account with the Company.
                </li>
              </ol>
            </li>
            <li>
              5. Rejection of Authentication and Authorization
              <ol className="my-2">
                <li>
                  5.1 You understand that the Payment System Providers and/or
                  Card Association and/or your issuing bank may reject
                  authentication and/or authorization of transaction placed by
                  you for any reason including but not limited to insufficient
                  funds, incorrect authentication details provided, expired
                  card/bank account, risk management, suspicion of fraudulent,
                  illegal or doubtful transactions, selling of banned items, use
                  of compromised cards or bank account numbers, use of
                  banned/blacklisted cards or bank account numbers, use of
                  suspicious API or in accordance with the RBI, Issuing
                  Institution and/or Card Association rules, guidelines,
                  regulations, etc. and any other laws, rules, regulations,
                  guidelines in force in India.
                </li>
                <li>
                  5.2 You further acknowledge that as a security measure we
                  and/or the Payment System Providers may at our sole
                  discretion, permanently or temporarily, block any card number,
                  account numbers, group of cards or transactions from any
                  specific blocked or blacklisted cards /, accounts, specific,
                  group of IP addresses, devices, geographic locations and / or
                  any such risk mitigation measures it wishes to undertake.
                </li>
                <li>
                  5.3 As a risk management tool, we and/or the Payment System
                  Providers reserve the right to limit or restrict transaction
                  size, amount and/or monthly volume at any time. We will
                  consider a variety of factors in making a decision and such
                  determination will be at our sole discretion.
                </li>
                <li>
                  5.4 A reverse of certain transactions will necessarily have to
                  be in your wallet maintained with the Company. You agree and
                  accept to have no objections towards the same.
                </li>
              </ol>
            </li>
            <li>
              6. Transaction Confirmation and Payment History
              <ol className="my-2">
                <li>
                  6.1 When your payment instructions are successfully processed
                  with respect to a transaction, we will update you account
                  activity and provide you with a transaction confirmation. This
                  confirmation will serve as your receipt. You acknowledge that
                  we will only release the transaction confirmation upon
                  receiving confirmation with respect to the authentication,
                  authorization and processing of such transaction (your bank
                  account or debit or credit card being debited or charged) from
                  the Payment System Provider. We will not be responsible for
                  any transactions that have not been confirmed to us by the
                  Payment System Providers. Except as required by law, you are
                  solely responsible for
                  <ol type="a">
                    <li>
                      (a) compiling and retaining permanent records of all
                      transactions and other data and
                    </li>
                    <li>
                      (b) reconciling all transaction information that is
                      associated with your Account.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              7. Our Relationship with You
              <ol className="my-2">
                <li>
                  7.1 We merely provide an online platform to enable online
                  money transfers. We only act as an intermediary in the process
                  of money transfer. We do not get involved in the clearing,
                  settlement or payment of the transaction. We shall at no point
                  be held responsible for any loss and/or damages arising from,
                  related to or incidental to the transaction.
                </li>
              </ol>
            </li>
            <li>
              8. Intellectual Property
              <ol className="my-2">
                <li>
                  8.1 All rights, title and interest in and to the products,
                  Platform and Services are and will remain the exclusive
                  property of the Company. The Services are protected by
                  copyright, trademark and other Indian laws. Nothing in these
                  Terms of Use or any Policies gives you a right to use the name
                  or any of the trademarks, logos, domain names, distinctive
                  brand features or any other intellectual property rights of
                  the Company. All the data and information arising out of, and
                  in relation to the access and use of the Platform and the
                  Services shall be the property of the Company; and the Company
                  shall be vested with all rights, title and interests thereto.
                  The Company shall have the right to use such data and
                  information in the manner it deems fit, subject however, to
                  the Privacy Policy.
                </li>
                <li>
                  8.2 Any feedback, comments, or suggestions you may provide
                  regarding the orders placed viz the Platform or/and Services
                  is entirely voluntary and we will be free to use such
                  feedback, comments or suggestions as we see fit and without
                  any obligation to you.
                </li>
                <li>
                  8.3 You acknowledge and accept that your use of the device and
                  that of our Services is limited solely to your use. Any
                  transfer of the device by you shall be done without recourse
                  to the Company, and the Company shall not be obligated to
                  record the transfer of the device in any manner whatsoever.
                  The usage of our Services does not implicitly or expressly
                  transfer any ownership interest to you in the content, and
                  specifically excludes, without limitation, any commercial or
                  promotional use rights in such content. Furthermore, you are
                  prohibited from republishing, retransmitting and reproducing
                  any images accessed through our Services for any other use or
                  purpose without our prior written consent.
                </li>
              </ol>
            </li>
            <li>
              9. Prohibited Uses
              <ol className="my-2">
                <li>
                  9.1 In addition to other restrictions and prohibitions as set
                  forth in the Terms of Use and any restrictions and
                  prohibitions under applicable laws, you are prohibited from
                  using the Service for:
                  <ol type="a">
                    <li>(a) any unlawful purpose;</li>
                    <li>
                      (b) soliciting others to perform or participate in any
                      unlawful acts;
                    </li>
                    <li>
                      (c) violating any international, federal, provincial or
                      state regulations, rules, laws, or local ordinances;
                    </li>
                    <li>
                      (d) infringing upon or violate our intellectual property
                      rights or the intellectual property rights of others;
                    </li>
                    <li>
                      (e) harassing, abusing, insulting, harming, defaming,
                      slandering, disparaging, intimidating, or discriminating
                      any person (irrespective of whether or not such a person
                      is a user of the Services or otherwise) based on gender,
                      sexual orientation, religion, caste, ethnicity, race, age,
                      national origin, disability, or on any other account;
                    </li>
                    <li>(f) submitting any false or misleading information;</li>
                    <li>
                      (g) uploading or transmitting viruses or any other type of
                      malicious data or any code that will or may be used in any
                      way that will affect the functionality or operation of the
                      Service or of any Platform or any other related websites
                      or applications;
                    </li>
                    <li>
                      (h) collecting or tracking the personal information of
                      others;
                    </li>
                    <li>
                      (i) any spamming, phishing, web crawling or spidering or
                      scraping;
                    </li>
                    <li>(j) any obscene or immoral purpose; or</li>
                    <li>
                      (k) interfering with or circumventing the security
                      features of the Service or any related applications.
                    </li>
                  </ol>
                </li>
                <li>
                  9.2 We reserve the right to discontinue, suspend or terminate
                  your use of the Services for violating any of the prohibited
                  uses or restricted uses.
                </li>
              </ol>
            </li>
            <li>
              10. Disclaimer of Warranties
              <ol className="my-2">
                <li>
                  10.1 We do not guarantee, represent or warrant in any manner
                  that your use of our Services will be uninterrupted, timely,
                  secure or error-free.
                </li>
                <li>
                  10.2 You agree that, from time to time, we may suspend,
                  modify, restrict or remove our Services for uncertain or
                  indefinite periods of time or cancel the Services at any time,
                  without notice to you.
                </li>
                <li>
                  10.3 You expressly agree that your use of, or inability to
                  use, the Services is at your sole risk. The Services delivered
                  to you are (except as expressly stated by us) provided on an
                  'as is' and 'as available' for your use, without any
                  representation, warranties or conditions of any kind.
                </li>
                <li>
                  10.4 You acknowledge that third-party links including without
                  limitation the payment gateway platforms on our Platform or
                  Services may direct you to third-party sites or pages or
                  applications that are not affiliated with us. We are not
                  responsible for examining or evaluating the content or
                  accuracy of such sites or pages or applications, and we do not
                  warrant and will not have any liability or responsibility for
                  any third-party materials or websites or pages or
                  applications, or for any other data, information, products or
                  services of any third-parties.
                </li>
                <li>
                  10.5 We may provide you with access to third-party tools over
                  which we neither monitor nor have any control nor input. You
                  acknowledge and agree that we provide access to such tools “as
                  is” and “as available” without any warranties, representations
                  or conditions of any kind and without any endorsement. We
                  shall have no liability whatsoever arising from or relating to
                  your use of optional third-party tools. Any use by you of
                  optional tools offered through the Platform is entirely at
                  your own risk and discretion; and you should ensure that you
                  are familiar with and approve of the terms on which tools are
                  provided by the relevant third-party provider(s).
                </li>
              </ol>
            </li>
            <li>
              11. Indemnification
              <ol className="my-2">
                <li>
                  11.1 You agree to indemnify, defend and hold harmless the
                  Company and our subsidiaries, affiliates, partners, officers,
                  directors, agents, contractors, licensors, service providers,
                  subcontractors, suppliers, interns and employees, from any
                  losses, injuries, claims, demands, proceedings, penalties,
                  interests, costs and expenses, including attorneys’ fees, made
                  by any third-party due to or arising out of your breach of
                  these Terms of Use or the Policies of the Company or the
                  documents they incorporate by reference, or your violation of
                  any law or the rights of a third-party.
                </li>
                <li>
                  11.2 The indemnification rights of the Company shall be in
                  addition to any rights that may be available to the Company
                  under applicable law (including injunctions and specific
                  performance reliefs), none of which shall be affected in any
                  manner whatsoever.
                </li>
              </ol>
            </li>
            <li>
              12. Limitation of Liability
              <ol className="my-2">
                <li>
                  12.1 In no event shall we, our directors, employees or agents,
                  be liable to you for any direct, indirect, incidental,
                  consequential, punitive, special or exemplary damages or for
                  any damages of any kind, including but not limited to loss of
                  use, loss of profits or loss of data, whether in an action in
                  contract, tort (including but not limited to negligence) or
                  otherwise, arising out of or in any way connected with the use
                  or inability to use the Services, including without limitation
                  any damages caused by or resulting from reliance by you on any
                  information obtained from us or that result from mistakes,
                  omissions, interruptions, deletion of files, errors, defects,
                  viruses, delays in operation or transmission or any failure of
                  performance, whether or not resulting from acts of god,
                  communications failure, theft, destruction or unauthorized
                  access to our records, programs or services.
                </li>
                <li>
                  12.2 In no event shall our aggregate liability, whether in
                  contract, warranty, tort (including negligence, whether
                  active, passive or imputed), product liability, strict
                  liability or other theory, arising out of or relating to the
                  use or inability to use the Services exceed the transaction
                  amount, from which such liability is claimed to have arisen.
                </li>
                <li>
                  12.3 In addition, we shall not be liable for errors you make
                  in using the Service, including the following:
                  <ul>
                    <li>
                      - You erroneously direct us to submit a payment
                      instructions to a merchant/biller multiple times;
                    </li>
                    <li>
                      - You direct us to submit the wrong amount to a
                      merchant/biller;
                    </li>
                    <li>
                      - You direct us to submit a payment instruction for a
                      wrong merchant/biller; or
                    </li>
                    <li>
                      - You provide us with incorrect or incomplete information.
                    </li>
                  </ul>
                  Any of the above issue(s) needs to be taken up directly with
                  the merchant/biller.
                </li>
                <li>
                  12.4 You understand and acknowledge that we do not have
                  control of, or liability for, the goods or services that are
                  paid for via the Service.
                </li>
                <li>
                  12.5 Your liability: Except as otherwise provided by law, you
                  will be liable for any loss or damage resulting from your
                  breach of this Agreement or your negligence, or which resulted
                  from unauthorized, fraudulent, or dishonest acts by others
                  (other than us). You are liable for all payments that you make
                  or which are made or requested under your account, even if
                  that payment is unauthorized.
                </li>
                <li>
                  12.6 Some jurisdictions do not allow limitations on implied
                  warranties or the exclusion or limitation of certain damages.
                  If these laws apply, some or all of the above disclaimers,
                  exclusions or limitations may not apply. You agree that, if
                  you are dissatisfied with the Services or any portion thereof,
                  your exclusive remedy shall be to stop using the Services.
                </li>
              </ol>
            </li>
            <li>
              13. Refund Policy
              <ol className="my-2">
                <li>
                  13.1 Once a value is debited from your payment instrument/bank
                  account and you have received the same value in your{' '}
                  {process.env.REACT_APP_COMPANY_NAME} Id, there is no
                  cancellation or refund permitted for such transaction.
                  However, if in a transaction performed by You on the{' '}
                  {process.env.REACT_APP_COMPANY_NAME} Platform, an amount has
                  been charged to Your card or bank account and a value is not
                  delivered within 48 hours of the completion of the
                  transaction, then You shall inform us by sending an e mail to
                  our customer services e mail address mentioned on the ‘Contact
                  Us’ page on the dodlapay.in Platform. Please include in the
                  e-mail the following details – value, transaction date and
                  Last 4 digit card number. {process.env.REACT_APP_COMPANY_NAME}{' '}
                  will investigate the incident and, if it is found that money
                  was indeed charged to Your card or bank account without
                  delivery of the value, then You will be refunded the amount
                  within 21 working days from the date of receipt of Your e
                  mail. All refunds will be credited to the instrument that was
                  charged.
                </li>
              </ol>
            </li>
            <li>
              14. Severability
              <ol className="my-2">
                <li>
                  14.1 In the event that any provision of these Terms of Use is
                  determined to be unlawful, void or unenforceable, such
                  provision shall nonetheless be enforceable to the fullest
                  extent permitted by applicable law, and the unenforceable
                  portion shall be deemed to be severed from these Terms of Use.
                  However, such determination shall not affect the validity and
                  enforceability of any other remaining provisions of these
                  Terms of Use.
                </li>
              </ol>
            </li>
            <li>
              15. Termination
              <ol className="my-2">
                <li>
                  15.1 Each party’s obligations and liabilities incurred prior
                  to the termination date shall survive the termination of this
                  Terms of Use for all purposes.
                </li>
                <li>
                  15.2 These Terms of Use are effective unless and until
                  terminated by either you or us. You may terminate these Terms
                  of Use at any time by notifying us that you no longer wish to
                  use our Services, or when you cease using our Services.
                </li>
                <li>
                  15.3 If, in our sole judgement, you fail, or we suspect that
                  you have failed, to comply with any term or provision of these
                  Terms of Use or/and the Policies, we may terminate this Terms
                  of Use at any time without notice and you will remain liable
                  for all amounts due up to and including the date of
                  termination; and/or we, accordingly, may deny you access to
                  our Services (or any part thereof).
                </li>
              </ol>
            </li>
            <li>
              16. Entire agreement
              <ol className="my-2">
                <li>
                  16.1 The failure of us to exercise or enforce any right or
                  provision of these Terms of Use shall not constitute a waiver
                  of such right or provision.
                </li>
                <li>
                  16.2 These Terms of Use and any Policies and any other
                  policies or operating rules posted by us on our Platform or in
                  respect of the use of the Services constitutes the entire
                  agreement and understanding between you and us and governs
                  your use of our Services. This will supersede any prior or
                  contemporaneous agreements, communications and proposals,
                  whether oral or written, between you and us (including, but
                  not limited to, any prior versions of the Terms of Use).
                </li>
                <li>
                  16.3 Any ambiguities in the interpretation of these Terms of
                  Use and the Policies shall not be construed against the
                  drafting party.
                </li>
              </ol>
            </li>
            <li>
              17. Governing law
              <ol className="my-2">
                <li>
                  17.1 These Terms of Use and the Policies which govern the use
                  of the Platform and the Services shall be governed by and
                  construed in accordance with the laws of India. Any disputes
                  or claims arising in relation to the Terms of Use and the
                  Policies and any of the Services shall be subject to the
                  exclusive jurisdiction of the courts in{' '}
                  {process.env.REACT_APP_COURT_JURISDICTION}.
                </li>
              </ol>
            </li>
          </ol>
          <p id="terms-copyright" className="mt-10 text-center">
            {process.env.REACT_APP_COPYRIGHT_LINE_1}
          </p>
          <KeyboardDoubleArrowDownRoundedIcon
            onClick={scrollToBottom}
            className="sticky bg-[#112467] text-white p-1 rounded-full bottom-0 left-full translate-x-4 translate-y-4 hover:cursor-pointer hover:scale-110"
          />
        </div>
        {!isLogin && (
          <div className="transition-all h-[137px]">
            <div>
              <input
                className="text-[#112467] mr-5 my-5"
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                onChange={handleCheckboxChange}
              />
              <span>I accept the Terms & Conditions</span>
            </div>
            <div>
              <CustomButton
                variant={pending}
                handleClick={handleButtonSubmit}
                disable={!buttonDisable}
                style={buttonStyle}
                label="Continue"
              />
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default TermsModal;
