import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Box} from '@mui/material';
import Modal from '@mui/material/Modal';
import {useContext, useEffect, useState} from 'react';
import UseForm from '../../../hooks/useForm';
import {Constants} from '../../../stateManagement/constants';
import {Context} from '../../../stateManagement/context';
import {OTPModalValidation} from '../../../validation/validation';
import CustomButton from '../customButton/customButton';
import CustomInput from '../customInput/customInput';

const buttonStyle = {
  width: '200px',
};

const initialState = {
  input_1: {value: '', required: true},
  input_2: {value: '', required: true},
  input_3: {value: '', required: true},
  input_4: {value: '', required: true},
  input_5: {value: '', required: true},
  input_6: {value: '', required: true},
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  // height:350,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '12px',
  boxShadow: 24,
  outline: 'none',
};

function CustomOtpModal() {
  const [state, contextDispatch] = useContext(Context);
  const [loading, setLoading] = useState('');
  const {handleChange, values, handleSubmit, errors, clearState} = UseForm(
    submitForm,
    OTPModalValidation,
    initialState,
  );
  const [counter, setCounter] = useState(60 * 3);
  function submitForm() {
    const appendedOtp =
      values?.input_1?.value +
      values?.input_2?.value +
      values?.input_3?.value +
      values?.input_4?.value +
      values?.input_5?.value +
      values?.input_6?.value;
    state[0]?.OTPModal?.triggerFunction(appendedOtp, setLoading);
    // setLoading("loading");
    clearState(initialState);
  }

  function handleModalClose() {
    contextDispatch({
      type: Constants.SHOW_OTP_MODAL,
      payload: {isOpen: false, modalProps: {}},
    });
    clearState(initialState);
  }

  const handleClick = click => {
    setCounter(60 * 3);
    state[0]?.OTPModal?.otpresendfuntion();
  };

  useEffect(() => {
    if (state[0]?.OTPModal?.isOpen) {
      if (counter > 0) {
        const timer = setInterval(
          () => setCounter(counter => counter - 1),
          1000,
        );
        return () => clearInterval(timer);
      }
    } else {
      setCounter(60 * 3);
    }
  }, [counter, state[0]?.OTPModal?.isOpen]);

  const seconds = String(counter % 60).padStart(2, 0);
  const minutes = String(Math.floor(counter / 60)).padStart(2, 0);

  return (
    <Modal
      sx={{outline: 'none'}}
      open={state[0]?.OTPModal?.isOpen ?? false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <form onSubmit={handleSubmit}>
          <div className="relative m-5 flex flex-col h-40 items-center">
            <CloseRoundedIcon
              onClick={handleModalClose}
              className="absolute top-0 right-0 cursor-pointer hover:scale-105 hover:bg-slate-300 hover:rounded-full"
            />
            <p className="text-center text-[#67748E] text-xl pt-5 w-3/4">
              {state[0]?.OTPModal?.modalContent}
            </p>
            <div className="flex gap-5 my-10">
              <CustomInput
                autoFocus={true}
                error={errors?.input_1}
                name="input_1"
                handleChange={handleChange}
                variant="otp"
                id="input_1"
                nextId="input_2"
                prevId={null}
              />
              <CustomInput
                error={errors?.input_2}
                name="input_2"
                handleChange={handleChange}
                variant="otp"
                id="input_2"
                nextId="input_3"
                prevId="input_1"
              />
              <CustomInput
                error={errors?.input_3}
                name="input_3"
                handleChange={handleChange}
                variant="otp"
                id="input_3"
                nextId="input_4"
                prevId="input_2"
              />
              <CustomInput
                error={errors?.input_4}
                name="input_4"
                handleChange={handleChange}
                variant="otp"
                id="input_4"
                nextId="input_5"
                prevId="input_3"
              />
              <CustomInput
                error={errors?.input_5}
                name="input_5"
                handleChange={handleChange}
                variant="otp"
                id="input_5"
                nextId="input_6"
                prevId="input_4"
              />
              <CustomInput
                error={errors?.input_6}
                name="input_6"
                handleChange={handleChange}
                variant="otp"
                id="input_6"
                nextId={null}
                prevId="input_5"
              />
            </div>
          </div>
          {counter > 0 ? (
            <p className="otp-min my-10">
              {`Request OTP in ${minutes}:${seconds} min`}
            </p>
          ) : (
            <p className="otp-min my-10" onClick={handleClick}>
              <span className="hover:underline cursor-pointer">Resend OTP</span>
            </p>
          )}
          <div className="absolute bottom-[-24px] left-0 flex justify-center w-full">
            <CustomButton
              variant={loading}
              style={buttonStyle}
              label="Submit"
            />
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default CustomOtpModal;
