import PaymentModal from "./paymentModal/paymentModal";

function Payments() {
    return(
        <div className="m-5">
            <div className="relative bottom-20">
            <PaymentModal />
            </div>
        </div>
    )
}

export default Payments;