import TransferKitsModal from "./kitManagementModal/TransferKitsModal";

const KitManagement = () => {
  return (
    <div className="m-5">
      <div className="relative bottom-20">
        <TransferKitsModal />
      </div>
    </div>
  );
};

export default KitManagement;
