import "./customselect.css";
import CreatableSelect from 'react-select/creatable';
import { components } from "react-select";
import { ReactComponent as DownArrow } from "../../../assets/svg/down-arrow.svg";

const FundLoadSelect = ({ data, label, handleChange, value, name, defaultValue }) => {
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <DownArrow className=" cursor-pointer" />
            </components.DropdownIndicator>
        );
    };

    const ValueContainer = ({ children, ...props }) => {
        const { icon } = props.selectProps;
        return (
            components.ValueContainer && (
                <components.ValueContainer className="iconwith" {...props}>
                    {
                        <span className="w-12 flex justify-center items-center absolute">
                            <img src={icon}></img>
                        </span>
                    }
                    {children}
                </components.ValueContainer>
            )
        );
    };

    const handleOnChange = (value) => {
        handleChange({ name: name, value: value })
    }

    return (<CreatableSelect
        maxMenuHeight={"200px"}
        className="focus:outline-none"
        menuPortalTarget={document.body}
        styles={{
            container: (base) => ({
                ...base,
                width: "100%",
            }),
            control: (base) => ({
                ...base,
                cursor: "pointer",
                height: "40px",
                border: "none",
                borderRadius: 12,
                // zIndex: 9999,
                paddingLeft: 12,
                background: "linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%);",
                boxShadow: "0px 1px 2px rgb(0 0 0 / 25%);",
            }),
            menu: (provided) => ({
                ...provided,
                width: "100%",
                borderRadius: "12px",
                borderTop: 0,
                overflow: "hidden",
            }),
            singleValue: provided => ({
                ...provided,
                color: '#112467',
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: "1.4375em",
                letterSpacing: "0.00938em",
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    fontSize: "16px",
                    padding: "0 5px",
                    fontFamily: "Libre Franklin",
                    cursor: isDisabled ? "not-allowed" : "pointer",
                };
            },
        }}
        onChange={(value) => handleChange({ name: name, value: value })}
        placeholder={<div className='whitespace-nowrap text-[#8392AB]'>{label}</div>}
        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
        options={data}
        value={value}
    />)
};

export default FundLoadSelect;
