import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useCallback, useContext, useState } from "react";
import distributorService from "../../../services/distributor_service";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { CustomModalvalidation } from "../../../validation/validation";
import CustomButton from "../customButton/customButton";
import "./custommodal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 300,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const buttonStyle = {
  width: "calc(100% - 60px)",
  marginLeft: "30px",
  marginRight: "30px",
  marginTop: "12px",
  marginBottom: "8px",
  lineHeight: "20px",
  letterSpacing: "4px",
  whiteSpace: "nowrap",
  padding: "0 20px",
};
const CustomModal = ({
  name,
  header,
  description,
  buttonLabel,
  data,
  modalType,
}) => {
  const [errors, setErrors] = useState({});
  const [state, contextDispatch] = useContext(Context);
  const [type, setType] = useState("password");

  const initialState = {
    [name]: {
      value: "",
      required: true,
      regexp: /^[0-9]*$/,
      minLength: 4,
      minLengthMessage: 'At least 4 characters long!',
      maxLength: 6,
      maxLengthMessage: 'Too many characters!'
    },
  };

  const handleCancel = () => {
    setValue(initialState);
    setErrors({});
    contextDispatch({
      type: Constants.CUSTOM_MODAL,
      payload: { isOpen: false, modalProps: {} },
    });
  };

  const [value, setValue] = useState(initialState);

  const setDataAndErrors = useCallback((data) => {
    setValue(data);

    const errors = CustomModalvalidation(data);

    setErrors(errors);
  }, []);

  const handleChange = useCallback(
    (e) => {
      let updatedData;
      if (value[e.target.name]?.regexp) {
        updatedData = {
          [e.target.name]: {
            ...value[e.target.name],
            value: value[e.target.name]?.regexp.test(e.target.value) ? e.target.value : value[e.target.name].value,
            touched: true,
          },
        };
      }
      else {
        updatedData = {
          [e.target.name]: {
            ...value[e.target.name],
            value: e.target.value,
            touched: true,
          },
        };
      }

      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, value]
  );

  const handleModalSubmit = (e) => {
    e.preventDefault();
    const errors = CustomModalvalidation(value);
    setErrors(errors);

    if (Object.keys(errors).length === 0 || description) {
      switch (modalType) {
        case "buykits":
          distributorService
            .Buy_Kits(data)
            .then((res) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: "success",
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "success",
                  message: res?.data?.message,
                },
              });
              contextDispatch({
                type: Constants.CALL_API,
                payload: !state[0]?.callApi,
              });
            })
            .catch((err) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: 'fail',
                  message: err?.response?.data?.message
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "error",
                  message: err?.response?.data?.message,
                },
              });
            });
          setValue(initialState);
          break;
        case "transferByNumber":
          distributorService
            .Transfer_Value_Forward({
              ...data,
              tPin: value?.transferByNumber?.value,
            })
            .then((res) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: "success",
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "success",
                  message: res?.data?.message,
                },
              });
              contextDispatch({
                type: Constants.CALL_API,
                payload: !state[0]?.callApi,
              });
            })
            .catch((err) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: 'fail',
                  message: err?.response?.data?.message
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "error",
                  message: err?.response?.data?.message,
                },
              });
            });
          setValue(initialState);
          break;
        case "transferValue":
          if (data?.type?.value) {
            distributorService
              .Transfer_Value_Forward({
                toUserId: data?.toUserId,
                value: data?.value,
                tPin: value?.transferValue?.value,
              })
              .then((res) => {
                contextDispatch({
                  type: Constants.CUSTOM_MODAL,
                  payload: { isOpen: false, modalProps: {} },
                });
                contextDispatch({
                  type: Constants.SHOW_POPUP,
                  payload: {
                    showLotti: true,
                    lottiSeverity: "success",
                  },
                });
                contextDispatch({
                  type: Constants.SHOW_SNACKBAR,
                  payload: {
                    isOpen: true,
                    severity: "success",
                    message: res?.data?.message,
                  },
                });
                contextDispatch({
                  type: Constants.CALL_API,
                  payload: !state[0]?.callApi,
                });
              })
              .catch((err) => {
                contextDispatch({
                  type: Constants.CUSTOM_MODAL,
                  payload: { isOpen: false, modalProps: {} },
                });
                contextDispatch({
                  type: Constants.SHOW_POPUP,
                  payload: {
                    showLotti: true,
                    lottiSeverity: 'fail',
                    message: err?.response?.data?.message
                  },
                });
                contextDispatch({
                  type: Constants.SHOW_SNACKBAR,
                  payload: {
                    isOpen: true,
                    severity: "error",
                    message: err?.response?.data?.message,
                  },
                });
              });
          } else {
            distributorService
              .Transfer_Value_Backward({
                userId: data?.toUserId,
                amount: data?.value,
              })
              .then((res) => {
                contextDispatch({
                  type: Constants.CUSTOM_MODAL,
                  payload: { isOpen: false, modalProps: {} },
                });
                contextDispatch({
                  type: Constants.SHOW_POPUP,
                  payload: {
                    showLotti: true,
                    lottiSeverity: "success",
                  },
                });
                contextDispatch({
                  type: Constants.SHOW_SNACKBAR,
                  payload: {
                    isOpen: true,
                    severity: "success",
                    message: res?.data?.message,
                  },
                });
                contextDispatch({
                  type: Constants.CALL_API,
                  payload: !state[0]?.callApi,
                });
              })
              .catch((err) => {
                contextDispatch({
                  type: Constants.CUSTOM_MODAL,
                  payload: { isOpen: false, modalProps: {} },
                });
                contextDispatch({
                  type: Constants.SHOW_POPUP,
                  payload: {
                    showLotti: true,
                    lottiSeverity: 'fail',
                    message: err?.response?.data?.message
                  },
                });
                contextDispatch({
                  type: Constants.SHOW_SNACKBAR,
                  payload: {
                    isOpen: true,
                    severity: "error",
                    message: err?.response?.data?.message,
                  },
                });
              });
          }

          setValue(initialState);
          break;
        // case "requestValue":
        //   distributorService
        //     .Transfer_Value({ ...data, tPin: value?.requestValue?.value })
        //     .then((res) => {
        //       contextDispatch({
        //         type: Constants.CUSTOM_MODAL,
        //         payload: { isOpen: false, modalProps: {} },
        //       });
        //       contextDispatch({
        //         type: Constants.SHOW_POPUP,
        //         payload: {
        //           showLotti: true,
        //           lottiSeverity: "success",
        //         },
        //       });
        //       contextDispatch({
        //         type: Constants.SHOW_SNACKBAR,
        //         payload: {
        //           isOpen: true,
        //           severity: "success",
        //           message: res?.data?.message,
        //         },
        //       });
        //       contextDispatch({
        //         type: Constants.CALL_API,
        //         payload: !state[0]?.callApi,
        //       });
        //     })
        //     .catch((err) => {
        //       contextDispatch({
        //         type: Constants.CUSTOM_MODAL,
        //         payload: { isOpen: false, modalProps: {} },
        //       });
        //       contextDispatch({
        //         type: Constants.SHOW_POPUP,
        //         payload: {
        //           showLotti: true,
        //           lottiSeverity: "fail",
        //         },
        //       });
        //       contextDispatch({
        //         type: Constants.SHOW_SNACKBAR,
        //         payload: {
        //           isOpen: true,
        //           severity: "error",
        //           message: err?.response?.data?.message,
        //         },
        //       });
        //     });
        //   setValue(initialState);
        //   break;
        case "fundrequesttransfer":
          distributorService
            .Value_Transfer_Accept({ fundTransactionId: data?.requestid, remarks: data?.remarks })
            .then((res) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: "success",
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "success",
                  message: res?.data?.message,
                },
              });
              contextDispatch({
                type: Constants.CALL_API,
                payload: !state[0]?.callApi,
              });
            })
            .catch((err) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: 'fail',
                  message: err?.response?.data?.message
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "error",
                  message: err?.response?.data?.message,
                },
              });
            });
          setValue(initialState);
          break;
        case "fundrequestreject":
          distributorService
            .Value_Transfer_Reject({ fundTransactionId: data?.requestid, remarks: data?.remarks })
            .then((res) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: "success",
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "success",
                  message: res?.data?.message,
                },
              });
              contextDispatch({
                type: Constants.CALL_API,
                payload: !state[0]?.callApi,
              });
            })
            .catch((err) => {
              contextDispatch({
                type: Constants.CUSTOM_MODAL,
                payload: { isOpen: false, modalProps: {} },
              });
              contextDispatch({
                type: Constants.SHOW_POPUP,
                payload: {
                  showLotti: true,
                  lottiSeverity: 'fail',
                  message: err?.response?.data?.message
                },
              });
              contextDispatch({
                type: Constants.SHOW_SNACKBAR,
                payload: {
                  isOpen: true,
                  severity: "error",
                  message: err?.response?.data?.message,
                },
              });
            });
          setValue(initialState);
          break;
        default:
          break;
      }
    }
  };

  const togglePassword = () => {
    if (type === "password") setType("text");
    else setType("password");
  };

  return (
    <Modal
      open={state[0]?.cutomModal?.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleModalSubmit}>
        <Box sx={style}>
          <div className="text-center flex justify-center flex-col">
            <h1 className="modal-title">{header}</h1>
            <div className="modal-line"></div>
          </div>
          <div className="text-center mt-24 modal-description">
            {description}
          </div>
          {!description ? (
            <div className="text-center mt-24">
              <input
                className="modal-input"
                maxLength={6}
                type={type}
                name={name}
                value={value[name]?.value}
                onChange={handleChange}
              />
              {errors[name] && (
                <p className="errorText absolute flex justify-center w-full left-0">
                  {errors?.[name]}
                </p>
              )}
              <span
                className="modal-eye-password  text-gray-600"
                onClick={togglePassword}
              >
                {type === "password" ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </span>
            </div>
          ) : (
            ""
          )}
          <div className="modal-buttons">
            <CustomButton label={buttonLabel} style={buttonStyle} />
            <CustomButton
              label={"Cancel"}
              handleClick={() => handleCancel()}
              style={buttonStyle}
            />
          </div>
        </Box>
      </form>
    </Modal>
  );
};

export default CustomModal;
