
import TransferModal from "./transferModal";

const ValueTransfer = () => {

  return (
    <div className="m-5">
      <div className="relative bottom-20">
        <TransferModal />
      </div>
    </div>
  );
};

export default ValueTransfer;
