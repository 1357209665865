import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import distributorService from '../../../services/distributor_service';
import TokenService from '../../../services/token_service';
import './dashboardcard.css';

function DashboardCard() {
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();
    const fetchDashboardData = async () => {
      try {
        const {
          data: { data },
        } = await distributorService.Dashboad_Chainbalance();
        setDashboardData({
          chainBalance: data.totalAmount,
          noRetailers: data.totalRetailers,
          totalDistributors: data.totalDistributors,
        });
      } catch (error) { }
    };

    fetchDashboardData();

    return () => {
      mounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Grid container gap={4} className="flex px-5 mb-20">
      <Grid item sm={12} md={5}>
        <div id="item" className="bg-white mt-5">
          {TokenService.getUser()?.userType !== 'super_distributor' && (
            <div className="flex flex-col ">
              <span className=" card-title1">Number Of Retailers</span>
              <span className="text-font-violet card-title2">
                {/* <span>
                No. Of Retailers
              </span> */}
                <span className="card-sub-title2">
                  {dashboardData?.noRetailers ?? 0}
                </span>
              </span>
            </div>
          )}
          {TokenService.getUser()?.userType == 'super_distributor' && (
            <div className="flex flex-col ">
              <span className=" card-title1">Number Of Distributors</span>
              <span className="text-font-violet card-title2">
                {/* <span>
                No. Of Distributors
              </span> */}
                <span className="card-sub-title2">
                  {dashboardData?.totalDistributors ?? 0}
                </span>
              </span>
            </div>
          )}
          <div id="card-left-image" className="overflow-hidden"></div>
        </div>
      </Grid>
      <Grid item sm={12} md={5}>
        <div id="item" className="bg-white mt-5">
          <div className="flex flex-col ">
            <span className=" card-title1">Chain Balance</span>
            <span className="text-font-violet card-title2">
              <span className="card-sub-title1">₹</span>
              <span className="card-sub-title2">
                {dashboardData?.chainBalance ?? 0}
              </span>
            </span>
          </div>
          <div id="card-left-image" className="overflow-hidden"></div>
        </div>
      </Grid>
      {/* <Grid item sm={12} md={5}>
        <div id="item" className="bg-white mt-5">
          <div className="flex flex-col ">
            <span className="text-font-light-grey2 card-title1">
              Retailer chain
            </span>
            <span className="text-font-violet card-title2">
              <span className="card-sub-title1">
              ₹
              </span>
              <span className="card-sub-title2">
                272.25
              </span>
            </span>
          </div>
          <div id="card-left-image" className="overflow-hidden">
          
          </div>
        </div>
        </Grid>
        <Grid item sm={12} md={5}>
        <div id="item" className="bg-white mt-5">
          <div className="flex flex-col ">
            <span className="text-font-light-grey2 card-title1">
              Retailer chain
            </span>
            <span className="text-font-violet card-title2">
              <span className="card-sub-title1">
              ₹
              </span>
              <span className="card-sub-title2">
                272.25
              </span>
            </span>
          </div>
          <div id="card-left-image" className="overflow-hidden">
          </div>
        </div>
        </Grid> */}
    </Grid>
  );
}

export default DashboardCard;
