import "./custominput.css";

const CustomInput = ({ nextId, onInput, autoFocus, error, prevId, id, placeholder, style, name, value, handleChange, variant, max, disabled, min, type }) => {

    function handleKeyUp(e) {
        //check if key is backspace or arrowleft
        if (e.keyCode === 8 || e.keyCode === 37) {
            const prev = document.getElementById(prevId);
            if (prev) {
                //select the previous element
                prev.select();
            }
        }
        else if (
            (e.keyCode >= 48 && e.keyCode <= 57) || //check if key is numeric keys 0 to 9
            //(e.keyCode >= 65 && e.keyCode <= 90) || //check if key is alphabetical keys A to Z
            (e.keyCode >= 96 && e.keyCode <= 105) || //check if key is numeric keypad keys 0 to 9
            e.keyCode === 39 //check if key is right arrow key
        ) {
            //find the next element
            const next = document.getElementById(nextId);
            if (next) {
                //select the next element
                next.select();
            }
            // else {
            //     //check if inputGroup has autoSubmit enabled
            //     const inputGroup = document.getElementById('OTPInputGroup');
            //     if (inputGroup && inputGroup.dataset['autosubmit']) {
            //         //submit the form
            //         handleSubmit();
            //     }
            // }
        }
    }

    const onlyNumbers = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, "");
    };

    switch (variant) {
        case "mobile":
            return (<div className="flex items-center">
                <span id="validation-span-mobile">+91</span>
                <input disabled={disabled} minLength={min} maxLength={max} className="input-background" type={"text"} name={name} style={style} value={value} onChange={handleChange} placeholder={placeholder} id="validation-input-mobile" />
            </div>);
        case "symbol":
            return (<div className="validation-container-symbol pl-5">
                <span id="validation-span-symbol">₹</span>
                <input disabled={disabled} minLength={min} maxLength={max} className="input-background" type={"text"} name={name} style={style} value={value} onChange={handleChange} placeholder={placeholder} id="validation-input-symbol" />
            </div>);
        case "otp":
            return (
                <input autoFocus={autoFocus} onInput={onlyNumbers} disabled={disabled} minLength={1} maxLength={1} type={"text"} name={name} style={style} value={value} onChange={handleChange} onKeyUp={handleKeyUp} placeholder="*" id={id} className={`otp-input ${error ? "border-red-500" : "border-[#112467]"}`} />
            );
        default:
            return (
                <input disabled={disabled} onInput={onInput} minLength={min} maxLength={max} className="py-2 my-2 px-6 w-full input-background validation-input" type={type} name={name} style={style} value={value} onChange={handleChange} placeholder={placeholder} />
            );

    }
};

export default CustomInput;