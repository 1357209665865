import PersonIcon from '@mui/icons-material/Person';
import {Button, Grid} from '@mui/material';
import Menu from '@mui/material/Menu';
import {alpha, styled} from '@mui/material/styles';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as RightArrow} from '../../../../src/assets/svg/down-arrow.svg';
import HeaderImage from '../../../assets/images/banner.jpg';
import Distributor_Service from '../../../services/distributor_service';
import {Constants} from '../../../stateManagement/constants';
import {Context} from '../../../stateManagement/context';
import TermsModal from '../../TermsModal';
import './header.css';
import ResetPassword from './resetPassword';
import UserCard from './userCard/userCard';

function Header() {
  const [data, setData] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, contextDispatch] = useContext(Context);
  const [currentTitle, setCurrentTitle] = useState('');
  const [termsModal, showTermsModal] = useState(false);
  const location = useLocation();
  const [resetPasswordModal, setResetPasswordModal] = useState({
    isOpen: false,
    flag: false,
  });
  const open = Boolean(anchorEl);

  const StyledMenu = styled(props => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: 345,
      }}
      {...props}
    />
  ))(({theme}) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const titles = [
    {path: '/dashboard', title: 'Dashboard'},
    {path: '/payments', title: 'Payments'},
    {
      path: '/get-funds-by-virtual-account',
      title: 'Get Funds By Virtual Account',
    },
    {title: 'Transfer Value', path: '/transfer-value'},
    {title: 'Request Value', path: '/request-value'},
    {path: '/value-transfer-report', title: 'Value Transfer Report'},
    {path: '/fund-load', title: 'Fund Load'},
    {path: '/retailer-list', title: 'Retailer List'},
    {path: '/create-retailer', title: 'Create Retailer'},
    {path: '/distributor-list', title: 'Distributor List'},
    {path: '/create-distributor', title: 'Create Distributor'},
    {path: '/transactions', title: 'Transactions'},
    {path: '/t-pin', title: 'T-PIN'},
    {path: '/my-ledger', title: 'My Ledger'},
    {path: '/kit-management', title: 'Kit Management'},
    {path: '/buy-kits', title: 'Buy Kits'},
    {path: '/my-earnings', title: 'My Earnings'},
    {path: '/transfer-kits', title: 'Transfer Kits'},
    {path: '/wallet-report-dt', title: 'Trade Wallet Report'},
    {path: '/wallet-report-sd', title: 'Trade Wallet Report'},
    {path: '/dmt-report-dt', title: 'DMT Report'},
    {path: '/dmt-report-sd', title: 'DMT Report'},
    {path: '/recharge-report-dt', title: 'Recharge Report'},
    {path: '/recharge-report-sd', title: 'Recharge Report'},
    {path: '/fund-transfer-report-dt', title: 'Fund Transfer Report'},
    {path: '/fund-transfer-report-sd', title: 'Fund Transfer Report'},
    {path: '/credit-card-report-dt', title: 'Credit Card Report'},
    {path: '/credit-card-report-sd', title: 'Credit Card Report'},
    {path: '/aeps-wallet-report-dt', title: 'AEPS Report'},
    {path: '/aeps-wallet-report-sd', title: 'AEPS Report'},
    {path: '/bbps-report-dt', title: 'BBPS Report'},
    {path: '/bbps-report-sd', title: 'BBPS Report'},
    {path: '/ledger-report-dt', title: 'Ledger Report'},
    {path: '/ledger-report-sd', title: 'Ledger Report'},
    {path: '/matm-report-dt', title: 'MATM Report'},
    {path: '/matm-report-sd', title: 'MATM Report'},
    {path: '/kycdmt-report-dt', title: 'KYC DMT Report'},
    {path: '/kycdmt-report-sd', title: 'KYC DMT Report'},
  ];

  const navigate = useNavigate();

  const handleLogOut = () => {
    setAnchorEl(null);
    contextDispatch({
      type: Constants.HANDLE_LOGIN,
      payload: {
        showForgotPasswordForm: false,
        showOTPForm: false,
        showLoginForm: true,
        forgotOtpVerifyForm: false,
        setPasswordForm: false,
      },
    });
    sessionStorage.setItem('user', null);
    navigate('/', {replace: true});
    window.onpopstate = function () {
      window.history.go(-1);
    };
  };

  useEffect(() => {
    let mounted = true;
    Distributor_Service.GetProfile('')
      .then(({data}) => {
        if (mounted) {
          setData(data?.data);
          if (!data?.data?.tnc_checked) {
            showTermsModal(true);
          }
          if (!data?.data?.pass_reset) {
            setResetPasswordModal({isOpen: true, flag: true});
          }
          contextDispatch({
            type: Constants.USER_ID,
            payload: data?.data?.edgeId?.slice(0, 2),
          });
        }
      })
      .catch(err => {});

    const path = location.pathname.substring();
    const pathTitle = titles.find(item => item.path === path);

    document.title = pathTitle.title; // document title

    setCurrentTitle(pathTitle.title);

    return () => (mounted = false);
  }, [state[0]?.callApi, location.pathname]);

  return (
    <>
      <Grid container spacing={0} padding="20px">
        <Grid item xs={12} md={12}>
          <div
            className="header flex w-full"
            style={{
              backgroundImage: `url(${HeaderImage}), linear-gradient(135deg, #FF0080 0%, #7928CA 100%)`,
            }}>
            <div className="flex justify-start items-center  h-12 w-2/5">
              <div className="text-white w-full">
                <span className="select-none head-titles">{currentTitle}</span>
              </div>
            </div>
            <div className="flex h-12  w-full justify-end">
              <div className="flex header-header items-center mr-[25px]">
                <span className="flex ">Balance</span>
                <span className="h-12 flex items-center  px-4">₹</span>
                <span className="flex ">{data.tradeBalance ?? 0}</span>
              </div>
              <Button
                id="customized-button"
                aria-controls={open ? 'customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                sx={{
                  backgroundColor: '#fff',
                  color: '#80808F',
                  borderRadius: '8px',
                  padding: '0.5rem 1rem',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                }}
                endIcon={<RightArrow />}
                startIcon={<PersonIcon />}>
                {data.firstName
                  ? data.firstName.charAt(0).toUpperCase() +
                    data.firstName.slice(1) +
                    ' ' +
                    data.lastName.charAt(0).toUpperCase() +
                    data.lastName.slice(1)
                  : 'loading...'}
              </Button>
              <StyledMenu
                id="customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <UserCard
                  setResetPasswordModal={setResetPasswordModal}
                  setAnchorEl={setAnchorEl}
                  handleLogOut={handleLogOut}
                  data={data}
                />
              </StyledMenu>
              {/* </div> */}
            </div>
          </div>
        </Grid>
      </Grid>
      <TermsModal termsModal={termsModal} showTermsModal={showTermsModal} />
      <ResetPassword
        resetPasswordModal={resetPasswordModal}
        setResetPasswordModal={setResetPasswordModal}
      />
    </>
  );
}
export default Header;
