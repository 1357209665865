import SidebarClosed from "./sidebarClosed/sidebarClosed";
import SidebarOpen from "./sidebarOpen/sidebarOpen";


const Index = ({ Sidebar_Data, handleSidebarType, sidebarType }) => {

  switch (sidebarType) {
    case true:
      return <SidebarOpen handleSidebarType={handleSidebarType} Sidebar_Data={Sidebar_Data} />
    case false:
      return <SidebarClosed handleSidebarType={handleSidebarType} Sidebar_Data={Sidebar_Data} />
    default:
      break;
  }
  // return (<SidebarOpen Sidebar_Data={Sidebar_Data}>{children}</SidebarOpen >)
};

export default Index;
