import {useContext} from 'react';
import {ReactComponent as Dashboard} from '../assets/svg/nav/dashboard.svg';
import {ReactComponent as DTIcon} from '../assets/svg/nav/dtIcon.svg';
import {ReactComponent as KitManagement} from '../assets/svg/nav/kitManagement.svg';
import {ReactComponent as Payments} from '../assets/svg/nav/payments.svg';
import {ReactComponent as Reports} from '../assets/svg/nav/reports.svg';
import {ReactComponent as RTIcon} from '../assets/svg/nav/rtIcon.svg';
import {ReactComponent as Tpin} from '../assets/svg/nav/tPin.svg';
import {ReactComponent as Transaction} from '../assets/svg/nav/transactions.svg';
import {ReactComponent as ValueTransfer} from '../assets/svg/nav/valueTransfer.svg';
import {Context} from '../stateManagement/context';

const appRoutes = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  PAYMENTS: '/payments',
  VIRTUAL_ACCOUNT: '/get-funds-by-virtual-account',
  REQUEST_VALUE: '/request-value',
  TRANSFER_VALUE: '/transfer-value',
  VALUE_TRANSFER_REPORT: '/value-transfer-report',
  FUND_LOAD: '/fund-load',
  RETAILER_LIST: '/retailer-list',
  CREATE_RETAILER: '/create-retailer',
  DISTRIBUTOR_LIST: '/distributor-list',
  CREATE_DISTRIBUTOR: '/create-distributor',
  TRANSACTION: '/transactions',
  TPIN: '/t-pin',
  MY_LEDGER: '/my-ledger',
  MY_EARNINGS: 'my-earnings',
  BUY_KITS: '/buy-kits',
  TRANSFER_KITS: '/transfer-kits',
  WALLET_REPORT_SD: '/wallet-report-sd',
  WALLET_REPORT_DT: '/wallet-report-dt',
  DMT_REPORT_DT: '/dmt-report-dt',
  DMT_REPORT_SD: '/dmt-report-sd',
  RECHARGE_REPORT_DT: '/recharge-report-dt',
  RECHARGE_REPORT_SD: '/recharge-report-sd',
  FUND_TRANSFER_REPORT_DT: '/fund-transfer-report-dt',
  FUND_TRANSFER_REPORT_SD: '/fund-transfer-report-sd',
  CREDIT_CARD_REPORT_DT: '/credit-card-report-dt',
  CREDIT_CARD_REPORT_SD: '/credit-card-report-sd',
  AEPS_WALLET_REPORT_DT: '/aeps-wallet-report-dt',
  AEPS_WALLET_REPORT_SD: '/aeps-wallet-report-sd',
  BBPS_REPORT_DT: '/bbps-report-dt',
  BBPS_REPORT_SD: '/bbps-report-sd',
  LEDGER_REPORT_DT: '/ledger-report-dt',
  LEDGER_REPORT_SD: '/ledger-report-sd',
  MATM_REPORT_DT: '/matm-report-dt',
  MATM_REPORT_SD: '/matm-report-sd',
  KYCDMT_REPORT_DT: '/kycdmt-report-dt',
  KYCDMT_REPORT_SD: '/kycdmt-report-sd',
  UNKNOWN: '/*',
};

function AllRoutesData() {
  const [state, contextDispatch] = useContext(Context);
  const userType = state[0].userType;

  const AllRoutes = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: <Dashboard />,
      cName: 'active',
      roles: ['distributor', 'super_distributor'],
      chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
      navId: 1,
    },
    {
      title: 'Payments',
      path: '/payments',
      icon: <Payments />,
      cName: 'active',
      navId: 2,
      roles: ['distributor', 'super_distributor'],
      chainType: ['admin_sd_dt', 'admin_dt', 'admin_dt_rt'],
      subNav: [
        {
          title: 'Get funds by Payment Gateway',
          path: '/payments',
          icon: '',
          cName: 'active',
          roles: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
        // {
        //   title: "Get funds by Virtual Account",
        //   path: "/get-funds-by-virtual-account",
        //   icon: "",
        //   cName: "active",
        //   roles: ["distributor", "super_distributor"],
        //   chainType: ["admin_sd_dt", "admin_sd", "admin_dt"],
        // },
      ],
    },
    {
      title: 'Money Management',
      path: '/transfer-value',
      icon: <ValueTransfer />,
      cName: 'active',
      navId: 3,
      roles: ['distributor', 'super_distributor'],
      chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
      subNav: [
        {
          title: 'Transfer Value',
          path: '/transfer-value',
          icon: '',
          cName: 'active',
          roles: ['distributor', 'super_distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
        {
          title: 'Request Value',
          path: '/request-value',
          icon: '',
          cName: 'active',
          roles: ['distributor', 'super_distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
        {
          title: 'Value Transfer Report',
          path: '/value-transfer-report',
          icon: '',
          cName: 'active',
          roles: ['distributor', 'super_distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
        {
          title: 'Fund Load',
          path: '/fund-load',
          icon: '',
          cName: 'active',
          roles: ['distributor', 'super_distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
      ],
    },
    {
      title: 'Retailer Management',
      path: '/retailer-list',
      icon: <RTIcon />,
      cName: 'active',
      navId: 5,
      roles: ['distributor'],
      chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
      subNav: [
        {
          title: 'Retailer List',
          path: '/retailer-list',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
        {
          title: 'Create Retailer ',
          path: '/create-retailer',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
      ],
    },
    {
      title: 'Distributor Management',
      path: '/distributor-list',
      icon: <DTIcon />,
      cName: 'active',
      navId: 6,
      roles: ['super_distributor'],
      chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
      subNav: [
        {
          title: 'Distributor List',
          path: '/distributor-list',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
        {
          title: 'Create Distributor ',
          path: '/create-distributor',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
      ],
    },
    {
      title: 'Transactions',
      path: '/transactions',
      icon: <Transaction />,
      cName: 'active',
      navId: 7,
      roles: ['distributor', 'super_distributor'],
      chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
    },
    {
      title: 'T-PIN',
      path: '/t-pin',
      icon: <Tpin />,
      cName: 'active',
      navId: 8,
      roles: ['distributor', 'super_distributor'],
      chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
    },
    {
      title: 'Reports',
      path:
        userType === 'distributor' ? '/wallet-report-dt' : '/wallet-report-sd',
      icon: <Reports />,
      cName: 'active',
      navId: 9,
      roles: ['distributor', 'super_distributor'],
      chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
      subNav: [
        {
          title: 'Trade Wallet',
          path:
            userType === 'distributor'
              ? '/wallet-report-dt'
              : '/wallet-report-sd',
          icon: '',
          cName: 'active',
          roles:
            userType === 'distributor'
              ? ['distributor']
              : ['super_distributor'],
          chainType:
            userType === 'distributor'
              ? ['admin_sd_dt', 'admin_dt']
              : ['admin_sd'],
        },
        {
          title: 'AEPS',
          path: '/aeps-wallet-report-sd',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd'],
        },
        {
          title: 'AEPS',
          path: '/aeps-wallet-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_dt'],
        },
        // {
        //   title: 'Fund Transfer',
        //   path: '/fund-transfer-report-sd',
        //   icon: '',
        //   cName: 'active',
        //   roles: ['super_distributor'],
        //   chainType: ['admin_sd'],
        // },
        // {
        //   title: 'Fund Transfer',
        //   path: '/fund-transfer-report-dt',
        //   icon: '',
        //   cName: 'active',
        //   roles: ['distributor'],
        //   chainType: ['admin_sd_dt', 'admin_dt'],
        // },
        {
          title: 'DMT',
          path: '/dmt-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_dt'],
        },
        {
          title: 'DMT',
          path: '/dmt-report-sd',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd'],
        },
        {
          title: 'KYC (DMT)',
          path: '/kycdmt-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt','admin_dt'],
        },
        {
          title: 'KYC (DMT)',
          path: '/kycdmt-report-sd',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd'],
        },
        {
          title: 'Recharge',
          path: '/recharge-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_dt'],
        },
        {
          title: 'Recharge',
          path: '/recharge-report-sd',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd'],
        },
        {
          title: 'Credit Card',
          path: '/credit-card-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_dt'],
        },
        {
          title: 'Credit Card',
          path: '/credit-card-report-sd',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd'],
        },
        {
          title: 'BBPS',
          path: '/bbps-report-sd',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd'],
        },
        {
          title: 'BBPS',
          path: '/bbps-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_dt'],
        },

        {
          title: 'MATM',
          path: '/matm-report-sd',
          icon: '',
          cName: 'active',
          roles: ['super_distributor'],
          chainType: ['admin_sd'],
        },
        {
          title: 'MATM',
          path: '/matm-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_dt'],
        },
        {
          title: 'My Earnings',
          path: '/my-earnings',
          icon: '',
          cName: 'active',
          roles: ['distributor', 'super_distributor'],
          chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        },
        // {
        //   title: 'My Ledger',
        //   path: '/my-ledger',
        //   icon: '',
        //   cName: 'active',
        //   roles: ['distributor', 'super_distributor'],
        //   chainType: ['admin_sd_dt', 'admin_sd', 'admin_dt'],
        // },
        {
          title: 'Ledger',
          path: '/ledger-report-dt',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd_dt', 'admin_dt'],
        },
        {
          title: 'Ledger',
          path: '/ledger-report-sd',
          icon: '',
          cName: 'active',
          roles: ['distributor'],
          chainType: ['admin_sd'],
        },
      ],
    },
    {
      title: 'Kit Management',
      path: '/buy-kits',
      icon: <KitManagement />,
      cName: 'active',
      navId: 10,
      roles: ['super_distributor', 'distributor'],
      chainType: ['admin_sd', 'admin_dt'],
      subNav: [
        {
          title: 'Buy Kits',
          path: '/buy-kits',
          icon: '',
          cName: 'active',
          roles: ['super_distributor', 'distributor'],
          chainType: ['admin_sd', 'admin_dt'],
        },
        {
          title: 'Transfer Kits',
          path: '/transfer-kits',
          icon: '',
          cName: 'active',
          roles: ['super_distributor', 'distributor'],
          chainType: ['admin_sd'],
        },
      ],
    },
  ];
  return AllRoutes;
}

export {AllRoutesData, appRoutes};
