import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useContext, useState } from 'react';
import CustomButton from '../../../components/common/customButton/customButton';
import DateFormatter from '../../../hooks/dateFormatter';
import distributorService from "../../../services/distributor_service";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
import "./createretailermodal.css";

const buttonStyle = {
    width: "240px",
    paddingLeft: "32px",
    paddingRight: "32px",
    lineHeight: "17px",
    letterSpacing: "4px",
};

function CreateRetailerSummary({ formData, setFormData, setActiveStep }) {
    const [loading, setLoading] = useState("");
    const [state, contextDispatch] = useContext(Context);

    const ModalData = [
        { key: "Full Name", value: formData.firstName.value + " " + formData.lastName.value },
        { key: "Gender", value: formData?.gender?.value?.label },
        { key: "Mobile", value: formData.mobile.value },
        { key: "Email", value: formData.email.value },
        { key: "Address", value: formData.address.value },
        { key: "PAN", value: formData.pan.value },
        { key: "Aadhaar", value: formData.aadhaar.value },
        { key: "DOB", value: DateFormatter(new Date(formData.dob.value), 'table') },
        { key: "Shop Name", value: formData.shopName.value }
    ];

    function handleBackClick() {
        setActiveStep(2);
    }

    function handleDataSubmit() {
        setLoading("loading");
        const data = {
            firstName: formData.firstName.value,
            lastName: formData.lastName.value,
            mobile: formData.mobile.value,
            roles: ["RETAILER"],
            email: formData.email.value,
            address: formData.address.value,
            pan: formData.pan.value,
            aadhaar: formData.aadhaar.value,
            dob: DateFormatter(new Date(formData.dob.value), 'payload'),
            // virtualaccount: checked,
            virtualaccount: false,
            gender: formData?.gender?.value.value,
            shopName: formData.shopName.value
        };
        distributorService
            .Create_Retailer(data)
            .then((res) => {
                setLoading("");
                setFormData(null);
                setActiveStep(1);
                contextDispatch({
                    type: Constants.SHOW_SNACKBAR,
                    payload: { isOpen: true, severity: "success", message: res?.data?.message },
                });
            })
            .catch((err) => {
                setLoading("");
                contextDispatch({
                    type: Constants.SHOW_SNACKBAR,
                    payload: { isOpen: true, severity: "error", message: err?.response?.data?.message }
                });
            });
    }

    return (
        <div id="createRetailer-yourchain-modal-container" className='bg-[#ffffff80]'>
            <ArrowBackRoundedIcon onClick={handleBackClick} className='cursor-pointer hover:rounded-full hover:bg-slate-100' />
            <h1 className='text-center text-xl font-bold text-[#112467] mt-2'>Details Summary</h1>
            <div className='h-full my-10 mx-5'>
                {ModalData?.map((item) => <div key={item.key} className='flex mt-5'>
                    <span className='w-1/2'>{item.key}</span>
                    <span className='font-semibold w-1/2 whitespace-pre-wrap'>{item.value}</span>
                </div>)}
            </div>
            <div className="createRetailer-form-submitbtn">
                <CustomButton handleClick={handleDataSubmit} variant={loading} style={buttonStyle} label="Create Retailer" />
            </div>
        </div>
    )
}


export default CreateRetailerSummary;