import { styled } from "@mui/material/styles";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactComponent as Calender } from '../../../assets/svg/calender.svg';

const DateInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: "linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%)",
    boxShadow: "0px 1px 2px rgb(0 0 0 / 25%)",
    height: "40px",
    borderRadius: "12px",
    color: '#112467',
    '&:hover': {
      outline: "none !important"
    },
    '&::placeholder': {
      color: '#8392AB'
    }
  },
  'fieldset': {
    border: "none !important"
  },
  "& input": {
    padding: "0px !important",
    paddingLeft: "28px !important"
  }
}));

const CustomDatePicker = ({ selected, placeholder, name, dateChange, minDate,format }) => {
  const HandleDate = (date) => {
    dateChange({ name: name, value: date });
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (<>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        minDate={minDate || "1923-01-1"}
        views={['year', 'month', 'day']}
        disableFuture={true}
        inputFormat={format||"DD/MM/YYYY"}
        value={selected || null}
        onChange={HandleDate}
        components={{
          OpenPickerIcon: Calender
        }}
        renderInput={(params) => <DateInput {...params} onKeyDown={onKeyDown} sx={{
          "& .MuiInputBase-input": {
            caretColor: 'transparent'
          },
          'input': {
            '&::placeholder': {
              textOverflow: 'ellipsis !important',
              opacity:1,
             color: '#8392AB',
             fontFamily: 'Libre Franklin, sans-serif',
             fontWeight: 400,
             fontSize: '1rem',
             lineHeight: '1.4375rem',
             letterSpacing: '0.00938rem',
            }
          }
        }} error={false} fullWidth inputProps={{ ...params.inputProps, placeholder: placeholder }} />}
      />
    </LocalizationProvider>
  </>)

}

export default CustomDatePicker