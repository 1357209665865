import axios from "axios";
import TokenService from "./token_service";
const API_URL = 'http://dev.api.edge.2.mtlstaging.com';
// const headers = { 'Content-Type': 'application/json' };

const axiosInstance= axios.create(
    {
        baseURL:process.env.REACT_APP_BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          'console-token' : process.env.REACT_APP_CONSOLE_TOKEN
        },
    }
);


axiosInstance.interceptors.request.use(
    config => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['x-access-token'] = token
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if(error.response.status==401){
        window.location.href = '/'
        sessionStorage.clear();
      }
      return Promise.reject(error);
    });

export default axiosInstance;  

