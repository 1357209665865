import { useRoutes } from "react-router-dom";
import routesConfig from "./routesConfig";

const RouteRender=()=>{
    const routes=useRoutes(routesConfig)
    
    return routes
}

export default RouteRender;


