import Select, { components } from "react-select";
import { ReactComponent as DownArrow } from "../../../assets/svg/down-arrow.svg";
import "./customselect.css";

const CustomSelect = ({ data, label, handleChange, value, name, icon, defaultValue, isLoading }) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DownArrow className=" cursor-pointer" />
      </components.DropdownIndicator>
    );
  };

  const ValueContainer = ({ children, ...props }) => {
    const { icon } = props.selectProps;
    return (
      components.ValueContainer && (
        <components.ValueContainer className="iconwith" {...props}>
          {
            <span className="w-12 flex justify-center items-center absolute">
              <img src={icon}></img>
            </span>
          }
          {children}
        </components.ValueContainer>
      )
    );
  };

  const handleOnChange = (value) => {
    handleChange({ name: name, value: value })
  }

  return icon ? (
    <div className=" custom-icon-select-container">
      <Select
        maxMenuHeight={"200px"}
        defaultValue={defaultValue ? { label: defaultValue?.label, value: defaultValue?.value } : null}
        className="w-full focus:outline-none"
        icon={icon}
        menuPortalTarget={document.body}
        styles={{
          container: (base) => ({
            ...base,
            width: "100%",
          }),
          singleValue: provided => ({
            ...provided,
            color: '#112467',
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
          }),
          control: (base) => ({
            ...base,
            cursor: "pointer",
            height: "40px",
            border: "none",
            borderRadius: 12,
            // zIndex: 9999,
            paddingLeft: 12,
            boxShadow: "0px 1px 2px rgb(0 0 0 / 25%);",
          }),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: 50,
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              fontSize: "16px",
              padding: "0 5px",
              fontFamily: "Libre Franklin",
              cursor: isDisabled ? "not-allowed" : "pointer",
            };
          },
          menu: (provided) => ({
            ...provided,
            width: "100%",
            borderRadius: "12px",
            borderTop: 0,
            overflow: "hidden",
          }),
        }}
        onChange={(value) => handleOnChange(value)}
        placeholder={<div className='whitespace-nowrap'>{label}</div>}
        // theme={(theme) => ({
        //   ...theme,
        //   colors: {
        //     ...theme.colors,
        //     neutral50: "#67748E", // Placeholder color
        //   },
        // })}
        components={{ IndicatorSeparator: () => null, ValueContainer }}
        options={data}
        value={value}
      />
    </div>
  ) : (
    <>
      <Select
        maxMenuHeight={"200px"}
        className="focus:outline-none"
        menuPortalTarget={document.body}
        styles={{
          container: (base) => ({
            ...base,
            width: "100%",
          }),
          control: (base) => ({
            ...base,
            cursor: "pointer",
            height: "40px",
            border: "none",
            borderRadius: 12,
            // zIndex: 9999,
            paddingLeft: 12,
            background: "linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%);",
            boxShadow: "0px 1px 2px rgb(0 0 0 / 25%);",
          }),
          menu: (provided) => ({
            ...provided,
            width: "100%",
            borderRadius: "12px",
            borderTop: 0,
            overflow: "hidden",
          }),
          singleValue: provided => ({
            ...provided,
            color: '#112467',
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              fontSize: "16px",
              padding: "0 5px",
              fontFamily: "Libre Franklin",
              cursor: isDisabled ? "not-allowed" : "pointer",
            };
          },
        }}
        onChange={(value) => handleChange({ name: name, value: value })}
        placeholder={<div className='whitespace-nowrap text-[#8392AB]'>{label}</div>}
        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
        options={data}
        value={value}
        isLoading={isLoading}
      />
    </>
  );
};

export default CustomSelect;
